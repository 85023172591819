import React, { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

type RichTextEditorProps = ReactQuill.ReactQuillProps & {
    modules?: any;
    injectedModules?: QuillModule[];
};

type QuillModule = 'mention' | 'toolbar' | 'blotFormatter' | 'imageUploader';

export const RichTextEditor: React.FC<RichTextEditorProps> = ({
    theme = 'snow',
    modules = [],
    injectedModules = [],
    ...props
}) => {
    const [importedModules, setImportedModules] = useState({});

    useEffect(() => {
        const loadModules = async () => {
            let newModules = {};
            for (const element of injectedModules) {
                const defaultModule = await import(`./modules/${element}`);
                newModules = { ...newModules, [element]: modules[element] || defaultModule[element] || {} };
            }

            setImportedModules(newModules);
        };
        loadModules();
    }, []);

    return <ReactQuill theme={theme} modules={importedModules} {...props} />;
};
