import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
    DateTime: any;
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: any;
    /** The `Upload` scalar type represents a file upload. */
    Upload: any;
};

export type AcceptApplicationInviteInput = {
    applicationInviteId: Scalars['String'];
    coverLetter?: Maybe<Scalars['String']>;
    resumeId?: Maybe<Scalars['String']>;
};

export type AcceptApplicationInvitePayload = {
    __typename?: 'AcceptApplicationInvitePayload';
    applicationInvite: ApplicationInvite;
};

export type ActionPayload = EmailActionPayload;

export type AddExternalCandidateInput = {
    email: Scalars['String'];
    firstName: Scalars['String'];
    jobId: Scalars['String'];
    lastName: Scalars['String'];
};

export type AddExternalCandidatePayload = {
    __typename?: 'AddExternalCandidatePayload';
    candidate: Candidate;
};

export type Address = {
    __typename?: 'Address';
    building?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    location?: Maybe<Location>;
    metroStations?: Maybe<Array<MetroStation>>;
    raw?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
};

export type AddressInput = {
    building?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    location?: Maybe<LocationInput>;
    metroStations?: Maybe<Array<MetroStationInput>>;
    raw?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
};

export type Admin = RelayNode & {
    __typename?: 'Admin';
    firstName?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    lastName?: Maybe<Scalars['String']>;
};

export type Age = {
    __typename?: 'Age';
    from?: Maybe<Scalars['Int']>;
    to?: Maybe<Scalars['Int']>;
};

export type AgeInput = {
    from?: Maybe<Scalars['Int']>;
    to?: Maybe<Scalars['Int']>;
};

export enum AlertPeriod {
    Daily = 'DAILY',
    None = 'NONE',
    Weekly = 'WEEKLY',
}

export type AllowedSettingValue = {
    __typename?: 'AllowedSettingValue';
    label: Scalars['String'];
    value: Scalars['String'];
};

export enum ApplicantProfileField {
    Educations = 'EDUCATIONS',
    Emails = 'EMAILS',
    Firstname = 'FIRSTNAME',
    Lastname = 'LASTNAME',
    LinkedinUrl = 'LINKEDIN_URL',
    Phones = 'PHONES',
    SalaryExpectation = 'SALARY_EXPECTATION',
    Summary = 'SUMMARY',
}

export type ApplicationInvite = RelayNode & {
    __typename?: 'ApplicationInvite';
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    job: Job;
    jobId: Scalars['String'];
    jobseeker: Jobseeker;
    jobseekerId: Scalars['String'];
    status: Scalars['String'];
};

export type ApplicationInviteConnection = {
    __typename?: 'ApplicationInviteConnection';
    edges: Array<ApplicationInviteEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type ApplicationInviteEdge = {
    __typename?: 'ApplicationInviteEdge';
    node: ApplicationInvite;
};

export enum ApplicationInviteOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    PublishedAtAsc = 'PUBLISHED_AT_ASC',
    PublishedAtDesc = 'PUBLISHED_AT_DESC',
    SalaryAsc = 'SALARY_ASC',
    SalaryDesc = 'SALARY_DESC',
    ViewCountAsc = 'VIEW_COUNT_ASC',
    ViewCountDesc = 'VIEW_COUNT_DESC',
}

export type ApplicationInvitePayload = {
    __typename?: 'ApplicationInvitePayload';
    applicationInvite: ApplicationInvite;
};

export enum ApplicationInviteStatus {
    Accepted = 'ACCEPTED',
    Pending = 'PENDING',
    Rejected = 'REJECTED',
}

export type ApplicationInviteWhereInput = {
    age?: Maybe<Scalars['Int']>;
    categoryId?: Maybe<Scalars['String']>;
    companyId?: Maybe<Scalars['String']>;
    experience?: Maybe<Experience>;
    includeWithoutSalary?: Maybe<Scalars['Boolean']>;
    onlyEasyApply?: Maybe<Scalars['Boolean']>;
    regionId?: Maybe<Scalars['String']>;
    salary?: Maybe<JobWhereSalaryInput>;
    searchText?: Maybe<Scalars['String']>;
    status?: Maybe<ApplicationInviteStatus>;
};

export enum ApplicationOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type ApplicationWhereInput = {
    categoryId?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    gender?: Maybe<Gender>;
    jobId?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    regionId?: Maybe<Scalars['String']>;
    salary?: Maybe<JobseekerWhereSalaryInput>;
    status?: Maybe<Scalars['String']>;
};

export type ApplyToJobInput = {
    coverLetter?: Maybe<Scalars['String']>;
    jobId: Scalars['String'];
    resumeId?: Maybe<Scalars['String']>;
};

export type ApplyToJobPayload = {
    __typename?: 'ApplyToJobPayload';
    application: JobApplication;
};

export type ApproveJobInput = {
    jobId: Scalars['String'];
};

export type ApproveJobPayload = {
    __typename?: 'ApproveJobPayload';
    job: Job;
};

export type Availability = {
    __typename?: 'Availability';
    date: Scalars['String'];
    slots: Array<AvailabilitySlot>;
};

export type AvailabilityInput = {
    date: Scalars['String'];
    slots: Array<AvailabilitySlotInput>;
};

export type AvailabilitySlot = {
    __typename?: 'AvailabilitySlot';
    from: Scalars['Int'];
};

export type AvailabilitySlotInput = {
    from: Scalars['Int'];
};

export type BlogPost = RelayNode & {
    __typename?: 'BlogPost';
    authorId: Scalars['String'];
    body: Scalars['String'];
    coverImage: FileInfo;
    coverImageId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    deletedAt: Scalars['DateTime'];
    id: Scalars['ID'];
    relatedBlogPosts: BlogPostConnection;
    slug: Scalars['String'];
    tags?: Maybe<Array<Scalars['String']>>;
    title: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    viewCount: Scalars['Int'];
};

export type BlogPostConnection = {
    __typename?: 'BlogPostConnection';
    edges: Array<BlogPostEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type BlogPostEdge = {
    __typename?: 'BlogPostEdge';
    node: BlogPost;
};

export enum BlogPostOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    TitleAsc = 'TITLE_ASC',
    TitleDesc = 'TITLE_DESC',
}

export type BlogPostWhereInput = {
    body?: Maybe<Scalars['String']>;
    tags?: Maybe<Array<Scalars['String']>>;
    title?: Maybe<Scalars['String']>;
};

export type BookmarkJobInput = {
    jobId: Scalars['String'];
};

export type BookmarkJobPayload = {
    __typename?: 'BookmarkJobPayload';
    job: Job;
};

export type BookmarkedJob = RelayNode & {
    __typename?: 'BookmarkedJob';
    id: Scalars['ID'];
    job: Job;
};

export type BookmarkedJobConnection = {
    __typename?: 'BookmarkedJobConnection';
    edges: Array<BookmarkedJobEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type BookmarkedJobEdge = {
    __typename?: 'BookmarkedJobEdge';
    node: BookmarkedJob;
};

export type CalendarEvent = RelayNode & {
    __typename?: 'CalendarEvent';
    createdAt: Scalars['DateTime'];
    date: Scalars['DateTime'];
    duration: Scalars['Int'];
    end: Scalars['Int'];
    id: Scalars['ID'];
    start: Scalars['Int'];
    title: Scalars['String'];
    type: CalendarEventType;
    userId: Scalars['String'];
};

export type CalendarEventConnection = {
    __typename?: 'CalendarEventConnection';
    edges: Array<CalendarEventEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type CalendarEventEdge = {
    __typename?: 'CalendarEventEdge';
    node: CalendarEvent;
};

export enum CalendarEventType {
    Interview = 'INTERVIEW',
    Meeting = 'MEETING',
}

export type CalendarEventWhereInput = {
    endDate: Scalars['DateTime'];
    startDate: Scalars['DateTime'];
};

export type Candidate = RelayNode & {
    __typename?: 'Candidate';
    applications?: Maybe<JobApplicationConnection>;
    id: Scalars['ID'];
    profile: Jobseeker;
};

export type CandidateConnection = {
    __typename?: 'CandidateConnection';
    edges: Array<CandidateEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type CandidateEdge = {
    __typename?: 'CandidateEdge';
    node: Candidate;
};

export enum CandidateOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export type CandidateWhereInput = {
    categoryId?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    gender?: Maybe<Gender>;
    jobId?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    regionId?: Maybe<Scalars['String']>;
    salary?: Maybe<JobseekerWhereSalaryInput>;
    status?: Maybe<Scalars['String']>;
};

export type Category = RelayNode & {
    __typename?: 'Category';
    id: Scalars['ID'];
    jobCount: Scalars['Int'];
    name: Scalars['String'];
    order: Scalars['Int'];
};

export type CategoryConnection = {
    __typename?: 'CategoryConnection';
    edges: Array<CategoryEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type CategoryEdge = {
    __typename?: 'CategoryEdge';
    node: Category;
};

export type CategoryWhereInput = {
    name?: Maybe<Scalars['String']>;
};

export type ChangePasswordInput = {
    currentPassword: Scalars['String'];
    newPassword: Scalars['String'];
};

export type ChangePasswordPayload = {
    __typename?: 'ChangePasswordPayload';
    success: Scalars['Boolean'];
};

export type ChatableUsersWhereInput = {
    keyword?: Maybe<Scalars['String']>;
};

export type Company = RelayNode & {
    __typename?: 'Company';
    createdAt: Scalars['DateTime'];
    currentPricingPlan?: Maybe<PurchasedPlan>;
    description?: Maybe<Scalars['String']>;
    employers?: Maybe<EmployerConnection>;
    id: Scalars['ID'];
    industryId?: Maybe<Scalars['String']>;
    jobs?: Maybe<JobConnection>;
    logo?: Maybe<FileInfo>;
    logoId?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    region: Region;
    regionId: Scalars['String'];
    slug?: Maybe<Scalars['String']>;
    type: CompanyType;
    verified: Scalars['Boolean'];
    website?: Maybe<Scalars['String']>;
};

export type CompanyConnection = {
    __typename?: 'CompanyConnection';
    edges: Array<CompanyEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type CompanyEdge = {
    __typename?: 'CompanyEdge';
    node: Company;
};

export enum CompanyOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    JobCountAsc = 'JOB_COUNT_ASC',
    JobCountDesc = 'JOB_COUNT_DESC',
    NameAsc = 'NAME_ASC',
    NameDesc = 'NAME_DESC',
}

export enum CompanyType {
    Agency = 'AGENCY',
    Company = 'COMPANY',
    PrivateRecruiter = 'PRIVATE_RECRUITER',
}

export type CompanyWhereInput = {
    name?: Maybe<Scalars['String']>;
    regionId?: Maybe<Scalars['String']>;
};

export type ConfirmScheduledEventInput = {
    confirmedSlot: ConfirmedSlotInput;
    scheduledEventId: Scalars['String'];
};

export type ConfirmScheduledEventPayload = {
    __typename?: 'ConfirmScheduledEventPayload';
    scheduledEvent: ScheduledEvent;
};

export type ConfirmedSlot = {
    __typename?: 'ConfirmedSlot';
    date: Scalars['String'];
    from: Scalars['Int'];
};

export type ConfirmedSlotInput = {
    date: Scalars['String'];
    from: Scalars['Int'];
};

export type ContactInfo = {
    __typename?: 'ContactInfo';
    behanceUrl?: Maybe<Scalars['String']>;
    emails?: Maybe<Array<Scalars['String']>>;
    facebookUrl?: Maybe<Scalars['String']>;
    githubUrl?: Maybe<Scalars['String']>;
    instagramUrl?: Maybe<Scalars['String']>;
    linkedInUrl?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    phones?: Maybe<Array<ContactPhone>>;
    skypeAddress?: Maybe<Scalars['String']>;
    twitterUrl?: Maybe<Scalars['String']>;
};

export type ContactInfoInput = {
    behanceUrl?: Maybe<Scalars['String']>;
    emails?: Maybe<Array<Scalars['String']>>;
    facebookUrl?: Maybe<Scalars['String']>;
    githubUrl?: Maybe<Scalars['String']>;
    instagramUrl?: Maybe<Scalars['String']>;
    linkedInUrl?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    phones?: Maybe<Array<ContactPhoneInput>>;
    skypeAddress?: Maybe<Scalars['String']>;
    twitterUrl?: Maybe<Scalars['String']>;
};

export type ContactPhone = {
    __typename?: 'ContactPhone';
    comment?: Maybe<Scalars['String']>;
    number?: Maybe<Scalars['String']>;
};

export type ContactPhoneInput = {
    comment?: Maybe<Scalars['String']>;
    number?: Maybe<Scalars['String']>;
};

export type CreateApplicationInviteInput = {
    jobId: Scalars['String'];
    jobseekerId: Scalars['String'];
};

export type CreateBlogPostInput = {
    body: Scalars['String'];
    coverImage: Scalars['Upload'];
    tags?: Maybe<Array<Scalars['String']>>;
    title: Scalars['String'];
};

export type CreateBlogPostPayload = {
    __typename?: 'CreateBlogPostPayload';
    post: BlogPost;
};

export type CreateCalendarEventInput = {
    date: Scalars['DateTime'];
    duration: Scalars['Int'];
    end: Scalars['Int'];
    start: Scalars['Int'];
    title: Scalars['String'];
    type?: Maybe<CalendarEventType>;
};

export type CreateCalendarEventPayload = {
    __typename?: 'CreateCalendarEventPayload';
    calendarEvent: CalendarEvent;
};

export type CreateEmailStageActionInput = {
    attachments?: Maybe<Array<Scalars['Upload']>>;
    payload: EmailActionPayloadInput;
    stageId: Scalars['String'];
};

export type CreateEmailStageActionPayload = {
    __typename?: 'CreateEmailStageActionPayload';
    hiringStage: HiringStage;
};

export type CreateHiringProcessInput = {
    title: Scalars['String'];
};

export type CreateHiringProcessPayload = {
    __typename?: 'CreateHiringProcessPayload';
    hiringProcess: HiringProcess;
};

export type CreateHiringStageInput = {
    color: Scalars['String'];
    processId: Scalars['String'];
    status: HiringStageStatus;
    title: Scalars['String'];
};

export type CreateHiringStagePayload = {
    __typename?: 'CreateHiringStagePayload';
    hiringStage: HiringStage;
};

export type CreateJobApplicationCommentInput = {
    applicationId: Scalars['String'];
    content: Scalars['String'];
};

export type CreateJobApplicationCommentPayload = {
    __typename?: 'CreateJobApplicationCommentPayload';
    jobApplicationComment: JobApplicationComment;
};

export type CreateJobInput = {
    acceptHandicapped?: Maybe<Scalars['Boolean']>;
    acceptIncompleteResumes?: Maybe<Scalars['Boolean']>;
    acceptKids?: Maybe<Scalars['Boolean']>;
    address?: Maybe<AddressInput>;
    age?: Maybe<AgeInput>;
    applicantProfileRequirements?: Maybe<Array<ApplicantProfileField>>;
    applicationUrl?: Maybe<Scalars['String']>;
    categoryId: Scalars['String'];
    code?: Maybe<Scalars['String']>;
    contacts?: Maybe<ContactInfoInput>;
    descriptionHtml: Scalars['String'];
    driverLicenseTypes?: Maybe<Array<DriverLicense>>;
    education?: Maybe<EducationLevel>;
    employment?: Maybe<Employment>;
    experience?: Maybe<Experience>;
    expiresAt: Scalars['DateTime'];
    gender?: Maybe<Gender>;
    keySkills?: Maybe<Array<Scalars['String']>>;
    processId: Scalars['String'];
    publish: Scalars['Boolean'];
    regionIds: Array<Scalars['String']>;
    salary?: Maybe<SalaryInput>;
    schedule: Schedule;
    title: Scalars['String'];
};

export type CreateJobPayload = {
    __typename?: 'CreateJobPayload';
    job: Job;
};

export type CreateSavedSearchInput = {
    alertPeriod: AlertPeriod;
    filter: JobFilterInput;
    name: Scalars['String'];
};

export type CreateSavedSearchPayload = {
    __typename?: 'CreateSavedSearchPayload';
    savedSearch: SavedSearch;
};

export type CreateScheduledEventInput = {
    applicationId?: Maybe<Scalars['String']>;
    availabilities: Array<AvailabilityInput>;
    confirmerId: Scalars['String'];
    duration: Scalars['Int'];
    expiresAt: Scalars['String'];
    location?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    notes?: Maybe<Scalars['String']>;
    type: EventType;
};

export type CreateScheduledEventPayload = {
    __typename?: 'CreateScheduledEventPayload';
    scheduledEvent: ScheduledEvent;
};

export type CreateScheduledEventTemplateInput = {
    availabilities: Array<AvailabilityInput>;
    duration: Scalars['Int'];
    expiresAt?: Maybe<Scalars['DateTime']>;
    location?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    notes?: Maybe<Scalars['String']>;
    timezone?: Maybe<Scalars['String']>;
    type?: Maybe<EventType>;
};

export type CreateScheduledEventTemplatePayload = {
    __typename?: 'CreateScheduledEventTemplatePayload';
    scheduledEventTemplate: ScheduledEventTemplate;
};

export type CredentialsInput = {
    email: Scalars['String'];
    password: Scalars['String'];
};

export type DeleteBlogPostPayload = {
    __typename?: 'DeleteBlogPostPayload';
    id: Scalars['String'];
};

export type DeleteCompanyPayload = {
    __typename?: 'DeleteCompanyPayload';
    company: Company;
};

export type DeleteHiringProcessPayload = {
    __typename?: 'DeleteHiringProcessPayload';
    id: Scalars['String'];
};

export type DeleteHiringStagePayload = {
    __typename?: 'DeleteHiringStagePayload';
    id: Scalars['String'];
};

export type DeleteJobPayload = {
    __typename?: 'DeleteJobPayload';
    id: Scalars['String'];
};

export type DeleteSavedSearchPayload = {
    __typename?: 'DeleteSavedSearchPayload';
    id: Scalars['String'];
};

export type DeleteStageActionPayload = {
    __typename?: 'DeleteStageActionPayload';
    id: Scalars['String'];
};

export enum DevicePlatform {
    Android = 'ANDROID',
    Ios = 'IOS',
    Web = 'WEB',
}

export enum DriverLicense {
    A = 'A',
    A1 = 'A1',
    B = 'B',
    Be = 'BE',
    C = 'C',
    Ce = 'CE',
    D = 'D',
    De = 'DE',
}

export type EditEmailStageActionInput = {
    attachments?: Maybe<Array<Scalars['Upload']>>;
    id: Scalars['String'];
    payload: EmailActionPayloadInput;
    previousAttachmentIds?: Maybe<Array<Scalars['String']>>;
};

export type EditEmailStageActionPayload = {
    __typename?: 'EditEmailStageActionPayload';
    trigger: StageAction;
};

export type EditHiringProcessInput = {
    id: Scalars['String'];
    title: Scalars['String'];
};

export type EditHiringProcessPayload = {
    __typename?: 'EditHiringProcessPayload';
    hiringProcess: HiringProcess;
};

export type EditHiringStageInput = {
    color: Scalars['String'];
    id: Scalars['String'];
    status: HiringStageStatus;
    title: Scalars['String'];
};

export type EditHiringStagePayload = {
    __typename?: 'EditHiringStagePayload';
    hiringStage: HiringStage;
};

export type EditJobInput = {
    acceptHandicapped?: Maybe<Scalars['Boolean']>;
    acceptIncompleteResumes?: Maybe<Scalars['Boolean']>;
    acceptKids?: Maybe<Scalars['Boolean']>;
    address?: Maybe<AddressInput>;
    age?: Maybe<AgeInput>;
    applicantProfileRequirements?: Maybe<Array<ApplicantProfileField>>;
    applicationUrl?: Maybe<Scalars['String']>;
    categoryId: Scalars['String'];
    code?: Maybe<Scalars['String']>;
    contacts?: Maybe<ContactInfoInput>;
    descriptionHtml: Scalars['String'];
    driverLicenseTypes?: Maybe<Array<DriverLicense>>;
    education?: Maybe<EducationLevel>;
    employment?: Maybe<Employment>;
    experience?: Maybe<Experience>;
    expiresAt: Scalars['DateTime'];
    gender?: Maybe<Gender>;
    id: Scalars['String'];
    keySkills?: Maybe<Array<Scalars['String']>>;
    processId: Scalars['String'];
    regionIds: Array<Scalars['String']>;
    salary?: Maybe<SalaryInput>;
    schedule: Schedule;
    title: Scalars['String'];
};

export type EditJobPayload = {
    __typename?: 'EditJobPayload';
    job: Job;
};

export enum EducationLevel {
    Bachelor = 'BACHELOR',
    College = 'COLLEGE',
    HighSchool = 'HIGH_SCHOOL',
    Master = 'MASTER',
    Phd = 'PHD',
}

export type EmailActionPayload = {
    __typename?: 'EmailActionPayload';
    body: Scalars['String'];
    from: SendEmailActionFrom;
    subject: Scalars['String'];
    to: SendEmailActionTo;
    toEmails?: Maybe<Array<Scalars['String']>>;
};

export type EmailActionPayloadInput = {
    body: Scalars['String'];
    from: SendEmailActionFrom;
    subject: Scalars['String'];
    to: SendEmailActionTo;
    toEmails?: Maybe<Array<Scalars['String']>>;
};

export type EmailStageActionOverrideInput = {
    attachments?: Maybe<Array<Scalars['Upload']>>;
    id: Scalars['String'];
    payload: EmailActionPayloadInput;
    previousAttachmentIds?: Maybe<Array<Scalars['String']>>;
};

export type Employer = RelayNode & {
    __typename?: 'Employer';
    avatar?: Maybe<FileInfo>;
    avatarId?: Maybe<Scalars['String']>;
    company: Company;
    companyId: Scalars['String'];
    firstName?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    lastName?: Maybe<Scalars['String']>;
    phoneNumber: Scalars['String'];
    user: User;
};

export type EmployerConnection = {
    __typename?: 'EmployerConnection';
    edges: Array<EmployerEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type EmployerEdge = {
    __typename?: 'EmployerEdge';
    node: Employer;
};

export enum Employment {
    Freelance = 'FREELANCE',
    FullTime = 'FULL_TIME',
    Internship = 'INTERNSHIP',
    PartTime = 'PART_TIME',
    Probation = 'PROBATION',
    Volunteer = 'VOLUNTEER',
}

export enum EventType {
    Meeting = 'MEETING',
    OnsiteInterview = 'ONSITE_INTERVIEW',
    PhoneInterview = 'PHONE_INTERVIEW',
    VideoInterview = 'VIDEO_INTERVIEW',
}

export enum Experience {
    BetweenOneAndThree = 'BETWEEN_ONE_AND_THREE',
    BetweenThreeAndSix = 'BETWEEN_THREE_AND_SIX',
    LessThanOne = 'LESS_THAN_ONE',
    MoreThanSix = 'MORE_THAN_SIX',
    NoExperience = 'NO_EXPERIENCE',
}

export enum FeatureCode {
    AccessTalentPool = 'ACCESS_TALENT_POOL',
    AutomaticCompanyVerificationBadge = 'AUTOMATIC_COMPANY_VERIFICATION_BADGE',
    CopyJobListing = 'COPY_JOB_LISTING',
    CreateHiringProcess = 'CREATE_HIRING_PROCESS',
    CreateJobListing = 'CREATE_JOB_LISTING',
    CustomCompanyUrl = 'CUSTOM_COMPANY_URL',
    FreeJobListingPromotion = 'FREE_JOB_LISTING_PROMOTION',
    ReactivateJobListing = 'REACTIVATE_JOB_LISTING',
    SendAutomatedEmailsToApplicants = 'SEND_AUTOMATED_EMAILS_TO_APPLICANTS',
    SendBulkMail = 'SEND_BULK_MAIL',
    SendDirectMail = 'SEND_DIRECT_MAIL',
}

export type FileInfo = RelayNode & {
    __typename?: 'FileInfo';
    createdAt: Scalars['DateTime'];
    extension: Scalars['String'];
    id: Scalars['ID'];
    name: Scalars['String'];
    objectName: Scalars['String'];
    size: Scalars['Int'];
    url: Scalars['String'];
    version: Scalars['Int'];
};

export type FileInfoConnection = {
    __typename?: 'FileInfoConnection';
    edges: Array<FileInfoEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type FileInfoEdge = {
    __typename?: 'FileInfoEdge';
    node: FileInfo;
};

export type ForgotPasswordInput = {
    email: Scalars['String'];
};

export type ForgotPasswordPayload = {
    __typename?: 'ForgotPasswordPayload';
    sent: Scalars['Boolean'];
};

export enum Gender {
    Female = 'FEMALE',
    Male = 'MALE',
    Unknown = 'UNKNOWN',
}

export type HiringProcess = RelayNode & {
    __typename?: 'HiringProcess';
    createdAt: Scalars['DateTime'];
    employerId: Scalars['String'];
    id: Scalars['ID'];
    stages: HiringStageConnection;
    title: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    version: Scalars['Int'];
};

export type HiringProcessConnection = {
    __typename?: 'HiringProcessConnection';
    edges: Array<HiringProcessEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type HiringProcessEdge = {
    __typename?: 'HiringProcessEdge';
    node: HiringProcess;
};

export type HiringStage = RelayNode & {
    __typename?: 'HiringStage';
    color: Scalars['String'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    order: Scalars['Int'];
    processId: Scalars['String'];
    status: HiringStageStatus;
    title: Scalars['String'];
    triggers: StageActionConnection;
    updatedAt: Scalars['DateTime'];
    version: Scalars['Int'];
};

export type HiringStageConnection = {
    __typename?: 'HiringStageConnection';
    edges: Array<HiringStageEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type HiringStageEdge = {
    __typename?: 'HiringStageEdge';
    node: HiringStage;
};

export enum HiringStageStatus {
    Declined = 'DECLINED',
    Hire = 'HIRE',
    Interview = 'INTERVIEW',
    New = 'NEW',
    Offer = 'OFFER',
    OfferAccepted = 'OFFER_ACCEPTED',
    OfferRejected = 'OFFER_REJECTED',
    Screening = 'SCREENING',
}

export type Industry = RelayNode & {
    __typename?: 'Industry';
    id: Scalars['ID'];
    name: Scalars['String'];
    order: Scalars['Int'];
};

export type IndustryConnection = {
    __typename?: 'IndustryConnection';
    edges: Array<IndustryEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type IndustryEdge = {
    __typename?: 'IndustryEdge';
    node: Industry;
};

export type Job = RelayNode & {
    __typename?: 'Job';
    acceptHandicapped?: Maybe<Scalars['Boolean']>;
    acceptIncompleteResumes?: Maybe<Scalars['Boolean']>;
    acceptKids?: Maybe<Scalars['Boolean']>;
    address?: Maybe<Address>;
    age?: Maybe<Age>;
    applicantProfileRequirements?: Maybe<Array<ApplicantProfileField>>;
    applicationCount?: Maybe<Scalars['Int']>;
    applicationUrl?: Maybe<Scalars['String']>;
    applications?: Maybe<JobApplicationConnection>;
    applied?: Maybe<Scalars['Boolean']>;
    bookmarked?: Maybe<Scalars['Boolean']>;
    category: Category;
    categoryId: Scalars['String'];
    code?: Maybe<Scalars['String']>;
    company: Company;
    companyId: Scalars['String'];
    contacts?: Maybe<ContactInfo>;
    createdAt: Scalars['DateTime'];
    descriptionData?: Maybe<Scalars['JSON']>;
    descriptionHtml: Scalars['String'];
    descriptionText: Scalars['String'];
    driverLicenseTypes?: Maybe<Array<DriverLicense>>;
    easyApply: Scalars['Boolean'];
    education?: Maybe<EducationLevel>;
    employment?: Maybe<Employment>;
    experience?: Maybe<Experience>;
    expiresAt: Scalars['DateTime'];
    gender?: Maybe<Gender>;
    id: Scalars['ID'];
    invitation?: Maybe<ApplicationInvite>;
    keySkills?: Maybe<Array<Scalars['String']>>;
    process?: Maybe<HiringProcess>;
    processId: Scalars['String'];
    publishedAt?: Maybe<Scalars['DateTime']>;
    regions: RegionConnection;
    rejectReason?: Maybe<Scalars['String']>;
    relatedJobs: JobConnection;
    salary?: Maybe<Salary>;
    schedule: Schedule;
    slug: Scalars['String'];
    source: Scalars['String'];
    status?: Maybe<JobStatus>;
    title: Scalars['String'];
    updatedAt: Scalars['DateTime'];
    version: Scalars['Int'];
    viewCount?: Maybe<Scalars['Int']>;
};

export type JobApplicationsArgs = {
    orderBy?: Maybe<ApplicationOrderBy>;
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<ApplicationWhereInput>;
};

export type JobApplication = RelayNode & {
    __typename?: 'JobApplication';
    applicant?: Maybe<Jobseeker>;
    applicantId: Scalars['String'];
    comments?: Maybe<JobApplicationCommentConnection>;
    coverLetter?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    internal: Scalars['Boolean'];
    job?: Maybe<Job>;
    jobId: Scalars['String'];
    resume?: Maybe<Resume>;
    resumeId?: Maybe<Scalars['String']>;
    scheduledEvents?: Maybe<ScheduledEventConnection>;
    stage?: Maybe<HiringStage>;
    stageId: Scalars['String'];
    statusHistories?: Maybe<JobApplicationStatusHistoryConnection>;
    updatedAt: Scalars['DateTime'];
    version: Scalars['Int'];
};

export type JobApplicationComment = RelayNode & {
    __typename?: 'JobApplicationComment';
    content: Scalars['String'];
    id: Scalars['ID'];
};

export type JobApplicationCommentConnection = {
    __typename?: 'JobApplicationCommentConnection';
    edges: Array<JobApplicationCommentEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type JobApplicationCommentEdge = {
    __typename?: 'JobApplicationCommentEdge';
    node: JobApplicationComment;
};

export type JobApplicationConnection = {
    __typename?: 'JobApplicationConnection';
    edges: Array<JobApplicationEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type JobApplicationEdge = {
    __typename?: 'JobApplicationEdge';
    node: JobApplication;
};

export type JobApplicationStatusHistory = RelayNode & {
    __typename?: 'JobApplicationStatusHistory';
    applicationId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    newStage?: Maybe<HiringStage>;
    newStageId: Scalars['String'];
    oldStage?: Maybe<HiringStage>;
    oldStageId: Scalars['String'];
    reason?: Maybe<Scalars['String']>;
};

export type JobApplicationStatusHistoryConnection = {
    __typename?: 'JobApplicationStatusHistoryConnection';
    edges: Array<JobApplicationStatusHistoryEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type JobApplicationStatusHistoryEdge = {
    __typename?: 'JobApplicationStatusHistoryEdge';
    node: JobApplicationStatusHistory;
};

export type JobApplicationWhereInput = {
    age?: Maybe<Scalars['Int']>;
    applicantId?: Maybe<Scalars['String']>;
    categoryId?: Maybe<Scalars['String']>;
    companyId?: Maybe<Scalars['String']>;
    experience?: Maybe<Experience>;
    includeWithoutSalary?: Maybe<Scalars['Boolean']>;
    onlyEasyApply?: Maybe<Scalars['Boolean']>;
    regionId?: Maybe<Scalars['String']>;
    salary?: Maybe<JobWhereSalaryInput>;
    searchText?: Maybe<Scalars['String']>;
};

export type JobConnection = {
    __typename?: 'JobConnection';
    edges: Array<JobEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type JobEdge = {
    __typename?: 'JobEdge';
    node: Job;
};

export type JobFilter = {
    __typename?: 'JobFilter';
    age?: Maybe<Scalars['Int']>;
    categoryId?: Maybe<Scalars['String']>;
    companyId?: Maybe<Scalars['String']>;
    experience?: Maybe<Experience>;
    includeWithoutSalary?: Maybe<Scalars['Boolean']>;
    onlyEasyApply?: Maybe<Scalars['Boolean']>;
    regionId?: Maybe<Scalars['String']>;
    salary?: Maybe<JobWhereSalary>;
    searchText?: Maybe<Scalars['String']>;
};

export type JobFilterInput = {
    age?: Maybe<Scalars['Int']>;
    categoryId?: Maybe<Scalars['String']>;
    companyId?: Maybe<Scalars['String']>;
    experience?: Maybe<Experience>;
    includeWithoutSalary?: Maybe<Scalars['Boolean']>;
    onlyEasyApply?: Maybe<Scalars['Boolean']>;
    regionId?: Maybe<Scalars['String']>;
    salary?: Maybe<JobWhereSalaryInput>;
    searchText?: Maybe<Scalars['String']>;
};

export type JobForAdminWhereInput = {
    categoryId?: Maybe<Scalars['String']>;
    source?: Maybe<Scalars['String']>;
    status?: Maybe<JobStatus>;
    title?: Maybe<Scalars['String']>;
};

export enum JobOrderBy {
    PublishedAtAsc = 'PUBLISHED_AT_ASC',
    PublishedAtDesc = 'PUBLISHED_AT_DESC',
    SalaryAsc = 'SALARY_ASC',
    SalaryDesc = 'SALARY_DESC',
    ViewCountAsc = 'VIEW_COUNT_ASC',
    ViewCountDesc = 'VIEW_COUNT_DESC',
}

export enum JobPostingOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    SalaryAsc = 'SALARY_ASC',
    SalaryDesc = 'SALARY_DESC',
}

export type JobPostingWhereInput = {
    categoryId?: Maybe<Scalars['String']>;
    status?: Maybe<JobStatus>;
    title?: Maybe<Scalars['String']>;
};

export enum JobStatus {
    Archived = 'ARCHIVED',
    Blocked = 'BLOCKED',
    Draft = 'DRAFT',
    Expired = 'EXPIRED',
    OnModeration = 'ON_MODERATION',
    Published = 'PUBLISHED',
}

export type JobWhereInput = {
    age?: Maybe<Scalars['Int']>;
    categoryId?: Maybe<Scalars['String']>;
    companyId?: Maybe<Scalars['String']>;
    experience?: Maybe<Experience>;
    includeWithoutSalary?: Maybe<Scalars['Boolean']>;
    onlyEasyApply?: Maybe<Scalars['Boolean']>;
    regionId?: Maybe<Scalars['String']>;
    salary?: Maybe<JobWhereSalaryInput>;
    searchText?: Maybe<Scalars['String']>;
};

export type JobWhereSalary = {
    __typename?: 'JobWhereSalary';
    from?: Maybe<Scalars['Int']>;
    to?: Maybe<Scalars['Int']>;
};

export type JobWhereSalaryInput = {
    from?: Maybe<Scalars['Int']>;
    to?: Maybe<Scalars['Int']>;
};

export type Jobseeker = RelayNode & {
    __typename?: 'Jobseeker';
    address?: Maybe<Address>;
    avatar?: Maybe<FileInfo>;
    avatarId?: Maybe<Scalars['String']>;
    category?: Maybe<Category>;
    categoryId?: Maybe<Scalars['String']>;
    contacts?: Maybe<ContactInfo>;
    createdAt: Scalars['DateTime'];
    dateOfBirth?: Maybe<Scalars['DateTime']>;
    educations: JobseekerEducationConnection;
    email: Scalars['String'];
    experiences: JobseekerExperienceConnection;
    firstName?: Maybe<Scalars['String']>;
    gender?: Maybe<Gender>;
    id: Scalars['ID'];
    keySkills?: Maybe<Array<Scalars['String']>>;
    languages: JobseekerLanguageConnection;
    lastName?: Maybe<Scalars['String']>;
    middleName?: Maybe<Scalars['String']>;
    placeOfBirth?: Maybe<Scalars['String']>;
    region?: Maybe<Region>;
    regionId?: Maybe<Scalars['String']>;
    resumes: ResumeConnection;
    salaryExpectation?: Maybe<Scalars['Int']>;
    status?: Maybe<Scalars['String']>;
    summary?: Maybe<Scalars['String']>;
    userId: Scalars['String'];
};

export type JobseekerConnection = {
    __typename?: 'JobseekerConnection';
    edges: Array<JobseekerEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type JobseekerEdge = {
    __typename?: 'JobseekerEdge';
    node: Jobseeker;
};

export type JobseekerEducation = RelayNode & {
    __typename?: 'JobseekerEducation';
    description?: Maybe<Scalars['String']>;
    endDate?: Maybe<Scalars['DateTime']>;
    field: Scalars['String'];
    id: Scalars['ID'];
    jobseekerId?: Maybe<Scalars['String']>;
    level: EducationLevel;
    location?: Maybe<Scalars['String']>;
    ongoing?: Maybe<Scalars['Boolean']>;
    school: Scalars['String'];
    startDate: Scalars['DateTime'];
    verified?: Maybe<Scalars['Boolean']>;
};

export type JobseekerEducationConnection = {
    __typename?: 'JobseekerEducationConnection';
    edges: Array<JobseekerEducationEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type JobseekerEducationEdge = {
    __typename?: 'JobseekerEducationEdge';
    node: JobseekerEducation;
};

export type JobseekerEducationInput = {
    description?: Maybe<Scalars['String']>;
    endDate?: Maybe<Scalars['DateTime']>;
    field: Scalars['String'];
    id?: Maybe<Scalars['String']>;
    level: EducationLevel;
    location?: Maybe<Scalars['String']>;
    ongoing?: Maybe<Scalars['Boolean']>;
    school: Scalars['String'];
    startDate: Scalars['DateTime'];
};

export type JobseekerExperience = RelayNode & {
    __typename?: 'JobseekerExperience';
    company: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    endDate?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    jobseekerId?: Maybe<Scalars['String']>;
    languageId?: Maybe<Scalars['String']>;
    ongoing?: Maybe<Scalars['Boolean']>;
    position: Scalars['String'];
    startDate: Scalars['DateTime'];
    verified?: Maybe<Scalars['Boolean']>;
};

export type JobseekerExperienceConnection = {
    __typename?: 'JobseekerExperienceConnection';
    edges: Array<JobseekerExperienceEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type JobseekerExperienceEdge = {
    __typename?: 'JobseekerExperienceEdge';
    node: JobseekerExperience;
};

export type JobseekerExperienceInput = {
    company: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    endDate?: Maybe<Scalars['DateTime']>;
    id?: Maybe<Scalars['String']>;
    ongoing?: Maybe<Scalars['Boolean']>;
    position: Scalars['String'];
    startDate: Scalars['DateTime'];
};

export type JobseekerLanguage = RelayNode & {
    __typename?: 'JobseekerLanguage';
    id: Scalars['ID'];
    jobseekerId?: Maybe<Scalars['String']>;
    languageId?: Maybe<Scalars['String']>;
    level?: Maybe<LanguageLevel>;
};

export type JobseekerLanguageConnection = {
    __typename?: 'JobseekerLanguageConnection';
    edges: Array<JobseekerLanguageEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type JobseekerLanguageEdge = {
    __typename?: 'JobseekerLanguageEdge';
    node: JobseekerLanguage;
};

export type JobseekerLanguageInput = {
    id?: Maybe<Scalars['String']>;
    languageId: Scalars['String'];
    level: LanguageLevel;
};

export enum JobseekerOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export enum JobseekerStatus {
    Employed = 'EMPLOYED',
    OpenToOpportunities = 'OPEN_TO_OPPORTUNITIES',
}

export type JobseekerWhereInput = {
    categoryId?: Maybe<Scalars['String']>;
    educationLevels?: Maybe<Array<EducationLevel>>;
    experience?: Maybe<Experience>;
    gender?: Maybe<Gender>;
    keySkills?: Maybe<Array<Scalars['String']>>;
    languages?: Maybe<Array<Scalars['String']>>;
    regionId?: Maybe<Scalars['String']>;
    salary?: Maybe<JobseekerWhereSalaryInput>;
    status?: Maybe<Scalars['String']>;
};

export type JobseekerWhereSalaryInput = {
    from?: Maybe<Scalars['Int']>;
    to?: Maybe<Scalars['Int']>;
};

export type Language = RelayNode & {
    __typename?: 'Language';
    id: Scalars['ID'];
    isoCode: Scalars['String'];
    name: Scalars['String'];
};

export type LanguageConnection = {
    __typename?: 'LanguageConnection';
    edges: Array<LanguageEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type LanguageEdge = {
    __typename?: 'LanguageEdge';
    node: Language;
};

export enum LanguageLevel {
    A1 = 'A1',
    A2 = 'A2',
    B1 = 'B1',
    B2 = 'B2',
    C1 = 'C1',
    C2 = 'C2',
}

export type Location = {
    __typename?: 'Location';
    latitude: Scalars['Float'];
    longitude: Scalars['Float'];
};

export type LocationInput = {
    latitude: Scalars['Float'];
    longitude: Scalars['Float'];
};

export type LoginPayload = {
    __typename?: 'LoginPayload';
    accessToken: Scalars['String'];
    matrixAuth?: Maybe<MatrixAuthData>;
};

export type MailMessage = RelayNode & {
    __typename?: 'MailMessage';
    body: Scalars['String'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    read: Scalars['Boolean'];
    receiverId: Scalars['String'];
    senderId: Scalars['String'];
    subject: Scalars['String'];
    threadId?: Maybe<Scalars['String']>;
    type: MailMessageType;
    updatedAt: Scalars['DateTime'];
    version: Scalars['Int'];
};

export enum MailMessageType {
    Application = 'APPLICATION',
    DirectMail = 'DIRECT_MAIL',
    Promotion = 'PROMOTION',
}

export type MatrixAuthData = {
    __typename?: 'MatrixAuthData';
    access_token: Scalars['String'];
    device_id: Scalars['String'];
    home_server: Scalars['String'];
    user_id: Scalars['String'];
};

export type MetroStation = {
    __typename?: 'MetroStation';
    location?: Maybe<Location>;
    name: Scalars['String'];
};

export type MetroStationInput = {
    location?: Maybe<LocationInput>;
    name: Scalars['String'];
};

export type MoveApplicationToStageInput = {
    applicationId: Scalars['String'];
    stageId: Scalars['String'];
    triggers?: Maybe<Array<EmailStageActionOverrideInput>>;
};

export type MoveApplicationToStagePayload = {
    __typename?: 'MoveApplicationToStagePayload';
    application: JobApplication;
};

export type Mutation = {
    __typename?: 'Mutation';
    acceptApplicationInvite: AcceptApplicationInvitePayload;
    acceptOffer?: Maybe<MoveApplicationToStagePayload>;
    addExternalCandidate: AddExternalCandidatePayload;
    applyToJob: ApplyToJobPayload;
    approveJob: ApproveJobPayload;
    bookmarkJob: BookmarkJobPayload;
    buyJobSlot: Scalars['String'];
    changePassword: ChangePasswordPayload;
    confirmScheduledEvent: ConfirmScheduledEventPayload;
    createCalendarEvent: CreateCalendarEventPayload;
    createHiringProcess: CreateHiringProcessPayload;
    createHiringStage: CreateHiringStagePayload;
    createJob: CreateJobPayload;
    createJobApplicationComment: CreateJobApplicationCommentPayload;
    createPost: CreateBlogPostPayload;
    createScheduledEvent: CreateScheduledEventPayload;
    createScheduledEventTemplate: CreateScheduledEventTemplatePayload;
    createStageTrigger: CreateEmailStageActionPayload;
    deleteBlogPost: DeleteBlogPostPayload;
    deleteCalendarEvent: Scalars['String'];
    deleteCompany: DeleteCompanyPayload;
    deleteHiringProcess: DeleteHiringProcessPayload;
    deleteHiringStage: DeleteHiringStagePayload;
    deleteJob: DeleteJobPayload;
    deleteJobApplicationComment: Scalars['String'];
    deleteResume: Resume;
    deleteSavedSearch: DeleteSavedSearchPayload;
    deleteScheduledEventTemplate: Scalars['String'];
    deleteStageAction: DeleteStageActionPayload;
    editHiringProcess: EditHiringProcessPayload;
    editHiringStage: EditHiringStagePayload;
    editJob: EditJobPayload;
    editStageTrigger: EditEmailStageActionPayload;
    facebookLogin: LoginPayload;
    forgotPassword: ForgotPasswordPayload;
    googleLogin: LoginPayload;
    login: LoginPayload;
    moveApplicationToStage?: Maybe<MoveApplicationToStagePayload>;
    publishJob: PublishJobPayload;
    reactivateJob: ReactivateJobPayload;
    registerAdmin: RegistrationPayload;
    registerDevice: Scalars['String'];
    registerEmployer: RegistrationPayload;
    registerJobseeker: RegistrationPayload;
    registerMatrixUser: MatrixAuthData;
    rejectApplicationInvite: RejectApplicationInvitePayload;
    rejectJob: RejectJobPayload;
    rejectMultipleCandidates?: Maybe<JobApplicationConnection>;
    rejectOffer?: Maybe<MoveApplicationToStagePayload>;
    reorderHiringStage: ReorderHiringStagePayload;
    resetPassword: ResetPasswordPayload;
    saveSearch: CreateSavedSearchPayload;
    sendInvitationToJobseeker: ApplicationInvitePayload;
    sendMessage: SendMailPayload;
    sendVerificationEmail: SendVerifyEmailPayload;
    setPlansToEveryOne: Scalars['Boolean'];
    updateBlogPost: UpdateBlogPostPayload;
    updateCalendarEvent: UpdateCalendarEventPayload;
    updateCompany: UpdateCompanyPayload;
    updateCompanyLogo: Company;
    updateCompanySlug: UpdateCompanySlugPayload;
    updateEmployer: UpdateEmployerPayload;
    updateEmployerAvatar: UpdateEmployerAvatarPayload;
    updateJobApplicationComment: UpdateJobApplicationCommentPayload;
    updateJobseekerAvatar: Jobseeker;
    updateJobseekerProfile: UpdateJobseekerProfilePayload;
    updatePricingPlan: Scalars['String'];
    updateSavedSearch: UpdateSavedSearchPayload;
    updateScheduledEventTemplate: UpdateScheduledEventTemplatePayload;
    updateUserSetting: UpdateUserSettingsPayload;
    updateUserSettings: UpdateUserSettingsPayload;
    uploadBlogPostCoverImage: UploadBlogPostCoverImagePayload;
    uploadImage: Scalars['String'];
    uploadResume: Resume;
    verifyCompany: VerifyCompanyPayload;
    verifyEmail: VerifyEmailPayload;
    viewBlogPost: ViewBlogPostPayload;
    viewJob: ViewJobPayload;
};

export type MutationAcceptApplicationInviteArgs = {
    input: AcceptApplicationInviteInput;
};

export type MutationAcceptOfferArgs = {
    applicationId: Scalars['String'];
};

export type MutationAddExternalCandidateArgs = {
    input: AddExternalCandidateInput;
};

export type MutationApplyToJobArgs = {
    application: ApplyToJobInput;
};

export type MutationApproveJobArgs = {
    job: ApproveJobInput;
};

export type MutationBookmarkJobArgs = {
    bookmarkJob: BookmarkJobInput;
};

export type MutationBuyJobSlotArgs = {
    jobId: Scalars['String'];
};

export type MutationChangePasswordArgs = {
    input: ChangePasswordInput;
};

export type MutationConfirmScheduledEventArgs = {
    scheduledEvent: ConfirmScheduledEventInput;
};

export type MutationCreateCalendarEventArgs = {
    calendarEvent: CreateCalendarEventInput;
};

export type MutationCreateHiringProcessArgs = {
    process: CreateHiringProcessInput;
};

export type MutationCreateHiringStageArgs = {
    stage: CreateHiringStageInput;
};

export type MutationCreateJobArgs = {
    job: CreateJobInput;
};

export type MutationCreateJobApplicationCommentArgs = {
    comment: CreateJobApplicationCommentInput;
};

export type MutationCreatePostArgs = {
    blogPost: CreateBlogPostInput;
};

export type MutationCreateScheduledEventArgs = {
    scheduledEvent: CreateScheduledEventInput;
};

export type MutationCreateScheduledEventTemplateArgs = {
    scheduledEventTemplate: CreateScheduledEventTemplateInput;
};

export type MutationCreateStageTriggerArgs = {
    trigger: CreateEmailStageActionInput;
};

export type MutationDeleteBlogPostArgs = {
    id: Scalars['String'];
};

export type MutationDeleteCalendarEventArgs = {
    eventId: Scalars['String'];
};

export type MutationDeleteCompanyArgs = {
    companyId: Scalars['String'];
};

export type MutationDeleteHiringProcessArgs = {
    id: Scalars['String'];
};

export type MutationDeleteHiringStageArgs = {
    id: Scalars['String'];
};

export type MutationDeleteJobArgs = {
    id: Scalars['String'];
};

export type MutationDeleteJobApplicationCommentArgs = {
    commentId: Scalars['String'];
};

export type MutationDeleteResumeArgs = {
    id: Scalars['String'];
};

export type MutationDeleteSavedSearchArgs = {
    id: Scalars['String'];
};

export type MutationDeleteScheduledEventTemplateArgs = {
    templateId: Scalars['String'];
};

export type MutationDeleteStageActionArgs = {
    id: Scalars['String'];
};

export type MutationEditHiringProcessArgs = {
    process: EditHiringProcessInput;
};

export type MutationEditHiringStageArgs = {
    stage: EditHiringStageInput;
};

export type MutationEditJobArgs = {
    job: EditJobInput;
};

export type MutationEditStageTriggerArgs = {
    trigger: EditEmailStageActionInput;
};

export type MutationForgotPasswordArgs = {
    input: ForgotPasswordInput;
};

export type MutationLoginArgs = {
    credentials: CredentialsInput;
};

export type MutationMoveApplicationToStageArgs = {
    input: MoveApplicationToStageInput;
};

export type MutationPublishJobArgs = {
    job: PublishJobInput;
};

export type MutationReactivateJobArgs = {
    job: ReactivateJobInput;
};

export type MutationRegisterAdminArgs = {
    admin: RegisterAdminInput;
};

export type MutationRegisterDeviceArgs = {
    input: RegisterDeviceInput;
};

export type MutationRegisterEmployerArgs = {
    employer: RegisterEmployerInput;
};

export type MutationRegisterJobseekerArgs = {
    jobseeker: RegisterJobseekerInput;
};

export type MutationRegisterMatrixUserArgs = {
    input: RegisterMatrixUserInput;
};

export type MutationRejectApplicationInviteArgs = {
    input: RejectApplicationInviteInput;
};

export type MutationRejectJobArgs = {
    job: RejectJobInput;
};

export type MutationRejectMultipleCandidatesArgs = {
    input: RejectMultipleCandidatesInput;
};

export type MutationRejectOfferArgs = {
    input: RejectOfferInput;
};

export type MutationReorderHiringStageArgs = {
    stage: ReorderHiringStageInput;
};

export type MutationResetPasswordArgs = {
    input: ResetPasswordInput;
};

export type MutationSaveSearchArgs = {
    search: CreateSavedSearchInput;
};

export type MutationSendInvitationToJobseekerArgs = {
    input: CreateApplicationInviteInput;
};

export type MutationSendMessageArgs = {
    message: SendMailInput;
};

export type MutationSendVerificationEmailArgs = {
    input: SendVerifyEmailInput;
};

export type MutationUpdateBlogPostArgs = {
    blogPost: UpdateBlogPostInput;
};

export type MutationUpdateCalendarEventArgs = {
    calendarEvent: UpdateCalendarEventInput;
};

export type MutationUpdateCompanyArgs = {
    company: UpdateCompanyInput;
};

export type MutationUpdateCompanyLogoArgs = {
    input: UploadCompanyLogoInput;
};

export type MutationUpdateCompanySlugArgs = {
    company: UpdateCompanySlugInput;
};

export type MutationUpdateEmployerArgs = {
    input: UpdateEmployerInput;
};

export type MutationUpdateEmployerAvatarArgs = {
    employer: UpdateEmployerAvatarInput;
};

export type MutationUpdateJobApplicationCommentArgs = {
    comment: UpdateJobApplicationCommentInput;
};

export type MutationUpdateJobseekerAvatarArgs = {
    input: UploadJobseekerAvatarInput;
};

export type MutationUpdateJobseekerProfileArgs = {
    profile: UpdateJobseekerProfileInput;
};

export type MutationUpdatePricingPlanArgs = {
    pricingPlanId: Scalars['String'];
};

export type MutationUpdateSavedSearchArgs = {
    search: UpdateSavedSearchInput;
};

export type MutationUpdateScheduledEventTemplateArgs = {
    scheduledEventTemplate: UpdateScheduledEventTemplateInput;
};

export type MutationUpdateUserSettingArgs = {
    input: UpdateUserSettingInput;
};

export type MutationUpdateUserSettingsArgs = {
    input: UpdateUserSettingsInput;
};

export type MutationUploadBlogPostCoverImageArgs = {
    blogPost: UploadBlogPostCoverImageInput;
};

export type MutationUploadImageArgs = {
    input: UploadImageInput;
};

export type MutationUploadResumeArgs = {
    input: UploadResumeInput;
};

export type MutationVerifyCompanyArgs = {
    company: VerifyCompanyInput;
};

export type MutationVerifyEmailArgs = {
    input: VerifyEmailInput;
};

export type MutationViewBlogPostArgs = {
    blogPost: ViewBlogPostInput;
};

export type MutationViewJobArgs = {
    viewJob: ViewJobInput;
};

export type PageInfo = {
    __typename?: 'PageInfo';
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    page: Scalars['Int'];
    totalPages: Scalars['Int'];
};

export type Payment = RelayNode & {
    __typename?: 'Payment';
    companyId?: Maybe<Scalars['String']>;
    companyName?: Maybe<Scalars['String']>;
    companySlug?: Maybe<Scalars['String']>;
    date?: Maybe<Scalars['DateTime']>;
    firstName?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    lastName?: Maybe<Scalars['String']>;
    price?: Maybe<Scalars['Int']>;
    status: PaymentStatus;
};

export type PaymentConnection = {
    __typename?: 'PaymentConnection';
    edges: Array<PaymentEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type PaymentEdge = {
    __typename?: 'PaymentEdge';
    node: Payment;
};

export enum PaymentOrderBy {
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
}

export enum PaymentStatus {
    Cancelled = 'CANCELLED',
    Declined = 'DECLINED',
    Paid = 'PAID',
    Pending = 'PENDING',
}

export type PaymentWhereInput = {
    companyName?: Maybe<Scalars['String']>;
    endDate?: Maybe<Scalars['DateTime']>;
    price?: Maybe<Scalars['Int']>;
    startDate?: Maybe<Scalars['DateTime']>;
    status?: Maybe<PaymentStatus>;
};

export type PricingPlan = RelayNode & {
    __typename?: 'PricingPlan';
    discountPercentage?: Maybe<Scalars['Int']>;
    features: PricingPlanFeatureConnection;
    id: Scalars['ID'];
    name: Scalars['String'];
    order: Scalars['Int'];
    price: Scalars['Int'];
    pricingType: PricingType;
};

export type PricingPlanConnection = {
    __typename?: 'PricingPlanConnection';
    edges: Array<PricingPlanEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type PricingPlanEdge = {
    __typename?: 'PricingPlanEdge';
    node: PricingPlan;
};

export type PricingPlanFeature = RelayNode & {
    __typename?: 'PricingPlanFeature';
    code: FeatureCode;
    enabled: Scalars['Boolean'];
    id: Scalars['ID'];
    limit?: Maybe<Scalars['Int']>;
};

export type PricingPlanFeatureConnection = {
    __typename?: 'PricingPlanFeatureConnection';
    edges: Array<PricingPlanFeatureEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type PricingPlanFeatureEdge = {
    __typename?: 'PricingPlanFeatureEdge';
    node: PricingPlanFeature;
};

export enum PricingType {
    Monthly = 'MONTHLY',
    PayAsYouGo = 'PAY_AS_YOU_GO',
}

export type PublishJobInput = {
    jobId: Scalars['String'];
};

export type PublishJobPayload = {
    __typename?: 'PublishJobPayload';
    job: Job;
};

export type PurchasedPlan = RelayNode & {
    __typename?: 'PurchasedPlan';
    active: Scalars['Boolean'];
    expiresAt: Scalars['DateTime'];
    features: PurchasedPlanFeatureConnection;
    id: Scalars['ID'];
    plan: PricingPlan;
    planId: Scalars['String'];
    startsAt: Scalars['DateTime'];
};

export type PurchasedPlanEdge = {
    __typename?: 'PurchasedPlanEdge';
    node: PurchasedPlan;
};

export type PurchasedPlanFeature = RelayNode & {
    __typename?: 'PurchasedPlanFeature';
    code: FeatureCode;
    enabled: Scalars['Boolean'];
    id: Scalars['ID'];
    limit?: Maybe<Scalars['Int']>;
    purchasedPlanId: Scalars['String'];
};

export type PurchasedPlanFeatureConnection = {
    __typename?: 'PurchasedPlanFeatureConnection';
    edges: Array<PurchasedPlanFeatureEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type PurchasedPlanFeatureEdge = {
    __typename?: 'PurchasedPlanFeatureEdge';
    node: PurchasedPlanFeature;
};

export type Query = {
    __typename?: 'Query';
    allJobPostings: JobConnection;
    applicationCount: Scalars['Int'];
    applicationInvites: ApplicationInviteConnection;
    applications: JobApplicationConnection;
    blogPost: BlogPost;
    blogPosts: BlogPostConnection;
    bookmarkedJobs: BookmarkedJobConnection;
    calendarEvents: CalendarEventConnection;
    candidate: Candidate;
    candidates: CandidateConnection;
    categories: CategoryConnection;
    category: Category;
    chatableUsers: UserConnection;
    companies: CompanyConnection;
    company: Company;
    companyCount: Scalars['Int'];
    companySlugs: Array<Scalars['String']>;
    currentPlan?: Maybe<PurchasedPlan>;
    dasboards: ReportConnection;
    expiringJobs: JobConnection;
    fileInfo: FileInfo;
    hiringProcess: HiringProcess;
    hiringProcesses: HiringProcessConnection;
    hiringStage: HiringStage;
    htmlResume: Scalars['String'];
    industries: IndustryConnection;
    industry: Industry;
    job: Job;
    jobApplication: JobApplication;
    jobCount: Scalars['Int'];
    jobPostings: JobConnection;
    jobSeeker: Jobseeker;
    jobs: JobConnection;
    jobseekerApplications: JobApplicationConnection;
    jobseekers: JobseekerConnection;
    language: Language;
    languages: LanguageConnection;
    me?: Maybe<User>;
    mostAppliedJobs: JobConnection;
    payments: PaymentConnection;
    pdfResume: Scalars['String'];
    pricingPlans: PricingPlanConnection;
    region: Region;
    regions: RegionConnection;
    registeredCompanies: CompanyConnection;
    reports: ReportConnection;
    savedSearch: SavedSearch;
    savedSearches: SavedSearchConnection;
    scheduledEvent: ScheduledEvent;
    scheduledEventTemplate: ScheduledEventTemplate;
    scheduledEventTemplates: ScheduledEventTemplateConnection;
    scheduledSlots: ScheduledSlotConnection;
    selectedJobs: JobConnection;
    selectedSettings: UserSettingConnection;
    settingGroups: SettingGroupConnection;
    topCategories: CategoryConnection;
    totalJobViewCount: Scalars['Int'];
};

export type QueryAllJobPostingsArgs = {
    orderBy?: Maybe<JobOrderBy>;
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<JobForAdminWhereInput>;
};

export type QueryApplicationInvitesArgs = {
    orderBy?: Maybe<ApplicationInviteOrderBy>;
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<ApplicationInviteWhereInput>;
};

export type QueryApplicationsArgs = {
    orderBy?: Maybe<JobOrderBy>;
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<JobApplicationWhereInput>;
};

export type QueryBlogPostArgs = {
    id?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
};

export type QueryBlogPostsArgs = {
    orderBy?: Maybe<BlogPostOrderBy>;
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<BlogPostWhereInput>;
};

export type QueryBookmarkedJobsArgs = {
    orderBy?: Maybe<JobOrderBy>;
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<JobWhereInput>;
};

export type QueryCalendarEventsArgs = {
    where: CalendarEventWhereInput;
};

export type QueryCandidateArgs = {
    id: Scalars['String'];
};

export type QueryCandidatesArgs = {
    orderBy?: Maybe<CandidateOrderBy>;
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<CandidateWhereInput>;
};

export type QueryCategoriesArgs = {
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<CategoryWhereInput>;
};

export type QueryCategoryArgs = {
    id: Scalars['String'];
};

export type QueryChatableUsersArgs = {
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<ChatableUsersWhereInput>;
};

export type QueryCompaniesArgs = {
    orderBy?: Maybe<CompanyOrderBy>;
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<CompanyWhereInput>;
};

export type QueryCompanyArgs = {
    id?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
};

export type QueryFileInfoArgs = {
    id: Scalars['String'];
};

export type QueryHiringProcessArgs = {
    id: Scalars['String'];
};

export type QueryHiringStageArgs = {
    id: Scalars['String'];
};

export type QueryIndustriesArgs = {
    page?: Maybe<Scalars['Int']>;
};

export type QueryIndustryArgs = {
    id: Scalars['String'];
};

export type QueryJobArgs = {
    id?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
};

export type QueryJobApplicationArgs = {
    id: Scalars['String'];
};

export type QueryJobPostingsArgs = {
    orderBy?: Maybe<JobPostingOrderBy>;
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<JobPostingWhereInput>;
};

export type QueryJobSeekerArgs = {
    id: Scalars['String'];
};

export type QueryJobsArgs = {
    orderBy?: Maybe<JobOrderBy>;
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<JobWhereInput>;
};

export type QueryJobseekerApplicationsArgs = {
    orderBy?: Maybe<JobOrderBy>;
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<JobApplicationWhereInput>;
};

export type QueryJobseekersArgs = {
    orderBy?: Maybe<JobseekerOrderBy>;
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<JobseekerWhereInput>;
};

export type QueryLanguageArgs = {
    id: Scalars['String'];
};

export type QueryPaymentsArgs = {
    orderBy?: Maybe<PaymentOrderBy>;
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<PaymentWhereInput>;
};

export type QueryRegionArgs = {
    id: Scalars['String'];
};

export type QueryRegisteredCompaniesArgs = {
    orderBy?: Maybe<CompanyOrderBy>;
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<CompanyWhereInput>;
};

export type QuerySavedSearchArgs = {
    id: Scalars['String'];
};

export type QueryScheduledEventArgs = {
    id: Scalars['String'];
};

export type QueryScheduledEventTemplateArgs = {
    templateId: Scalars['String'];
};

export type QueryScheduledSlotsArgs = {
    where: ScheduledSlotWhereInput;
};

export type ReactivateJobInput = {
    jobId: Scalars['String'];
};

export type ReactivateJobPayload = {
    __typename?: 'ReactivateJobPayload';
    job: Job;
};

export type Region = RelayNode & {
    __typename?: 'Region';
    aliases: Array<Scalars['String']>;
    children: RegionConnection;
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type RegionConnection = {
    __typename?: 'RegionConnection';
    edges: Array<RegionEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type RegionEdge = {
    __typename?: 'RegionEdge';
    node: Region;
};

export type RegisterAdminInput = {
    email: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
};

export type RegisterDeviceInput = {
    platform: DevicePlatform;
    pushToken: Scalars['String'];
};

export type RegisterEmployerInput = {
    companyName: Scalars['String'];
    companyRegionId: Scalars['String'];
    companyType: CompanyType;
    companyWebsite?: Maybe<Scalars['String']>;
    email: Scalars['String'];
    firstName: Scalars['String'];
    isAgency?: Maybe<Scalars['Boolean']>;
    lastName: Scalars['String'];
    password: Scalars['String'];
    phoneNumber: Scalars['String'];
};

export type RegisterJobseekerInput = {
    email: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    password: Scalars['String'];
};

export type RegisterMatrixUserInput = {
    password: Scalars['String'];
};

export type RegistrationPayload = {
    __typename?: 'RegistrationPayload';
    id: Scalars['String'];
};

export type RejectApplicationInviteInput = {
    applicationInviteId: Scalars['String'];
};

export type RejectApplicationInvitePayload = {
    __typename?: 'RejectApplicationInvitePayload';
    applicationInvite: ApplicationInvite;
};

export type RejectJobInput = {
    jobId: Scalars['String'];
    rejectReason: Scalars['String'];
};

export type RejectJobPayload = {
    __typename?: 'RejectJobPayload';
    job: Job;
};

export type RejectMultipleCandidatesInput = {
    candidateIds: Array<Scalars['String']>;
    jobId: Scalars['String'];
};

export type RejectOfferInput = {
    applicationId: Scalars['String'];
    reason: Scalars['String'];
};

export type RelayNode = {
    id: Scalars['ID'];
};

export type ReorderHiringStageInput = {
    id: Scalars['String'];
    order: Scalars['Int'];
};

export type ReorderHiringStagePayload = {
    __typename?: 'ReorderHiringStagePayload';
    stages: HiringStageConnection;
};

export type Report = RelayNode & {
    __typename?: 'Report';
    code: Scalars['String'];
    embedUrl: Scalars['String'];
    id: Scalars['ID'];
};

export type ReportConnection = {
    __typename?: 'ReportConnection';
    edges: Array<ReportEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type ReportEdge = {
    __typename?: 'ReportEdge';
    node: Report;
};

export type ResetPasswordInput = {
    password: Scalars['String'];
    token: Scalars['String'];
};

export type ResetPasswordPayload = {
    __typename?: 'ResetPasswordPayload';
    success: Scalars['Boolean'];
};

export type Resume = RelayNode & {
    __typename?: 'Resume';
    createdAt?: Maybe<Scalars['DateTime']>;
    file: FileInfo;
    id: Scalars['ID'];
    jobseekerId?: Maybe<Scalars['String']>;
    localUri: Scalars['String'];
    name?: Maybe<Scalars['String']>;
};

export type ResumeConnection = {
    __typename?: 'ResumeConnection';
    edges: Array<ResumeEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type ResumeEdge = {
    __typename?: 'ResumeEdge';
    node: Resume;
};

export type Role = RelayNode & {
    __typename?: 'Role';
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type Salary = {
    __typename?: 'Salary';
    currency: Scalars['String'];
    from?: Maybe<Scalars['Int']>;
    gross?: Maybe<Scalars['Boolean']>;
    to?: Maybe<Scalars['Int']>;
};

export type SalaryInput = {
    currency: Scalars['String'];
    from?: Maybe<Scalars['Int']>;
    gross?: Maybe<Scalars['Boolean']>;
    to?: Maybe<Scalars['Int']>;
};

export type SavedSearch = RelayNode & {
    __typename?: 'SavedSearch';
    alertPeriod: AlertPeriod;
    filter: JobFilter;
    id: Scalars['ID'];
    lastExecutedAt?: Maybe<Scalars['DateTime']>;
    name: Scalars['String'];
};

export type SavedSearchConnection = {
    __typename?: 'SavedSearchConnection';
    edges: Array<SavedSearchEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type SavedSearchEdge = {
    __typename?: 'SavedSearchEdge';
    node: SavedSearch;
};

export enum Schedule {
    Flexible = 'FLEXIBLE',
    FlyInFlyOut = 'FLY_IN_FLY_OUT',
    FullDay = 'FULL_DAY',
    Remote = 'REMOTE',
    Shift = 'SHIFT',
}

export type ScheduledEvent = RelayNode & {
    __typename?: 'ScheduledEvent';
    applicationId?: Maybe<Scalars['String']>;
    availabilities: Array<Availability>;
    confirmed: Scalars['Boolean'];
    confirmedSlot?: Maybe<ConfirmedSlot>;
    confirmerId: Scalars['String'];
    createdAt: Scalars['DateTime'];
    creatorId: Scalars['String'];
    deletedAt: Scalars['DateTime'];
    duration: Scalars['Int'];
    expiresAt: Scalars['DateTime'];
    id: Scalars['ID'];
    location: Scalars['String'];
    name: Scalars['String'];
    notes?: Maybe<Scalars['String']>;
    timezone: Scalars['String'];
    type: EventType;
    updatedAt: Scalars['DateTime'];
};

export type ScheduledEventConnection = {
    __typename?: 'ScheduledEventConnection';
    edges: Array<ScheduledEventEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type ScheduledEventEdge = {
    __typename?: 'ScheduledEventEdge';
    node: ScheduledEvent;
};

export type ScheduledEventTemplate = RelayNode & {
    __typename?: 'ScheduledEventTemplate';
    availabilities: Array<Availability>;
    createdAt: Scalars['DateTime'];
    creatorId: Scalars['String'];
    duration: Scalars['Int'];
    expiresAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    location?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    notes?: Maybe<Scalars['String']>;
    timezone?: Maybe<Scalars['String']>;
    type?: Maybe<EventType>;
};

export type ScheduledEventTemplateConnection = {
    __typename?: 'ScheduledEventTemplateConnection';
    edges: Array<ScheduledEventTemplateEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type ScheduledEventTemplateEdge = {
    __typename?: 'ScheduledEventTemplateEdge';
    node: ScheduledEventTemplate;
};

export type ScheduledSlot = RelayNode & {
    __typename?: 'ScheduledSlot';
    date: Scalars['DateTime'];
    end: Scalars['Int'];
    id: Scalars['ID'];
    start: Scalars['Int'];
};

export type ScheduledSlotConnection = {
    __typename?: 'ScheduledSlotConnection';
    edges: Array<ScheduledSlotEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type ScheduledSlotEdge = {
    __typename?: 'ScheduledSlotEdge';
    node: ScheduledSlot;
};

export type ScheduledSlotWhereInput = {
    dates: Array<Scalars['DateTime']>;
    userId: Scalars['String'];
};

export enum SendEmailActionFrom {
    LoggedInUser = 'LOGGED_IN_USER',
}

export enum SendEmailActionTo {
    Candidate = 'CANDIDATE',
    EmailAddress = 'EMAIL_ADDRESS',
}

export type SendMailInput = {
    body: Scalars['String'];
    receiverId: Scalars['String'];
    subject: Scalars['String'];
    threadId?: Maybe<Scalars['String']>;
};

export type SendMailPayload = {
    __typename?: 'SendMailPayload';
    mailMessage: MailMessage;
};

export type SendVerifyEmailInput = {
    email: Scalars['String'];
};

export type SendVerifyEmailPayload = {
    __typename?: 'SendVerifyEmailPayload';
    sent: Scalars['Boolean'];
};

export type Setting = RelayNode & {
    __typename?: 'Setting';
    allowedValues?: Maybe<Array<AllowedSettingValue>>;
    dataType?: Maybe<SettingDataType>;
    groupId?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    required?: Maybe<Scalars['Boolean']>;
    scopes?: Maybe<Array<SettingScope>>;
    type?: Maybe<SettingType>;
};

export type SettingConnection = {
    __typename?: 'SettingConnection';
    edges: Array<SettingEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export enum SettingDataType {
    Boolean = 'BOOLEAN',
    Integer = 'INTEGER',
    String = 'STRING',
}

export type SettingEdge = {
    __typename?: 'SettingEdge';
    node: Setting;
};

export type SettingGroup = RelayNode & {
    __typename?: 'SettingGroup';
    id: Scalars['ID'];
    name: Scalars['String'];
    settings: SettingConnection;
};

export type SettingGroupConnection = {
    __typename?: 'SettingGroupConnection';
    edges: Array<SettingGroupEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type SettingGroupEdge = {
    __typename?: 'SettingGroupEdge';
    node: SettingGroup;
};

export enum SettingScope {
    All = 'ALL',
    Employer = 'EMPLOYER',
    Jobseeker = 'JOBSEEKER',
    Partner = 'PARTNER',
}

export enum SettingType {
    Country = 'COUNTRY',
    EmployerDailyNewApplicationsEmail = 'EMPLOYER_DAILY_NEW_APPLICATIONS_EMAIL',
    EmployerRejectedApplicationInviteEmail = 'EMPLOYER_REJECTED_APPLICATION_INVITE_EMAIL',
    JobseekerDailyJobsEmail = 'JOBSEEKER_DAILY_JOBS_EMAIL',
    JobseekerDailyJobsPush = 'JOBSEEKER_DAILY_JOBS_PUSH',
    JobseekerDailyJobsWebPush = 'JOBSEEKER_DAILY_JOBS_WEB_PUSH',
    JobseekerFilterJobsEmail = 'JOBSEEKER_FILTER_JOBS_EMAIL',
    JobseekerFilterJobsPush = 'JOBSEEKER_FILTER_JOBS_PUSH',
    JobseekerFilterJobsWebPush = 'JOBSEEKER_FILTER_JOBS_WEB_PUSH',
    Language = 'LANGUAGE',
    Theme = 'THEME',
}

export type StageAction = RelayNode & {
    __typename?: 'StageAction';
    attachments: FileInfoConnection;
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    payload: ActionPayload;
    stageId: Scalars['String'];
    type: TriggerType;
    updatedAt: Scalars['DateTime'];
    version: Scalars['Int'];
};

export type StageActionConnection = {
    __typename?: 'StageActionConnection';
    edges: Array<StageActionEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type StageActionEdge = {
    __typename?: 'StageActionEdge';
    node: StageAction;
};

export enum TriggerType {
    Email = 'EMAIL',
    Push = 'PUSH',
}

export type UpdateBlogPostInput = {
    body: Scalars['String'];
    id: Scalars['String'];
    tags?: Maybe<Array<Scalars['String']>>;
    title: Scalars['String'];
};

export type UpdateBlogPostPayload = {
    __typename?: 'UpdateBlogPostPayload';
    blogPost: BlogPost;
};

export type UpdateCalendarEventInput = {
    date: Scalars['DateTime'];
    duration: Scalars['Int'];
    end: Scalars['Int'];
    id: Scalars['String'];
    start: Scalars['Int'];
    title: Scalars['String'];
    type?: Maybe<CalendarEventType>;
};

export type UpdateCalendarEventPayload = {
    __typename?: 'UpdateCalendarEventPayload';
    calendarEvent: CalendarEvent;
};

export type UpdateCompanyInput = {
    description?: Maybe<Scalars['String']>;
    industryId?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    regionId: Scalars['String'];
    type: CompanyType;
    website?: Maybe<Scalars['String']>;
};

export type UpdateCompanyPayload = {
    __typename?: 'UpdateCompanyPayload';
    company: Company;
};

export type UpdateCompanySlugInput = {
    companyId: Scalars['String'];
    slug: Scalars['String'];
};

export type UpdateCompanySlugPayload = {
    __typename?: 'UpdateCompanySlugPayload';
    company: Company;
};

export type UpdateEmployerAvatarInput = {
    avatar: Scalars['Upload'];
};

export type UpdateEmployerAvatarPayload = {
    __typename?: 'UpdateEmployerAvatarPayload';
    employer: Employer;
};

export type UpdateEmployerInput = {
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    phoneNumber?: Maybe<Scalars['String']>;
};

export type UpdateEmployerPayload = {
    __typename?: 'UpdateEmployerPayload';
    employer: Employer;
};

export type UpdateJobApplicationCommentInput = {
    content: Scalars['String'];
    id: Scalars['String'];
};

export type UpdateJobApplicationCommentPayload = {
    __typename?: 'UpdateJobApplicationCommentPayload';
    jobApplicationComment: JobApplicationComment;
};

export type UpdateJobseekerProfileInput = {
    address?: Maybe<AddressInput>;
    categoryId?: Maybe<Scalars['String']>;
    contacts?: Maybe<ContactInfoInput>;
    dateOfBirth?: Maybe<Scalars['DateTime']>;
    educations?: Maybe<Array<JobseekerEducationInput>>;
    experiences?: Maybe<Array<JobseekerExperienceInput>>;
    firstName?: Maybe<Scalars['String']>;
    gender?: Maybe<Gender>;
    keySkills?: Maybe<Array<Scalars['String']>>;
    languages?: Maybe<Array<JobseekerLanguageInput>>;
    lastName?: Maybe<Scalars['String']>;
    middleName?: Maybe<Scalars['String']>;
    placeOfBirth?: Maybe<Scalars['String']>;
    regionId?: Maybe<Scalars['String']>;
    salaryExpectation?: Maybe<Scalars['Int']>;
    status?: Maybe<JobseekerStatus>;
    summary?: Maybe<Scalars['String']>;
};

export type UpdateJobseekerProfilePayload = {
    __typename?: 'UpdateJobseekerProfilePayload';
    profile: Jobseeker;
};

export type UpdateSavedSearchInput = {
    alertPeriod: AlertPeriod;
    id: Scalars['String'];
    name: Scalars['String'];
};

export type UpdateSavedSearchPayload = {
    __typename?: 'UpdateSavedSearchPayload';
    savedSearch: SavedSearch;
};

export type UpdateScheduledEventTemplateInput = {
    availabilities: Array<AvailabilityInput>;
    duration: Scalars['Int'];
    expiresAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['String'];
    location?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    notes?: Maybe<Scalars['String']>;
    timezone?: Maybe<Scalars['String']>;
    type?: Maybe<EventType>;
};

export type UpdateScheduledEventTemplatePayload = {
    __typename?: 'UpdateScheduledEventTemplatePayload';
    scheduledEventTemplate: ScheduledEventTemplate;
};

export type UpdateUserSettingInput = {
    settingId: Scalars['String'];
    value: Scalars['String'];
};

export type UpdateUserSettingsInput = {
    settings: Array<UpdateUserSettingInput>;
};

export type UpdateUserSettingsPayload = {
    __typename?: 'UpdateUserSettingsPayload';
    result: Scalars['Boolean'];
};

export type UploadBlogPostCoverImageInput = {
    coverImage: Scalars['Upload'];
    id: Scalars['String'];
};

export type UploadBlogPostCoverImagePayload = {
    __typename?: 'UploadBlogPostCoverImagePayload';
    blogPost: BlogPost;
};

export type UploadCompanyLogoInput = {
    logo: Scalars['Upload'];
};

export type UploadImageInput = {
    image: Scalars['Upload'];
};

export type UploadJobseekerAvatarInput = {
    avatar: Scalars['Upload'];
};

export type UploadResumeInput = {
    name: Scalars['String'];
    resume: Scalars['Upload'];
};

export type User = RelayNode & {
    __typename?: 'User';
    admin?: Maybe<Admin>;
    blocked: Scalars['Boolean'];
    email: Scalars['String'];
    emailVerified: Scalars['Boolean'];
    employer?: Maybe<Employer>;
    id: Scalars['ID'];
    jobseeker?: Maybe<Jobseeker>;
    matrixAuth?: Maybe<MatrixAuthData>;
    role: Role;
};

export type UserConnection = {
    __typename?: 'UserConnection';
    edges: Array<UserEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type UserEdge = {
    __typename?: 'UserEdge';
    node: User;
};

export type UserSetting = RelayNode & {
    __typename?: 'UserSetting';
    id: Scalars['ID'];
    settingId: Scalars['String'];
    type?: Maybe<SettingType>;
    userId: Scalars['String'];
    value?: Maybe<Scalars['String']>;
};

export type UserSettingConnection = {
    __typename?: 'UserSettingConnection';
    edges: Array<UserSettingEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type UserSettingEdge = {
    __typename?: 'UserSettingEdge';
    node: UserSetting;
};

export type VerifyCompanyInput = {
    companyId: Scalars['String'];
};

export type VerifyCompanyPayload = {
    __typename?: 'VerifyCompanyPayload';
    company: Company;
};

export type VerifyEmailInput = {
    token: Scalars['String'];
};

export type VerifyEmailPayload = {
    __typename?: 'VerifyEmailPayload';
    success: Scalars['Boolean'];
};

export type ViewBlogPostInput = {
    blogPostId: Scalars['String'];
};

export type ViewBlogPostPayload = {
    __typename?: 'ViewBlogPostPayload';
    viewCount: Scalars['Int'];
};

export type ViewJobInput = {
    jobId: Scalars['String'];
};

export type ViewJobPayload = {
    __typename?: 'ViewJobPayload';
    viewCount: Scalars['Int'];
};

export type BlogPostPartsFragment = { __typename?: 'BlogPost' } & Pick<
    BlogPost,
    'id' | 'title' | 'slug' | 'body' | 'viewCount' | 'tags' | 'createdAt' | 'updatedAt'
> & { coverImage: { __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'> };

export type HiringStagePartsFragment = { __typename?: 'HiringStage' } & Pick<
    HiringStage,
    'title' | 'id' | 'status' | 'color' | 'processId' | 'version'
> & {
        triggers: { __typename?: 'StageActionConnection' } & {
            edges: Array<
                { __typename?: 'StageActionEdge' } & {
                    node: { __typename?: 'StageAction' } & Pick<StageAction, 'id' | 'type' | 'stageId'> & {
                            attachments: { __typename?: 'FileInfoConnection' } & {
                                edges: Array<
                                    { __typename?: 'FileInfoEdge' } & {
                                        node: { __typename?: 'FileInfo' } & Pick<
                                            FileInfo,
                                            'id' | 'name' | 'url' | 'extension'
                                        >;
                                    }
                                >;
                            };
                            payload: { __typename?: 'EmailActionPayload' } & Pick<
                                EmailActionPayload,
                                'from' | 'to' | 'toEmails' | 'subject' | 'body'
                            >;
                        };
                }
            >;
        };
    };

export type ApproveJobMutationVariables = Exact<{
    job: ApproveJobInput;
}>;

export type ApproveJobMutation = { __typename?: 'Mutation' } & {
    approveJob: { __typename?: 'ApproveJobPayload' } & { job: { __typename?: 'Job' } & Pick<Job, 'id' | 'status'> };
};

export type CreatePostMutationVariables = Exact<{
    values: CreateBlogPostInput;
}>;

export type CreatePostMutation = { __typename?: 'Mutation' } & {
    createPost: { __typename?: 'CreateBlogPostPayload' } & {
        post: { __typename?: 'BlogPost' } & Pick<BlogPost, 'id' | 'slug' | 'title'>;
    };
};

export type DeleteBlogPostMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteBlogPostMutation = { __typename?: 'Mutation' } & {
    deleteBlogPost: { __typename?: 'DeleteBlogPostPayload' } & Pick<DeleteBlogPostPayload, 'id'>;
};

export type DeleteCompanyMutationVariables = Exact<{
    companyId: Scalars['String'];
}>;

export type DeleteCompanyMutation = { __typename?: 'Mutation' } & {
    deleteCompany: { __typename?: 'DeleteCompanyPayload' } & {
        company: { __typename?: 'Company' } & Pick<Company, 'id'>;
    };
};

export type RejectJobMutationVariables = Exact<{
    job: RejectJobInput;
}>;

export type RejectJobMutation = { __typename?: 'Mutation' } & {
    rejectJob: { __typename?: 'RejectJobPayload' } & { job: { __typename?: 'Job' } & Pick<Job, 'id' | 'status'> };
};

export type UploadBlogPostCoverImageMutationVariables = Exact<{
    blogPost: UploadBlogPostCoverImageInput;
}>;

export type UploadBlogPostCoverImageMutation = { __typename?: 'Mutation' } & {
    uploadBlogPostCoverImage: { __typename?: 'UploadBlogPostCoverImagePayload' } & {
        blogPost: { __typename?: 'BlogPost' } & Pick<BlogPost, 'id'> & {
                coverImage: { __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>;
            };
    };
};

export type UpdateBlogPostMutationVariables = Exact<{
    blog: UpdateBlogPostInput;
}>;

export type UpdateBlogPostMutation = { __typename?: 'Mutation' } & {
    updateBlogPost: { __typename?: 'UpdateBlogPostPayload' } & {
        blogPost: { __typename?: 'BlogPost' } & Pick<BlogPost, 'id' | 'title' | 'body' | 'tags'>;
    };
};

export type UpdateCompanySlugMutationVariables = Exact<{
    company: UpdateCompanySlugInput;
}>;

export type UpdateCompanySlugMutation = { __typename?: 'Mutation' } & {
    updateCompanySlug: { __typename?: 'UpdateCompanySlugPayload' } & {
        company: { __typename?: 'Company' } & Pick<Company, 'id' | 'slug'>;
    };
};

export type VerifyCompanyMutationVariables = Exact<{
    company: VerifyCompanyInput;
}>;

export type VerifyCompanyMutation = { __typename?: 'Mutation' } & {
    verifyCompany: { __typename?: 'VerifyCompanyPayload' } & {
        company: { __typename?: 'Company' } & Pick<Company, 'id' | 'verified'>;
    };
};

export type VerifyEmailMutationVariables = Exact<{
    input: VerifyEmailInput;
}>;

export type VerifyEmailMutation = { __typename?: 'Mutation' } & {
    verifyEmail: { __typename?: 'VerifyEmailPayload' } & Pick<VerifyEmailPayload, 'success'>;
};

export type ChangePasswordMutationVariables = Exact<{
    input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = { __typename?: 'Mutation' } & {
    changePassword: { __typename?: 'ChangePasswordPayload' } & Pick<ChangePasswordPayload, 'success'>;
};

export type FacebookLoginMutationVariables = Exact<{ [key: string]: never }>;

export type FacebookLoginMutation = { __typename?: 'Mutation' } & {
    facebookLogin: { __typename?: 'LoginPayload' } & Pick<LoginPayload, 'accessToken'> & {
            matrixAuth?: Maybe<
                { __typename?: 'MatrixAuthData' } & Pick<
                    MatrixAuthData,
                    'access_token' | 'device_id' | 'home_server' | 'user_id'
                >
            >;
        };
};

export type ForgotPasswordMutationVariables = Exact<{
    input: ForgotPasswordInput;
}>;

export type ForgotPasswordMutation = { __typename?: 'Mutation' } & {
    forgotPassword: { __typename?: 'ForgotPasswordPayload' } & Pick<ForgotPasswordPayload, 'sent'>;
};

export type GoogleLoginMutationVariables = Exact<{ [key: string]: never }>;

export type GoogleLoginMutation = { __typename?: 'Mutation' } & {
    googleLogin: { __typename?: 'LoginPayload' } & Pick<LoginPayload, 'accessToken'> & {
            matrixAuth?: Maybe<
                { __typename?: 'MatrixAuthData' } & Pick<
                    MatrixAuthData,
                    'access_token' | 'device_id' | 'home_server' | 'user_id'
                >
            >;
        };
};

export type SignInMutationVariables = Exact<{
    credentials: CredentialsInput;
}>;

export type SignInMutation = { __typename?: 'Mutation' } & {
    login: { __typename?: 'LoginPayload' } & Pick<LoginPayload, 'accessToken'> & {
            matrixAuth?: Maybe<
                { __typename?: 'MatrixAuthData' } & Pick<
                    MatrixAuthData,
                    'access_token' | 'device_id' | 'home_server' | 'user_id'
                >
            >;
        };
};

export type RegisterEmployerMutationVariables = Exact<{
    employer: RegisterEmployerInput;
}>;

export type RegisterEmployerMutation = { __typename?: 'Mutation' } & {
    registerEmployer: { __typename?: 'RegistrationPayload' } & Pick<RegistrationPayload, 'id'>;
};

export type RegisterJobSeekerMutationVariables = Exact<{
    jobseeker: RegisterJobseekerInput;
}>;

export type RegisterJobSeekerMutation = { __typename?: 'Mutation' } & {
    registerJobseeker: { __typename?: 'RegistrationPayload' } & Pick<RegistrationPayload, 'id'>;
};

export type RegisterMatrixUserMutationVariables = Exact<{
    input: RegisterMatrixUserInput;
}>;

export type RegisterMatrixUserMutation = { __typename?: 'Mutation' } & {
    registerMatrixUser: { __typename?: 'MatrixAuthData' } & Pick<
        MatrixAuthData,
        'access_token' | 'device_id' | 'home_server' | 'user_id'
    >;
};

export type ResetPasswordMutationVariables = Exact<{
    input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = { __typename?: 'Mutation' } & {
    resetPassword: { __typename?: 'ResetPasswordPayload' } & Pick<ResetPasswordPayload, 'success'>;
};

export type SendVerifyEmailMutationVariables = Exact<{
    input: SendVerifyEmailInput;
}>;

export type SendVerifyEmailMutation = { __typename?: 'Mutation' } & {
    sendVerificationEmail: { __typename?: 'SendVerifyEmailPayload' } & Pick<SendVerifyEmailPayload, 'sent'>;
};

export type ViewBlogPostMutationVariables = Exact<{
    blogPost: ViewBlogPostInput;
}>;

export type ViewBlogPostMutation = { __typename?: 'Mutation' } & {
    viewBlogPost: { __typename?: 'ViewBlogPostPayload' } & Pick<ViewBlogPostPayload, 'viewCount'>;
};

export type ConfirmScheduledEventMutationVariables = Exact<{
    scheduledEvent: ConfirmScheduledEventInput;
}>;

export type ConfirmScheduledEventMutation = { __typename?: 'Mutation' } & {
    confirmScheduledEvent: { __typename?: 'ConfirmScheduledEventPayload' } & {
        scheduledEvent: { __typename?: 'ScheduledEvent' } & Pick<ScheduledEvent, 'name' | 'id'>;
    };
};

export type CreateCalendarEventMutationVariables = Exact<{
    calendarEvent: CreateCalendarEventInput;
}>;

export type CreateCalendarEventMutation = { __typename?: 'Mutation' } & {
    createCalendarEvent: { __typename?: 'CreateCalendarEventPayload' } & {
        calendarEvent: { __typename?: 'CalendarEvent' } & Pick<
            CalendarEvent,
            'id' | 'start' | 'end' | 'date' | 'title' | 'duration' | 'userId' | 'createdAt'
        >;
    };
};

export type CreateScheduledEventMutationVariables = Exact<{
    scheduledEvent: CreateScheduledEventInput;
}>;

export type CreateScheduledEventMutation = { __typename?: 'Mutation' } & {
    createScheduledEvent: { __typename?: 'CreateScheduledEventPayload' } & {
        scheduledEvent: { __typename?: 'ScheduledEvent' } & Pick<ScheduledEvent, 'id'>;
    };
};

export type DeleteCalendarEventMutationVariables = Exact<{
    eventId: Scalars['String'];
}>;

export type DeleteCalendarEventMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'deleteCalendarEvent'>;

export type SendInvitationToJobseekerMutationVariables = Exact<{
    input: CreateApplicationInviteInput;
}>;

export type SendInvitationToJobseekerMutation = { __typename?: 'Mutation' } & {
    sendInvitationToJobseeker: { __typename?: 'ApplicationInvitePayload' } & {
        applicationInvite: { __typename?: 'ApplicationInvite' } & Pick<
            ApplicationInvite,
            'id' | 'jobseekerId' | 'jobId'
        >;
    };
};

export type CreateJobApplicationCommentMutationVariables = Exact<{
    comment: CreateJobApplicationCommentInput;
}>;

export type CreateJobApplicationCommentMutation = { __typename?: 'Mutation' } & {
    createJobApplicationComment: { __typename?: 'CreateJobApplicationCommentPayload' } & {
        jobApplicationComment: { __typename?: 'JobApplicationComment' } & Pick<JobApplicationComment, 'id' | 'content'>;
    };
};

export type DeleteJobApplicationCommentMutationVariables = Exact<{
    commentId: Scalars['String'];
}>;

export type DeleteJobApplicationCommentMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'deleteJobApplicationComment'
>;

export type MoveApplicationToStageMutationVariables = Exact<{
    applicationId: Scalars['String'];
    stageId: Scalars['String'];
    triggers?: Maybe<Array<EmailStageActionOverrideInput> | EmailStageActionOverrideInput>;
}>;

export type MoveApplicationToStageMutation = { __typename?: 'Mutation' } & {
    moveApplicationToStage?: Maybe<
        { __typename?: 'MoveApplicationToStagePayload' } & {
            application: { __typename?: 'JobApplication' } & Pick<JobApplication, 'id' | 'stageId'> & {
                    stage?: Maybe<{ __typename?: 'HiringStage' } & Pick<HiringStage, 'id' | 'title'>>;
                };
        }
    >;
};

export type UpdateJobApplicationCommentMutationVariables = Exact<{
    comment: UpdateJobApplicationCommentInput;
}>;

export type UpdateJobApplicationCommentMutation = { __typename?: 'Mutation' } & {
    updateJobApplicationComment: { __typename?: 'UpdateJobApplicationCommentPayload' } & {
        jobApplicationComment: { __typename?: 'JobApplicationComment' } & Pick<JobApplicationComment, 'id' | 'content'>;
    };
};

export type BuyJobSlotMutationVariables = Exact<{
    jobId: Scalars['String'];
}>;

export type BuyJobSlotMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'buyJobSlot'>;

export type AddExternalCandidateMutationVariables = Exact<{
    input: AddExternalCandidateInput;
}>;

export type AddExternalCandidateMutation = { __typename?: 'Mutation' } & {
    addExternalCandidate: { __typename?: 'AddExternalCandidatePayload' } & {
        candidate: { __typename?: 'Candidate' } & Pick<Candidate, 'id'>;
    };
};

export type RejectMultipleCandidatesMutationVariables = Exact<{
    input: RejectMultipleCandidatesInput;
}>;

export type RejectMultipleCandidatesMutation = { __typename?: 'Mutation' } & {
    rejectMultipleCandidates?: Maybe<
        { __typename?: 'JobApplicationConnection' } & Pick<JobApplicationConnection, 'totalCount'> & {
                pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'totalPages'>;
                edges: Array<
                    { __typename?: 'JobApplicationEdge' } & {
                        node: { __typename?: 'JobApplication' } & Pick<JobApplication, 'id'> & {
                                applicant?: Maybe<
                                    { __typename?: 'Jobseeker' } & Pick<
                                        Jobseeker,
                                        'id' | 'firstName' | 'lastName' | 'createdAt'
                                    > & {
                                            avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                                            contacts?: Maybe<
                                                { __typename?: 'ContactInfo' } & Pick<ContactInfo, 'emails'> & {
                                                        phones?: Maybe<
                                                            Array<
                                                                { __typename?: 'ContactPhone' } & Pick<
                                                                    ContactPhone,
                                                                    'number' | 'comment'
                                                                >
                                                            >
                                                        >;
                                                    }
                                            >;
                                        }
                                >;
                                stage?: Maybe<{ __typename?: 'HiringStage' } & Pick<HiringStage, 'status' | 'color'>>;
                            };
                    }
                >;
            }
    >;
};

export type CreateHiringProcessMutationVariables = Exact<{
    title: Scalars['String'];
}>;

export type CreateHiringProcessMutation = { __typename?: 'Mutation' } & {
    createHiringProcess: { __typename?: 'CreateHiringProcessPayload' } & {
        hiringProcess: { __typename?: 'HiringProcess' } & Pick<
            HiringProcess,
            'id' | 'title' | 'employerId' | 'createdAt' | 'updatedAt' | 'version'
        >;
    };
};

export type DeleteHiringProcessMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteHiringProcessMutation = { __typename?: 'Mutation' } & {
    deleteHiringProcess: { __typename?: 'DeleteHiringProcessPayload' } & Pick<DeleteHiringProcessPayload, 'id'>;
};

export type EditHiringProcessMutationVariables = Exact<{
    id: Scalars['String'];
    title: Scalars['String'];
}>;

export type EditHiringProcessMutation = { __typename?: 'Mutation' } & {
    editHiringProcess: { __typename?: 'EditHiringProcessPayload' } & {
        hiringProcess: { __typename?: 'HiringProcess' } & Pick<
            HiringProcess,
            'id' | 'title' | 'employerId' | 'createdAt' | 'updatedAt' | 'version'
        >;
    };
};

export type CreateHiringStageMutationVariables = Exact<{
    title: Scalars['String'];
    color: Scalars['String'];
    status: HiringStageStatus;
    processId: Scalars['String'];
}>;

export type CreateHiringStageMutation = { __typename?: 'Mutation' } & {
    createHiringStage: { __typename?: 'CreateHiringStagePayload' } & {
        hiringStage: { __typename?: 'HiringStage' } & Pick<HiringStage, 'title'>;
    };
};

export type DeleteHiringStageMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteHiringStageMutation = { __typename?: 'Mutation' } & {
    deleteHiringStage: { __typename?: 'DeleteHiringStagePayload' } & Pick<DeleteHiringStagePayload, 'id'>;
};

export type EditHiringStageMutationVariables = Exact<{
    id: Scalars['String'];
    title: Scalars['String'];
    color: Scalars['String'];
    status: HiringStageStatus;
}>;

export type EditHiringStageMutation = { __typename?: 'Mutation' } & {
    editHiringStage: { __typename?: 'EditHiringStagePayload' } & {
        hiringStage: { __typename?: 'HiringStage' } & Pick<
            HiringStage,
            'id' | 'title' | 'color' | 'status' | 'order' | 'createdAt' | 'updatedAt'
        >;
    };
};

export type ReorderHiringStagesMutationVariables = Exact<{
    stage: ReorderHiringStageInput;
}>;

export type ReorderHiringStagesMutation = { __typename?: 'Mutation' } & {
    reorderHiringStage: { __typename?: 'ReorderHiringStagePayload' } & {
        stages: { __typename?: 'HiringStageConnection' } & {
            edges: Array<
                { __typename?: 'HiringStageEdge' } & {
                    node: { __typename?: 'HiringStage' } & Pick<
                        HiringStage,
                        'id' | 'status' | 'order' | 'color' | 'title' | 'processId' | 'version'
                    > & {
                            triggers: { __typename?: 'StageActionConnection' } & Pick<
                                StageActionConnection,
                                'totalCount'
                            >;
                        };
                }
            >;
        };
    };
};

export type CreateJobMutationVariables = Exact<{
    values: CreateJobInput;
}>;

export type CreateJobMutation = { __typename?: 'Mutation' } & {
    createJob: { __typename?: 'CreateJobPayload' } & {
        job: { __typename?: 'Job' } & Pick<Job, 'id' | 'slug' | 'title'>;
    };
};

export type DeleteJobMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteJobMutation = { __typename?: 'Mutation' } & {
    deleteJob: { __typename?: 'DeleteJobPayload' } & Pick<DeleteJobPayload, 'id'>;
};

export type EditJobMutationVariables = Exact<{
    values: EditJobInput;
}>;

export type EditJobMutation = { __typename?: 'Mutation' } & {
    editJob: { __typename?: 'EditJobPayload' } & { job: { __typename?: 'Job' } & Pick<Job, 'id' | 'slug' | 'title'> };
};

export type PublishJobMutationVariables = Exact<{
    job: PublishJobInput;
}>;

export type PublishJobMutation = { __typename?: 'Mutation' } & {
    publishJob: { __typename?: 'PublishJobPayload' } & {
        job: { __typename?: 'Job' } & Pick<
            Job,
            'id' | 'slug' | 'title' | 'status' | 'viewCount' | 'expiresAt' | 'createdAt' | 'publishedAt'
        > & {
                applications?: Maybe<
                    { __typename?: 'JobApplicationConnection' } & Pick<JobApplicationConnection, 'totalCount'>
                >;
            };
    };
};

export type CreateScheduledEventTemplateMutationVariables = Exact<{
    scheduledEventTemplate: CreateScheduledEventTemplateInput;
}>;

export type CreateScheduledEventTemplateMutation = { __typename?: 'Mutation' } & {
    createScheduledEventTemplate: { __typename?: 'CreateScheduledEventTemplatePayload' } & {
        scheduledEventTemplate: { __typename?: 'ScheduledEventTemplate' } & Pick<ScheduledEventTemplate, 'id'>;
    };
};

export type DeleteScheduledEventTemplateMutationVariables = Exact<{
    templateId: Scalars['String'];
}>;

export type DeleteScheduledEventTemplateMutation = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'deleteScheduledEventTemplate'
>;

export type UpdateScheduledEventTemplateMutationVariables = Exact<{
    scheduledEventTemplate: UpdateScheduledEventTemplateInput;
}>;

export type UpdateScheduledEventTemplateMutation = { __typename?: 'Mutation' } & {
    updateScheduledEventTemplate: { __typename?: 'UpdateScheduledEventTemplatePayload' } & {
        scheduledEventTemplate: { __typename?: 'ScheduledEventTemplate' } & Pick<ScheduledEventTemplate, 'id'>;
    };
};

export type CreateEmailStageActionMutationVariables = Exact<{
    trigger: CreateEmailStageActionInput;
}>;

export type CreateEmailStageActionMutation = { __typename?: 'Mutation' } & {
    createStageTrigger: { __typename?: 'CreateEmailStageActionPayload' } & {
        hiringStage: { __typename?: 'HiringStage' } & HiringStagePartsFragment;
    };
};

export type DeleteStageActionMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteStageActionMutation = { __typename?: 'Mutation' } & {
    deleteStageAction: { __typename?: 'DeleteStageActionPayload' } & Pick<DeleteStageActionPayload, 'id'>;
};

export type EditEmailStageActionMutationVariables = Exact<{
    trigger: EditEmailStageActionInput;
}>;

export type EditEmailStageActionMutation = { __typename?: 'Mutation' } & {
    editStageTrigger: { __typename?: 'EditEmailStageActionPayload' } & {
        trigger: { __typename?: 'StageAction' } & Pick<StageAction, 'id' | 'type' | 'stageId'> & {
                payload: { __typename?: 'EmailActionPayload' } & Pick<
                    EmailActionPayload,
                    'to' | 'subject' | 'body' | 'from' | 'toEmails'
                >;
            };
    };
};

export type UpdateEmployerAvatarMutationVariables = Exact<{
    employer: UpdateEmployerAvatarInput;
}>;

export type UpdateEmployerAvatarMutation = { __typename?: 'Mutation' } & {
    updateEmployerAvatar: { __typename?: 'UpdateEmployerAvatarPayload' } & {
        employer: { __typename?: 'Employer' } & Pick<Employer, 'id'> & {
                avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
            };
    };
};

export type UpdateCompanyLogoMutationVariables = Exact<{
    input: UploadCompanyLogoInput;
}>;

export type UpdateCompanyLogoMutation = { __typename?: 'Mutation' } & {
    updateCompanyLogo: { __typename?: 'Company' } & {
        logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'name'>>;
    };
};

export type UpdateCompanyMutationVariables = Exact<{
    company: UpdateCompanyInput;
}>;

export type UpdateCompanyMutation = { __typename?: 'Mutation' } & {
    updateCompany: { __typename?: 'UpdateCompanyPayload' } & {
        company: { __typename?: 'Company' } & Pick<
            Company,
            'name' | 'type' | 'regionId' | 'industryId' | 'website' | 'description'
        >;
    };
};

export type UpdateEmployerMutationVariables = Exact<{
    input: UpdateEmployerInput;
}>;

export type UpdateEmployerMutation = { __typename?: 'Mutation' } & {
    updateEmployer: { __typename?: 'UpdateEmployerPayload' } & {
        employer: { __typename?: 'Employer' } & Pick<Employer, 'id' | 'firstName' | 'lastName' | 'phoneNumber'>;
    };
};

export type UpdatePricingPlanMutationVariables = Exact<{
    pricingPlanId: Scalars['String'];
}>;

export type UpdatePricingPlanMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'updatePricingPlan'>;

export type ApplyToJobMutationVariables = Exact<{
    jobId: Scalars['String'];
    coverLetter?: Maybe<Scalars['String']>;
}>;

export type ApplyToJobMutation = { __typename?: 'Mutation' } & {
    applyToJob: { __typename?: 'ApplyToJobPayload' } & {
        application: { __typename?: 'JobApplication' } & Pick<JobApplication, 'id' | 'applicantId'>;
    };
};

export type BookmarkJobMutationVariables = Exact<{
    jobId: Scalars['String'];
}>;

export type BookmarkJobMutation = { __typename?: 'Mutation' } & {
    bookmarkJob: { __typename?: 'BookmarkJobPayload' } & {
        job: { __typename?: 'Job' } & Pick<Job, 'id' | 'bookmarked'>;
    };
};

export type ReactivateJobMutationVariables = Exact<{
    job: ReactivateJobInput;
}>;

export type ReactivateJobMutation = { __typename?: 'Mutation' } & {
    reactivateJob: { __typename?: 'ReactivateJobPayload' } & { job: { __typename?: 'Job' } & Pick<Job, 'id'> };
};

export type ViewJobMutationVariables = Exact<{
    jobId: Scalars['String'];
}>;

export type ViewJobMutation = { __typename?: 'Mutation' } & {
    viewJob: { __typename?: 'ViewJobPayload' } & Pick<ViewJobPayload, 'viewCount'>;
};

export type AcceptApplicationInviteMutationVariables = Exact<{
    input: AcceptApplicationInviteInput;
}>;

export type AcceptApplicationInviteMutation = { __typename?: 'Mutation' } & {
    acceptApplicationInvite: { __typename?: 'AcceptApplicationInvitePayload' } & {
        applicationInvite: { __typename?: 'ApplicationInvite' } & Pick<ApplicationInvite, 'id'>;
    };
};

export type AcceptOfferMutationVariables = Exact<{
    applicationId: Scalars['String'];
}>;

export type AcceptOfferMutation = { __typename?: 'Mutation' } & {
    acceptOffer?: Maybe<
        { __typename?: 'MoveApplicationToStagePayload' } & {
            application: { __typename?: 'JobApplication' } & Pick<JobApplication, 'id'> & {
                    stage?: Maybe<{ __typename?: 'HiringStage' } & Pick<HiringStage, 'id' | 'status' | 'color'>>;
                    statusHistories?: Maybe<
                        { __typename?: 'JobApplicationStatusHistoryConnection' } & {
                            edges: Array<
                                { __typename?: 'JobApplicationStatusHistoryEdge' } & {
                                    node: { __typename?: 'JobApplicationStatusHistory' } & Pick<
                                        JobApplicationStatusHistory,
                                        'id' | 'reason'
                                    >;
                                }
                            >;
                        }
                    >;
                };
        }
    >;
};

export type CreateSavedSearchMutationVariables = Exact<{
    search: CreateSavedSearchInput;
}>;

export type CreateSavedSearchMutation = { __typename?: 'Mutation' } & {
    saveSearch: { __typename?: 'CreateSavedSearchPayload' } & {
        savedSearch: { __typename?: 'SavedSearch' } & Pick<SavedSearch, 'id'>;
    };
};

export type DeleteResumeMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteResumeMutation = { __typename?: 'Mutation' } & {
    deleteResume: { __typename?: 'Resume' } & Pick<Resume, 'id'>;
};

export type DeleteSavedSearchMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteSavedSearchMutation = { __typename?: 'Mutation' } & {
    deleteSavedSearch: { __typename?: 'DeleteSavedSearchPayload' } & Pick<DeleteSavedSearchPayload, 'id'>;
};

export type RejectApplicationInviteMutationVariables = Exact<{
    input: RejectApplicationInviteInput;
}>;

export type RejectApplicationInviteMutation = { __typename?: 'Mutation' } & {
    rejectApplicationInvite: { __typename?: 'RejectApplicationInvitePayload' } & {
        applicationInvite: { __typename?: 'ApplicationInvite' } & Pick<ApplicationInvite, 'id'>;
    };
};

export type RejectOfferMutationVariables = Exact<{
    input: RejectOfferInput;
}>;

export type RejectOfferMutation = { __typename?: 'Mutation' } & {
    rejectOffer?: Maybe<
        { __typename?: 'MoveApplicationToStagePayload' } & {
            application: { __typename?: 'JobApplication' } & Pick<JobApplication, 'id'> & {
                    stage?: Maybe<{ __typename?: 'HiringStage' } & Pick<HiringStage, 'id' | 'status' | 'color'>>;
                    statusHistories?: Maybe<
                        { __typename?: 'JobApplicationStatusHistoryConnection' } & {
                            edges: Array<
                                { __typename?: 'JobApplicationStatusHistoryEdge' } & {
                                    node: { __typename?: 'JobApplicationStatusHistory' } & Pick<
                                        JobApplicationStatusHistory,
                                        'id' | 'reason'
                                    >;
                                }
                            >;
                        }
                    >;
                };
        }
    >;
};

export type UpdateJobseekerAvatarMutationVariables = Exact<{
    input: UploadJobseekerAvatarInput;
}>;

export type UpdateJobseekerAvatarMutation = { __typename?: 'Mutation' } & {
    updateJobseekerAvatar: { __typename?: 'Jobseeker' } & Pick<Jobseeker, 'id'> & {
            avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
        };
};

export type UpdateJobseekerProfileMutationVariables = Exact<{
    profile: UpdateJobseekerProfileInput;
}>;

export type UpdateJobseekerProfileMutation = { __typename?: 'Mutation' } & {
    updateJobseekerProfile: { __typename?: 'UpdateJobseekerProfilePayload' } & {
        profile: { __typename?: 'Jobseeker' } & Pick<
            Jobseeker,
            | 'firstName'
            | 'middleName'
            | 'lastName'
            | 'gender'
            | 'dateOfBirth'
            | 'placeOfBirth'
            | 'regionId'
            | 'categoryId'
            | 'keySkills'
        > & {
                address?: Maybe<
                    { __typename?: 'Address' } & Pick<Address, 'building' | 'street' | 'description'> & {
                            location?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'longitude' | 'latitude'>>;
                            metroStations?: Maybe<
                                Array<
                                    { __typename?: 'MetroStation' } & Pick<MetroStation, 'name'> & {
                                            location?: Maybe<
                                                { __typename?: 'Location' } & Pick<Location, 'longitude' | 'latitude'>
                                            >;
                                        }
                                >
                            >;
                        }
                >;
                contacts?: Maybe<
                    { __typename?: 'ContactInfo' } & Pick<
                        ContactInfo,
                        'emails' | 'linkedInUrl' | 'skypeAddress' | 'facebookUrl' | 'twitterUrl' | 'githubUrl'
                    > & {
                            phones?: Maybe<
                                Array<{ __typename?: 'ContactPhone' } & Pick<ContactPhone, 'number' | 'comment'>>
                            >;
                        }
                >;
                languages: { __typename?: 'JobseekerLanguageConnection' } & {
                    edges: Array<
                        { __typename?: 'JobseekerLanguageEdge' } & {
                            node: { __typename?: 'JobseekerLanguage' } & Pick<
                                JobseekerLanguage,
                                'id' | 'languageId' | 'level'
                            >;
                        }
                    >;
                };
                educations: { __typename?: 'JobseekerEducationConnection' } & {
                    edges: Array<
                        { __typename?: 'JobseekerEducationEdge' } & {
                            node: { __typename?: 'JobseekerEducation' } & Pick<
                                JobseekerEducation,
                                | 'id'
                                | 'school'
                                | 'location'
                                | 'level'
                                | 'field'
                                | 'startDate'
                                | 'endDate'
                                | 'ongoing'
                                | 'description'
                            >;
                        }
                    >;
                };
                experiences: { __typename?: 'JobseekerExperienceConnection' } & {
                    edges: Array<
                        { __typename?: 'JobseekerExperienceEdge' } & {
                            node: { __typename?: 'JobseekerExperience' } & Pick<
                                JobseekerExperience,
                                'id' | 'company' | 'position' | 'startDate' | 'endDate' | 'ongoing' | 'description'
                            >;
                        }
                    >;
                };
            };
    };
};

export type UpdateSavedSearchMutationVariables = Exact<{
    search: UpdateSavedSearchInput;
}>;

export type UpdateSavedSearchMutation = { __typename?: 'Mutation' } & {
    updateSavedSearch: { __typename?: 'UpdateSavedSearchPayload' } & {
        savedSearch: { __typename?: 'SavedSearch' } & Pick<SavedSearch, 'id' | 'name' | 'alertPeriod'>;
    };
};

export type UploadResumeMutationVariables = Exact<{
    input: UploadResumeInput;
}>;

export type UploadResumeMutation = { __typename?: 'Mutation' } & {
    uploadResume: { __typename?: 'Resume' } & Pick<Resume, 'name'>;
};

export type RegisterDeviceMutationVariables = Exact<{
    input: RegisterDeviceInput;
}>;

export type RegisterDeviceMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'registerDevice'>;

export type UpdateCalendarEventMutationVariables = Exact<{
    calendarEvent: UpdateCalendarEventInput;
}>;

export type UpdateCalendarEventMutation = { __typename?: 'Mutation' } & {
    updateCalendarEvent: { __typename?: 'UpdateCalendarEventPayload' } & {
        calendarEvent: { __typename?: 'CalendarEvent' } & Pick<
            CalendarEvent,
            'id' | 'title' | 'start' | 'end' | 'duration' | 'type'
        >;
    };
};

export type UpdateUserSettingMutationVariables = Exact<{
    input: UpdateUserSettingInput;
}>;

export type UpdateUserSettingMutation = { __typename?: 'Mutation' } & {
    updateUserSetting: { __typename?: 'UpdateUserSettingsPayload' } & Pick<UpdateUserSettingsPayload, 'result'>;
};

export type UpdateUserSettingsMutationVariables = Exact<{
    input: UpdateUserSettingsInput;
}>;

export type UpdateUserSettingsMutation = { __typename?: 'Mutation' } & {
    updateUserSettings: { __typename?: 'UpdateUserSettingsPayload' } & Pick<UpdateUserSettingsPayload, 'result'>;
};

export type UploadImageMutationVariables = Exact<{
    input: UploadImageInput;
}>;

export type UploadImageMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'uploadImage'>;

export type AllBlogPostingsQueryVariables = Exact<{ [key: string]: never }>;

export type AllBlogPostingsQuery = { __typename?: 'Query' } & {
    blogPosts: { __typename?: 'BlogPostConnection' } & {
        edges: Array<
            { __typename?: 'BlogPostEdge' } & {
                node: { __typename?: 'BlogPost' } & Pick<BlogPost, 'id' | 'title' | 'body' | 'slug' | 'createdAt'> & {
                        coverImage: { __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>;
                    };
            }
        >;
    };
};

export type BlogPostQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type BlogPostQuery = { __typename?: 'Query' } & {
    blogPost: { __typename?: 'BlogPost' } & Pick<BlogPost, 'id' | 'title' | 'slug' | 'body' | 'tags'> & {
            coverImage: { __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>;
        };
};

export type RegisteredCompaniesQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<CompanyWhereInput>;
    orderBy?: Maybe<CompanyOrderBy>;
}>;

export type RegisteredCompaniesQuery = { __typename?: 'Query' } & {
    registeredCompanies: { __typename?: 'CompanyConnection' } & Pick<CompanyConnection, 'totalCount'> & {
            pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'totalPages'>;
            edges: Array<
                { __typename?: 'CompanyEdge' } & {
                    node: { __typename?: 'Company' } & Pick<
                        Company,
                        'id' | 'name' | 'type' | 'slug' | 'verified' | 'createdAt'
                    > & {
                            region: { __typename?: 'Region' } & Pick<Region, 'id' | 'name'>;
                            jobs?: Maybe<{ __typename?: 'JobConnection' } & Pick<JobConnection, 'totalCount'>>;
                            logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                            currentPricingPlan?: Maybe<
                                { __typename?: 'PurchasedPlan' } & Pick<PurchasedPlan, 'startsAt' | 'expiresAt'> & {
                                        plan: { __typename?: 'PricingPlan' } & Pick<PricingPlan, 'id' | 'name'>;
                                    }
                            >;
                        };
                }
            >;
        };
};

export type AllJobPostingsQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<JobForAdminWhereInput>;
    orderBy?: Maybe<JobOrderBy>;
}>;

export type AllJobPostingsQuery = { __typename?: 'Query' } & {
    allJobPostings: { __typename?: 'JobConnection' } & Pick<JobConnection, 'totalCount'> & {
            pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'totalPages'>;
            edges: Array<
                { __typename?: 'JobEdge' } & {
                    node: { __typename?: 'Job' } & Pick<
                        Job,
                        'id' | 'slug' | 'title' | 'viewCount' | 'status' | 'expiresAt' | 'createdAt' | 'publishedAt'
                    > & {
                            company: { __typename?: 'Company' } & Pick<Company, 'id' | 'name' | 'slug'>;
                            applications?: Maybe<
                                { __typename?: 'JobApplicationConnection' } & Pick<
                                    JobApplicationConnection,
                                    'totalCount'
                                >
                            >;
                        };
                }
            >;
        };
};

export type PaymentsQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<PaymentWhereInput>;
    orderBy?: Maybe<PaymentOrderBy>;
}>;

export type PaymentsQuery = { __typename?: 'Query' } & {
    payments: { __typename?: 'PaymentConnection' } & Pick<PaymentConnection, 'totalCount'> & {
            pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'totalPages'>;
            edges: Array<
                { __typename?: 'PaymentEdge' } & {
                    node: { __typename?: 'Payment' } & Pick<
                        Payment,
                        | 'id'
                        | 'firstName'
                        | 'lastName'
                        | 'companyName'
                        | 'price'
                        | 'status'
                        | 'date'
                        | 'companySlug'
                        | 'companyId'
                    >;
                }
            >;
        };
};

export type BlogPostDetailQueryVariables = Exact<{
    slug: Scalars['String'];
}>;

export type BlogPostDetailQuery = { __typename?: 'Query' } & {
    blogPost: { __typename?: 'BlogPost' } & {
        relatedBlogPosts: { __typename?: 'BlogPostConnection' } & {
            edges: Array<
                { __typename?: 'BlogPostEdge' } & { node: { __typename?: 'BlogPost' } & BlogPostPartsFragment }
            >;
        };
    } & BlogPostPartsFragment;
};

export type BlogPostsQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<BlogPostWhereInput>;
    orderBy?: Maybe<BlogPostOrderBy>;
}>;

export type BlogPostsQuery = { __typename?: 'Query' } & {
    blogPosts: { __typename?: 'BlogPostConnection' } & Pick<BlogPostConnection, 'totalCount'> & {
            pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'totalPages'>;
            edges: Array<
                { __typename?: 'BlogPostEdge' } & { node: { __typename?: 'BlogPost' } & BlogPostPartsFragment }
            >;
        };
};

export type CalendarEventsQueryVariables = Exact<{
    where: CalendarEventWhereInput;
}>;

export type CalendarEventsQuery = { __typename?: 'Query' } & {
    calendarEvents: { __typename?: 'CalendarEventConnection' } & Pick<CalendarEventConnection, 'totalCount'> & {
            edges: Array<
                { __typename?: 'CalendarEventEdge' } & {
                    node: { __typename?: 'CalendarEvent' } & Pick<
                        CalendarEvent,
                        'id' | 'start' | 'end' | 'date' | 'title' | 'duration' | 'type'
                    >;
                }
            >;
        };
};

export type CategoryListQueryVariables = Exact<{
    whereInput?: Maybe<CategoryWhereInput>;
}>;

export type CategoryListQuery = { __typename?: 'Query' } & {
    categories: { __typename?: 'CategoryConnection' } & Pick<CategoryConnection, 'totalCount'> & {
            edges: Array<
                { __typename?: 'CategoryEdge' } & {
                    node: { __typename?: 'Category' } & Pick<Category, 'id' | 'order' | 'name'>;
                }
            >;
        };
};

export type ChatableUsersQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<ChatableUsersWhereInput>;
}>;

export type ChatableUsersQuery = { __typename?: 'Query' } & {
    chatableUsers: { __typename?: 'UserConnection' } & {
        edges: Array<
            { __typename?: 'UserEdge' } & {
                node: { __typename?: 'User' } & Pick<User, 'id'> & {
                        matrixAuth?: Maybe<
                            { __typename?: 'MatrixAuthData' } & Pick<
                                MatrixAuthData,
                                'access_token' | 'device_id' | 'home_server' | 'user_id'
                            >
                        >;
                    };
            }
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage'>;
    };
};

export type CompanyListQueryVariables = Exact<{
    where?: Maybe<CompanyWhereInput>;
    page?: Maybe<Scalars['Int']>;
    orderBy?: Maybe<CompanyOrderBy>;
}>;

export type CompanyListQuery = { __typename?: 'Query' } & {
    companies: { __typename?: 'CompanyConnection' } & Pick<CompanyConnection, 'totalCount'> & {
            pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'totalPages'>;
            edges: Array<
                { __typename?: 'CompanyEdge' } & {
                    node: { __typename?: 'Company' } & Pick<Company, 'id' | 'name' | 'type' | 'slug' | 'verified'> & {
                            logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                            region: { __typename?: 'Region' } & Pick<Region, 'id' | 'name'>;
                            jobs?: Maybe<{ __typename?: 'JobConnection' } & Pick<JobConnection, 'totalCount'>>;
                        };
                }
            >;
        };
};

export type CompanyQueryVariables = Exact<{
    id?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
}>;

export type CompanyQuery = { __typename?: 'Query' } & {
    company: { __typename?: 'Company' } & Pick<
        Company,
        'id' | 'slug' | 'name' | 'logoId' | 'verified' | 'type' | 'website' | 'description'
    > & {
            employers?: Maybe<
                { __typename?: 'EmployerConnection' } & {
                    edges: Array<
                        { __typename?: 'EmployerEdge' } & {
                            node: { __typename?: 'Employer' } & Pick<
                                Employer,
                                'id' | 'firstName' | 'lastName' | 'phoneNumber'
                            > & { user: { __typename?: 'User' } & Pick<User, 'id' | 'email'> };
                        }
                    >;
                }
            >;
            region: { __typename?: 'Region' } & Pick<Region, 'id' | 'name'>;
            logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
            jobs?: Maybe<
                { __typename?: 'JobConnection' } & {
                    edges: Array<
                        { __typename?: 'JobEdge' } & {
                            node: { __typename?: 'Job' } & Pick<
                                Job,
                                | 'id'
                                | 'slug'
                                | 'title'
                                | 'employment'
                                | 'createdAt'
                                | 'publishedAt'
                                | 'applied'
                                | 'bookmarked'
                                | 'viewCount'
                                | 'source'
                            > & {
                                    salary?: Maybe<{ __typename?: 'Salary' } & Pick<Salary, 'from' | 'to'>>;
                                    company: { __typename?: 'Company' } & Pick<
                                        Company,
                                        'id' | 'slug' | 'name' | 'verified'
                                    > & { logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>> };
                                };
                        }
                    >;
                }
            >;
        };
};

export type CurrentPlanQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentPlanQuery = { __typename?: 'Query' } & {
    currentPlan?: Maybe<
        { __typename?: 'PurchasedPlan' } & Pick<PurchasedPlan, 'id' | 'active' | 'startsAt' | 'expiresAt'> & {
                plan: { __typename?: 'PricingPlan' } & Pick<
                    PricingPlan,
                    'id' | 'name' | 'order' | 'pricingType' | 'price' | 'discountPercentage'
                >;
                features: { __typename?: 'PurchasedPlanFeatureConnection' } & {
                    edges: Array<
                        { __typename?: 'PurchasedPlanFeatureEdge' } & {
                            node: { __typename?: 'PurchasedPlanFeature' } & Pick<
                                PurchasedPlanFeature,
                                'id' | 'enabled' | 'code' | 'limit'
                            >;
                        }
                    >;
                };
            }
    >;
};

export type DashboardsQueryVariables = Exact<{ [key: string]: never }>;

export type DashboardsQuery = { __typename?: 'Query' } & {
    dasboards: { __typename?: 'ReportConnection' } & Pick<ReportConnection, 'totalCount'> & {
            edges: Array<
                { __typename?: 'ReportEdge' } & {
                    node: { __typename?: 'Report' } & Pick<Report, 'id' | 'code' | 'embedUrl'>;
                }
            >;
        };
};

export type CandidateQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type CandidateQuery = { __typename?: 'Query' } & {
    candidate: { __typename?: 'Candidate' } & {
        profile: { __typename?: 'Jobseeker' } & Pick<
            Jobseeker,
            | 'id'
            | 'firstName'
            | 'lastName'
            | 'middleName'
            | 'gender'
            | 'dateOfBirth'
            | 'placeOfBirth'
            | 'regionId'
            | 'status'
            | 'userId'
            | 'email'
            | 'keySkills'
        > & {
                address?: Maybe<
                    { __typename?: 'Address' } & Pick<Address, 'city' | 'street' | 'building' | 'description'>
                >;
                contacts?: Maybe<
                    { __typename?: 'ContactInfo' } & Pick<
                        ContactInfo,
                        | 'name'
                        | 'emails'
                        | 'instagramUrl'
                        | 'linkedInUrl'
                        | 'skypeAddress'
                        | 'facebookUrl'
                        | 'twitterUrl'
                        | 'githubUrl'
                        | 'behanceUrl'
                    > & {
                            phones?: Maybe<
                                Array<{ __typename?: 'ContactPhone' } & Pick<ContactPhone, 'number' | 'comment'>>
                            >;
                        }
                >;
                languages: { __typename?: 'JobseekerLanguageConnection' } & {
                    edges: Array<
                        { __typename?: 'JobseekerLanguageEdge' } & {
                            node: { __typename?: 'JobseekerLanguage' } & Pick<
                                JobseekerLanguage,
                                'id' | 'languageId' | 'level'
                            >;
                        }
                    >;
                };
                educations: { __typename?: 'JobseekerEducationConnection' } & {
                    edges: Array<
                        { __typename?: 'JobseekerEducationEdge' } & {
                            node: { __typename?: 'JobseekerEducation' } & Pick<
                                JobseekerEducation,
                                | 'id'
                                | 'school'
                                | 'field'
                                | 'level'
                                | 'startDate'
                                | 'location'
                                | 'endDate'
                                | 'ongoing'
                                | 'description'
                                | 'verified'
                            >;
                        }
                    >;
                };
                experiences: { __typename?: 'JobseekerExperienceConnection' } & {
                    edges: Array<
                        { __typename?: 'JobseekerExperienceEdge' } & {
                            node: { __typename?: 'JobseekerExperience' } & Pick<
                                JobseekerExperience,
                                'id' | 'company' | 'position' | 'startDate' | 'endDate' | 'ongoing' | 'verified'
                            >;
                        }
                    >;
                };
                region?: Maybe<{ __typename?: 'Region' } & Pick<Region, 'id' | 'name'>>;
            };
        applications?: Maybe<
            { __typename?: 'JobApplicationConnection' } & {
                edges: Array<
                    { __typename?: 'JobApplicationEdge' } & {
                        node: { __typename?: 'JobApplication' } & Pick<JobApplication, 'id' | 'coverLetter'> & {
                                resume?: Maybe<
                                    { __typename?: 'Resume' } & Pick<Resume, 'id' | 'name' | 'localUri'> & {
                                            file: { __typename?: 'FileInfo' } & Pick<FileInfo, 'url' | 'name' | 'id'>;
                                        }
                                >;
                                scheduledEvents?: Maybe<
                                    { __typename?: 'ScheduledEventConnection' } & {
                                        edges: Array<
                                            { __typename?: 'ScheduledEventEdge' } & {
                                                node: { __typename?: 'ScheduledEvent' } & Pick<
                                                    ScheduledEvent,
                                                    | 'id'
                                                    | 'confirmed'
                                                    | 'type'
                                                    | 'location'
                                                    | 'name'
                                                    | 'notes'
                                                    | 'duration'
                                                    | 'expiresAt'
                                                > & {
                                                        confirmedSlot?: Maybe<
                                                            { __typename?: 'ConfirmedSlot' } & Pick<
                                                                ConfirmedSlot,
                                                                'date' | 'from'
                                                            >
                                                        >;
                                                    };
                                            }
                                        >;
                                    }
                                >;
                                stage?: Maybe<
                                    { __typename?: 'HiringStage' } & Pick<
                                        HiringStage,
                                        'id' | 'title' | 'color' | 'status'
                                    >
                                >;
                                statusHistories?: Maybe<
                                    { __typename?: 'JobApplicationStatusHistoryConnection' } & {
                                        edges: Array<
                                            { __typename?: 'JobApplicationStatusHistoryEdge' } & {
                                                node: { __typename?: 'JobApplicationStatusHistory' } & Pick<
                                                    JobApplicationStatusHistory,
                                                    'id' | 'createdAt' | 'reason'
                                                >;
                                            }
                                        >;
                                    }
                                >;
                                job?: Maybe<
                                    { __typename?: 'Job' } & Pick<Job, 'id' | 'title'> & {
                                            process?: Maybe<
                                                { __typename?: 'HiringProcess' } & Pick<HiringProcess, 'id'> & {
                                                        stages: { __typename?: 'HiringStageConnection' } & {
                                                            edges: Array<
                                                                { __typename?: 'HiringStageEdge' } & {
                                                                    node: { __typename?: 'HiringStage' } & Pick<
                                                                        HiringStage,
                                                                        'id' | 'title' | 'color' | 'order' | 'status'
                                                                    > & {
                                                                            triggers: {
                                                                                __typename?: 'StageActionConnection';
                                                                            } & {
                                                                                edges: Array<
                                                                                    {
                                                                                        __typename?: 'StageActionEdge';
                                                                                    } & {
                                                                                        node: {
                                                                                            __typename?: 'StageAction';
                                                                                        } & Pick<
                                                                                            StageAction,
                                                                                            'id' | 'type' | 'stageId'
                                                                                        > & {
                                                                                                attachments: {
                                                                                                    __typename?: 'FileInfoConnection';
                                                                                                } & {
                                                                                                    edges: Array<
                                                                                                        {
                                                                                                            __typename?: 'FileInfoEdge';
                                                                                                        } & {
                                                                                                            node: {
                                                                                                                __typename?: 'FileInfo';
                                                                                                            } & Pick<
                                                                                                                FileInfo,
                                                                                                                | 'id'
                                                                                                                | 'name'
                                                                                                                | 'url'
                                                                                                                | 'extension'
                                                                                                            >;
                                                                                                        }
                                                                                                    >;
                                                                                                };
                                                                                                payload: {
                                                                                                    __typename?: 'EmailActionPayload';
                                                                                                } & Pick<
                                                                                                    EmailActionPayload,
                                                                                                    | 'from'
                                                                                                    | 'to'
                                                                                                    | 'toEmails'
                                                                                                    | 'subject'
                                                                                                    | 'body'
                                                                                                >;
                                                                                            };
                                                                                    }
                                                                                >;
                                                                            };
                                                                        };
                                                                }
                                                            >;
                                                        };
                                                    }
                                            >;
                                        }
                                >;
                                comments?: Maybe<
                                    { __typename?: 'JobApplicationCommentConnection' } & {
                                        edges: Array<
                                            { __typename?: 'JobApplicationCommentEdge' } & {
                                                node: { __typename?: 'JobApplicationComment' } & Pick<
                                                    JobApplicationComment,
                                                    'id' | 'content'
                                                >;
                                            }
                                        >;
                                    }
                                >;
                            };
                    }
                >;
            }
        >;
    };
};

export type CandidatesQueryVariables = Exact<{
    where?: Maybe<CandidateWhereInput>;
    page?: Maybe<Scalars['Int']>;
    orderBy?: Maybe<CandidateOrderBy>;
}>;

export type CandidatesQuery = { __typename?: 'Query' } & {
    candidates: { __typename?: 'CandidateConnection' } & Pick<CandidateConnection, 'totalCount'> & {
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'hasNextPage' | 'hasPreviousPage' | 'page' | 'totalPages'
            >;
            edges: Array<
                { __typename?: 'CandidateEdge' } & {
                    node: { __typename?: 'Candidate' } & Pick<Candidate, 'id'> & {
                            profile: { __typename?: 'Jobseeker' } & Pick<
                                Jobseeker,
                                'id' | 'firstName' | 'lastName' | 'createdAt' | 'email'
                            > & {
                                    avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                                    contacts?: Maybe<
                                        { __typename?: 'ContactInfo' } & Pick<ContactInfo, 'emails'> & {
                                                phones?: Maybe<
                                                    Array<
                                                        { __typename?: 'ContactPhone' } & Pick<
                                                            ContactPhone,
                                                            'number' | 'comment'
                                                        >
                                                    >
                                                >;
                                            }
                                    >;
                                    region?: Maybe<{ __typename?: 'Region' } & Pick<Region, 'id' | 'name'>>;
                                };
                        };
                }
            >;
        };
};

export type JobApplicationsQueryVariables = Exact<{
    slug: Scalars['String'];
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<ApplicationWhereInput>;
    orderBy?: Maybe<ApplicationOrderBy>;
}>;

export type JobApplicationsQuery = { __typename?: 'Query' } & {
    job: { __typename?: 'Job' } & Pick<Job, 'id' | 'slug' | 'title'> & {
            applications?: Maybe<
                { __typename?: 'JobApplicationConnection' } & Pick<JobApplicationConnection, 'totalCount'> & {
                        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'totalPages'>;
                        edges: Array<
                            { __typename?: 'JobApplicationEdge' } & {
                                node: { __typename?: 'JobApplication' } & Pick<JobApplication, 'id'> & {
                                        applicant?: Maybe<
                                            { __typename?: 'Jobseeker' } & Pick<
                                                Jobseeker,
                                                'id' | 'firstName' | 'lastName' | 'createdAt' | 'email'
                                            > & {
                                                    avatar?: Maybe<
                                                        { __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>
                                                    >;
                                                    contacts?: Maybe<
                                                        { __typename?: 'ContactInfo' } & Pick<ContactInfo, 'emails'> & {
                                                                phones?: Maybe<
                                                                    Array<
                                                                        { __typename?: 'ContactPhone' } & Pick<
                                                                            ContactPhone,
                                                                            'number' | 'comment'
                                                                        >
                                                                    >
                                                                >;
                                                            }
                                                    >;
                                                }
                                        >;
                                        stage?: Maybe<
                                            { __typename?: 'HiringStage' } & Pick<HiringStage, 'status' | 'color'>
                                        >;
                                    };
                            }
                        >;
                    }
            >;
        };
};

export type DashboardQueryVariables = Exact<{ [key: string]: never }>;

export type DashboardQuery = { __typename?: 'Query' } & Pick<Query, 'totalJobViewCount'> & {
        jobPostings: { __typename?: 'JobConnection' } & Pick<JobConnection, 'totalCount'>;
        candidates: { __typename?: 'CandidateConnection' } & Pick<CandidateConnection, 'totalCount'>;
        applications: { __typename?: 'JobApplicationConnection' } & Pick<JobApplicationConnection, 'totalCount'>;
        expiringJobs: { __typename?: 'JobConnection' } & {
            edges: Array<
                { __typename?: 'JobEdge' } & {
                    node: { __typename?: 'Job' } & Pick<
                        Job,
                        'id' | 'slug' | 'title' | 'status' | 'expiresAt' | 'createdAt' | 'publishedAt'
                    > & {
                            applications?: Maybe<
                                { __typename?: 'JobApplicationConnection' } & Pick<
                                    JobApplicationConnection,
                                    'totalCount'
                                >
                            >;
                        };
                }
            >;
        };
        mostAppliedJobs: { __typename?: 'JobConnection' } & {
            edges: Array<
                { __typename?: 'JobEdge' } & {
                    node: { __typename?: 'Job' } & Pick<
                        Job,
                        'id' | 'slug' | 'title' | 'status' | 'expiresAt' | 'createdAt' | 'publishedAt'
                    > & {
                            applications?: Maybe<
                                { __typename?: 'JobApplicationConnection' } & Pick<
                                    JobApplicationConnection,
                                    'totalCount'
                                >
                            >;
                        };
                }
            >;
        };
    };

export type EmployerQueryVariables = Exact<{ [key: string]: never }>;

export type EmployerQuery = { __typename?: 'Query' } & {
    me?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id'> & {
                employer?: Maybe<
                    { __typename?: 'Employer' } & Pick<Employer, 'id' | 'firstName' | 'lastName' | 'phoneNumber'> & {
                            avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                            company: { __typename?: 'Company' } & Pick<
                                Company,
                                'id' | 'slug' | 'name' | 'type' | 'website' | 'description' | 'regionId' | 'industryId'
                            > & { logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>> };
                        }
                >;
            }
    >;
};

export type HiringProcessQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type HiringProcessQuery = { __typename?: 'Query' } & {
    hiringProcess: { __typename?: 'HiringProcess' } & Pick<HiringProcess, 'id' | 'title'> & {
            stages: { __typename?: 'HiringStageConnection' } & {
                edges: Array<
                    { __typename?: 'HiringStageEdge' } & {
                        node: { __typename?: 'HiringStage' } & Pick<
                            HiringStage,
                            'id' | 'status' | 'color' | 'title' | 'processId' | 'version'
                        > & {
                                triggers: { __typename?: 'StageActionConnection' } & Pick<
                                    StageActionConnection,
                                    'totalCount'
                                >;
                            };
                    }
                >;
            };
        };
};

export type HiringProcessListQueryVariables = Exact<{ [key: string]: never }>;

export type HiringProcessListQuery = { __typename?: 'Query' } & {
    hiringProcesses: { __typename?: 'HiringProcessConnection' } & {
        edges: Array<
            { __typename?: 'HiringProcessEdge' } & {
                node: { __typename?: 'HiringProcess' } & Pick<HiringProcess, 'id' | 'title'> & {
                        stages: { __typename?: 'HiringStageConnection' } & {
                            edges: Array<
                                { __typename?: 'HiringStageEdge' } & {
                                    node: { __typename?: 'HiringStage' } & Pick<HiringStage, 'id' | 'title' | 'color'>;
                                }
                            >;
                        };
                    };
            }
        >;
    };
};

export type HiringStageQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type HiringStageQuery = { __typename?: 'Query' } & {
    hiringStage: { __typename?: 'HiringStage' } & HiringStagePartsFragment;
};

export type IndustriesQueryVariables = Exact<{ [key: string]: never }>;

export type IndustriesQuery = { __typename?: 'Query' } & {
    industries: { __typename?: 'IndustryConnection' } & {
        edges: Array<
            { __typename?: 'IndustryEdge' } & {
                node: { __typename?: 'Industry' } & Pick<Industry, 'id' | 'name' | 'order'>;
            }
        >;
    };
};

export type JobPostingsQueryVariables = Exact<{
    where?: Maybe<JobPostingWhereInput>;
    page?: Maybe<Scalars['Int']>;
    orderBy?: Maybe<JobPostingOrderBy>;
}>;

export type JobPostingsQuery = { __typename?: 'Query' } & {
    jobPostings: { __typename?: 'JobConnection' } & Pick<JobConnection, 'totalCount'> & {
            pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'totalPages'>;
            edges: Array<
                { __typename?: 'JobEdge' } & {
                    node: { __typename?: 'Job' } & Pick<
                        Job,
                        | 'id'
                        | 'slug'
                        | 'title'
                        | 'status'
                        | 'viewCount'
                        | 'expiresAt'
                        | 'createdAt'
                        | 'publishedAt'
                        | 'applicationCount'
                    >;
                }
            >;
        };
};

export type JobQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type JobQuery = { __typename?: 'Query' } & {
    job: { __typename?: 'Job' } & Pick<
        Job,
        | 'id'
        | 'title'
        | 'employment'
        | 'applicationUrl'
        | 'descriptionHtml'
        | 'acceptHandicapped'
        | 'acceptKids'
        | 'acceptIncompleteResumes'
        | 'applicantProfileRequirements'
        | 'categoryId'
        | 'processId'
        | 'schedule'
        | 'gender'
        | 'experience'
        | 'expiresAt'
        | 'education'
        | 'code'
        | 'keySkills'
    > & {
            regions: { __typename?: 'RegionConnection' } & {
                edges: Array<
                    { __typename?: 'RegionEdge' } & { node: { __typename?: 'Region' } & Pick<Region, 'id' | 'name'> }
                >;
            };
            salary?: Maybe<{ __typename?: 'Salary' } & Pick<Salary, 'from' | 'to' | 'currency'>>;
            age?: Maybe<{ __typename?: 'Age' } & Pick<Age, 'from' | 'to'>>;
            address?: Maybe<{ __typename?: 'Address' } & Pick<Address, 'city' | 'street' | 'building' | 'description'>>;
            contacts?: Maybe<
                { __typename?: 'ContactInfo' } & Pick<ContactInfo, 'name' | 'emails'> & {
                        phones?: Maybe<
                            Array<{ __typename?: 'ContactPhone' } & Pick<ContactPhone, 'number' | 'comment'>>
                        >;
                    }
            >;
        };
};

export type ScheduledEventTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type ScheduledEventTemplatesQuery = { __typename?: 'Query' } & {
    scheduledEventTemplates: { __typename?: 'ScheduledEventTemplateConnection' } & Pick<
        ScheduledEventTemplateConnection,
        'totalCount'
    > & {
            edges: Array<
                { __typename?: 'ScheduledEventTemplateEdge' } & {
                    node: { __typename?: 'ScheduledEventTemplate' } & Pick<
                        ScheduledEventTemplate,
                        'id' | 'name' | 'type' | 'duration' | 'location' | 'expiresAt' | 'notes'
                    > & {
                            availabilities: Array<
                                { __typename?: 'Availability' } & Pick<Availability, 'date'> & {
                                        slots: Array<
                                            { __typename?: 'AvailabilitySlot' } & Pick<AvailabilitySlot, 'from'>
                                        >;
                                    }
                            >;
                        };
                }
            >;
        };
};

export type ScheduledSlotsQueryVariables = Exact<{
    where: ScheduledSlotWhereInput;
}>;

export type ScheduledSlotsQuery = { __typename?: 'Query' } & {
    scheduledSlots: { __typename?: 'ScheduledSlotConnection' } & {
        edges: Array<
            { __typename?: 'ScheduledSlotEdge' } & {
                node: { __typename?: 'ScheduledSlot' } & Pick<ScheduledSlot, 'id' | 'start' | 'end' | 'date'>;
            }
        >;
    };
};

export type HomeStatisticsQueryVariables = Exact<{ [key: string]: never }>;

export type HomeStatisticsQuery = { __typename?: 'Query' } & Pick<Query, 'jobCount' | 'companyCount'> & {
        jobseekers: { __typename?: 'JobseekerConnection' } & Pick<JobseekerConnection, 'totalCount'>;
    };

export type NewJobsQueryVariables = Exact<{ [key: string]: never }>;

export type NewJobsQuery = { __typename?: 'Query' } & {
    jobs: { __typename?: 'JobConnection' } & {
        edges: Array<
            { __typename?: 'JobEdge' } & {
                node: { __typename?: 'Job' } & Pick<
                    Job,
                    | 'id'
                    | 'slug'
                    | 'title'
                    | 'employment'
                    | 'createdAt'
                    | 'publishedAt'
                    | 'applied'
                    | 'bookmarked'
                    | 'easyApply'
                    | 'viewCount'
                > & {
                        salary?: Maybe<{ __typename?: 'Salary' } & Pick<Salary, 'from' | 'to'>>;
                        company: { __typename?: 'Company' } & Pick<Company, 'id' | 'name' | 'verified'> & {
                                logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                            };
                    };
            }
        >;
    };
};

export type SelectedJobsQueryVariables = Exact<{ [key: string]: never }>;

export type SelectedJobsQuery = { __typename?: 'Query' } & {
    selectedJobs: { __typename?: 'JobConnection' } & {
        edges: Array<
            { __typename?: 'JobEdge' } & {
                node: { __typename?: 'Job' } & Pick<
                    Job,
                    | 'id'
                    | 'slug'
                    | 'title'
                    | 'employment'
                    | 'createdAt'
                    | 'publishedAt'
                    | 'applied'
                    | 'bookmarked'
                    | 'easyApply'
                    | 'status'
                    | 'viewCount'
                > & {
                        salary?: Maybe<{ __typename?: 'Salary' } & Pick<Salary, 'from' | 'to'>>;
                        company: { __typename?: 'Company' } & Pick<Company, 'id' | 'name' | 'verified'> & {
                                logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                            };
                    };
            }
        >;
    };
};

export type TopCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type TopCategoriesQuery = { __typename?: 'Query' } & {
    topCategories: { __typename?: 'CategoryConnection' } & {
        edges: Array<
            { __typename?: 'CategoryEdge' } & {
                node: { __typename?: 'Category' } & Pick<Category, 'id' | 'name' | 'order' | 'jobCount'>;
            }
        >;
    };
};

export type JobDetailQueryVariables = Exact<{
    slug: Scalars['String'];
}>;

export type JobDetailQuery = { __typename?: 'Query' } & {
    job: { __typename?: 'Job' } & Pick<
        Job,
        | 'id'
        | 'slug'
        | 'title'
        | 'easyApply'
        | 'status'
        | 'employment'
        | 'createdAt'
        | 'expiresAt'
        | 'publishedAt'
        | 'experience'
        | 'education'
        | 'bookmarked'
        | 'applied'
        | 'descriptionHtml'
        | 'descriptionText'
        | 'viewCount'
        | 'schedule'
        | 'keySkills'
        | 'driverLicenseTypes'
        | 'gender'
        | 'applicationUrl'
        | 'acceptHandicapped'
        | 'acceptIncompleteResumes'
        | 'applicantProfileRequirements'
    > & {
            invitation?: Maybe<{ __typename?: 'ApplicationInvite' } & Pick<ApplicationInvite, 'id' | 'status'>>;
            regions: { __typename?: 'RegionConnection' } & {
                edges: Array<
                    { __typename?: 'RegionEdge' } & { node: { __typename?: 'Region' } & Pick<Region, 'id' | 'name'> }
                >;
            };
            age?: Maybe<{ __typename?: 'Age' } & Pick<Age, 'from' | 'to'>>;
            address?: Maybe<
                { __typename?: 'Address' } & Pick<Address, 'city'> & {
                        location?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'latitude' | 'longitude'>>;
                    }
            >;
            company: { __typename?: 'Company' } & Pick<Company, 'id' | 'slug' | 'name' | 'verified'> & {
                    logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                };
            salary?: Maybe<{ __typename?: 'Salary' } & Pick<Salary, 'gross' | 'currency' | 'from' | 'to'>>;
            contacts?: Maybe<
                { __typename?: 'ContactInfo' } & Pick<
                    ContactInfo,
                    'emails' | 'name' | 'linkedInUrl' | 'skypeAddress' | 'facebookUrl' | 'twitterUrl' | 'githubUrl'
                > & { phones?: Maybe<Array<{ __typename?: 'ContactPhone' } & Pick<ContactPhone, 'number'>>> }
            >;
            relatedJobs: { __typename?: 'JobConnection' } & {
                edges: Array<
                    { __typename?: 'JobEdge' } & {
                        node: { __typename?: 'Job' } & Pick<
                            Job,
                            | 'id'
                            | 'title'
                            | 'slug'
                            | 'employment'
                            | 'applied'
                            | 'status'
                            | 'bookmarked'
                            | 'createdAt'
                            | 'publishedAt'
                        > & {
                                salary?: Maybe<
                                    { __typename?: 'Salary' } & Pick<Salary, 'gross' | 'currency' | 'from' | 'to'>
                                >;
                                company: { __typename?: 'Company' } & Pick<Company, 'id' | 'name' | 'verified'> & {
                                        logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                                    };
                            };
                    }
                >;
            };
        };
};

export type JobListQueryVariables = Exact<{
    where?: Maybe<JobWhereInput>;
    page?: Maybe<Scalars['Int']>;
    orderBy?: Maybe<JobOrderBy>;
}>;

export type JobListQuery = { __typename?: 'Query' } & {
    jobs: { __typename?: 'JobConnection' } & Pick<JobConnection, 'totalCount'> & {
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'hasNextPage' | 'hasPreviousPage' | 'page' | 'totalPages'
            >;
            edges: Array<
                { __typename?: 'JobEdge' } & {
                    node: { __typename?: 'Job' } & Pick<
                        Job,
                        | 'id'
                        | 'slug'
                        | 'title'
                        | 'employment'
                        | 'createdAt'
                        | 'publishedAt'
                        | 'applied'
                        | 'bookmarked'
                        | 'easyApply'
                        | 'status'
                        | 'expiresAt'
                        | 'viewCount'
                    > & {
                            applications?: Maybe<
                                { __typename?: 'JobApplicationConnection' } & Pick<
                                    JobApplicationConnection,
                                    'totalCount'
                                >
                            >;
                            salary?: Maybe<{ __typename?: 'Salary' } & Pick<Salary, 'from' | 'to'>>;
                            company: { __typename?: 'Company' } & Pick<Company, 'id' | 'slug' | 'name' | 'verified'> & {
                                    logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                                };
                        };
                }
            >;
        };
};

export type JobseekerListQueryVariables = Exact<{
    where?: Maybe<JobseekerWhereInput>;
    page?: Maybe<Scalars['Int']>;
    orderBy?: Maybe<JobseekerOrderBy>;
}>;

export type JobseekerListQuery = { __typename?: 'Query' } & {
    jobseekers: { __typename?: 'JobseekerConnection' } & Pick<JobseekerConnection, 'totalCount'> & {
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'hasNextPage' | 'hasPreviousPage' | 'page' | 'totalPages'
            >;
            edges: Array<
                { __typename?: 'JobseekerEdge' } & {
                    node: { __typename?: 'Jobseeker' } & Pick<
                        Jobseeker,
                        'id' | 'firstName' | 'lastName' | 'gender' | 'keySkills' | 'salaryExpectation' | 'status'
                    > & {
                            region?: Maybe<{ __typename?: 'Region' } & Pick<Region, 'id' | 'name'>>;
                            avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                            category?: Maybe<{ __typename?: 'Category' } & Pick<Category, 'id' | 'name' | 'order'>>;
                            contacts?: Maybe<
                                { __typename?: 'ContactInfo' } & Pick<ContactInfo, 'emails' | 'twitterUrl'>
                            >;
                        };
                }
            >;
        };
};

export type ApplicationInvitesQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    where?: Maybe<ApplicationInviteWhereInput>;
    orderBy?: Maybe<ApplicationInviteOrderBy>;
}>;

export type ApplicationInvitesQuery = { __typename?: 'Query' } & {
    applicationInvites: { __typename?: 'ApplicationInviteConnection' } & Pick<
        ApplicationInviteConnection,
        'totalCount'
    > & {
            edges: Array<
                { __typename?: 'ApplicationInviteEdge' } & {
                    node: { __typename?: 'ApplicationInvite' } & Pick<
                        ApplicationInvite,
                        'id' | 'jobId' | 'createdAt' | 'status'
                    > & {
                            job: { __typename?: 'Job' } & Pick<
                                Job,
                                | 'slug'
                                | 'title'
                                | 'applied'
                                | 'createdAt'
                                | 'publishedAt'
                                | 'employment'
                                | 'easyApply'
                                | 'status'
                                | 'applicantProfileRequirements'
                                | 'acceptIncompleteResumes'
                            > & {
                                    salary?: Maybe<{ __typename?: 'Salary' } & Pick<Salary, 'from' | 'to'>>;
                                    company: { __typename?: 'Company' } & Pick<Company, 'name' | 'id'> & {
                                            logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                                        };
                                };
                            jobseeker: { __typename?: 'Jobseeker' } & Pick<Jobseeker, 'id' | 'firstName' | 'lastName'>;
                        };
                }
            >;
        };
};

export type JobApplicationQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type JobApplicationQuery = { __typename?: 'Query' } & {
    jobApplication: { __typename?: 'JobApplication' } & Pick<JobApplication, 'id' | 'coverLetter' | 'createdAt'> & {
            stage?: Maybe<{ __typename?: 'HiringStage' } & Pick<HiringStage, 'id' | 'status' | 'color'>>;
            statusHistories?: Maybe<
                { __typename?: 'JobApplicationStatusHistoryConnection' } & {
                    edges: Array<
                        { __typename?: 'JobApplicationStatusHistoryEdge' } & {
                            node: { __typename?: 'JobApplicationStatusHistory' } & Pick<
                                JobApplicationStatusHistory,
                                'id' | 'createdAt' | 'reason'
                            > & {
                                    newStage?: Maybe<
                                        { __typename?: 'HiringStage' } & Pick<
                                            HiringStage,
                                            'id' | 'title' | 'color' | 'status'
                                        >
                                    >;
                                    oldStage?: Maybe<
                                        { __typename?: 'HiringStage' } & Pick<
                                            HiringStage,
                                            'id' | 'title' | 'color' | 'status'
                                        >
                                    >;
                                };
                        }
                    >;
                }
            >;
            job?: Maybe<
                { __typename?: 'Job' } & Pick<Job, 'id' | 'slug' | 'title'> & {
                        company: { __typename?: 'Company' } & Pick<Company, 'id' | 'slug' | 'name'> & {
                                logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                            };
                    }
            >;
            scheduledEvents?: Maybe<
                { __typename?: 'ScheduledEventConnection' } & {
                    edges: Array<
                        { __typename?: 'ScheduledEventEdge' } & {
                            node: { __typename?: 'ScheduledEvent' } & Pick<
                                ScheduledEvent,
                                'id' | 'confirmed' | 'type' | 'location' | 'name' | 'notes' | 'duration' | 'expiresAt'
                            > & {
                                    confirmedSlot?: Maybe<
                                        { __typename?: 'ConfirmedSlot' } & Pick<ConfirmedSlot, 'date' | 'from'>
                                    >;
                                };
                        }
                    >;
                }
            >;
        };
};

export type BookmarkedJobsQueryVariables = Exact<{
    where?: Maybe<JobWhereInput>;
    orderBy?: Maybe<JobOrderBy>;
    page?: Maybe<Scalars['Int']>;
}>;

export type BookmarkedJobsQuery = { __typename?: 'Query' } & {
    bookmarkedJobs: { __typename?: 'BookmarkedJobConnection' } & Pick<BookmarkedJobConnection, 'totalCount'> & {
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'hasNextPage' | 'hasPreviousPage' | 'page' | 'totalPages'
            >;
            edges: Array<
                { __typename?: 'BookmarkedJobEdge' } & {
                    node: { __typename?: 'BookmarkedJob' } & Pick<BookmarkedJob, 'id'> & {
                            job: { __typename?: 'Job' } & Pick<
                                Job,
                                | 'id'
                                | 'slug'
                                | 'title'
                                | 'applied'
                                | 'employment'
                                | 'easyApply'
                                | 'createdAt'
                                | 'publishedAt'
                                | 'bookmarked'
                            > & {
                                    salary?: Maybe<{ __typename?: 'Salary' } & Pick<Salary, 'from' | 'to'>>;
                                    company: { __typename?: 'Company' } & Pick<
                                        Company,
                                        'id' | 'slug' | 'name' | 'verified'
                                    > & { logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>> };
                                };
                        };
                }
            >;
        };
};

export type HtmlResumeQueryVariables = Exact<{ [key: string]: never }>;

export type HtmlResumeQuery = { __typename?: 'Query' } & Pick<Query, 'htmlResume'>;

export type JobseekerApplicationsQueryVariables = Exact<{
    where?: Maybe<JobApplicationWhereInput>;
    orderBy?: Maybe<JobOrderBy>;
}>;

export type JobseekerApplicationsQuery = { __typename?: 'Query' } & {
    jobseekerApplications: { __typename?: 'JobApplicationConnection' } & {
        edges: Array<
            { __typename?: 'JobApplicationEdge' } & {
                node: { __typename?: 'JobApplication' } & Pick<JobApplication, 'id' | 'createdAt'> & {
                        job?: Maybe<
                            { __typename?: 'Job' } & Pick<Job, 'id' | 'title'> & {
                                    company: { __typename?: 'Company' } & Pick<
                                        Company,
                                        'slug' | 'id' | 'name' | 'verified'
                                    > & { logo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>> };
                                }
                        >;
                        stage?: Maybe<
                            { __typename?: 'HiringStage' } & Pick<HiringStage, 'id' | 'title' | 'color' | 'status'>
                        >;
                    };
            }
        >;
    };
};

export type JobseekerProfileQueryVariables = Exact<{ [key: string]: never }>;

export type JobseekerProfileQuery = { __typename?: 'Query' } & {
    me?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id' | 'email'> & {
                jobseeker?: Maybe<
                    { __typename?: 'Jobseeker' } & Pick<
                        Jobseeker,
                        | 'id'
                        | 'firstName'
                        | 'middleName'
                        | 'lastName'
                        | 'email'
                        | 'status'
                        | 'gender'
                        | 'placeOfBirth'
                        | 'dateOfBirth'
                        | 'summary'
                        | 'salaryExpectation'
                        | 'categoryId'
                        | 'regionId'
                        | 'keySkills'
                    > & {
                            category?: Maybe<{ __typename?: 'Category' } & Pick<Category, 'id' | 'name'>>;
                            avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
                            address?: Maybe<{ __typename?: 'Address' } & Pick<Address, 'city' | 'street'>>;
                            contacts?: Maybe<
                                { __typename?: 'ContactInfo' } & Pick<
                                    ContactInfo,
                                    | 'emails'
                                    | 'linkedInUrl'
                                    | 'skypeAddress'
                                    | 'facebookUrl'
                                    | 'twitterUrl'
                                    | 'githubUrl'
                                    | 'instagramUrl'
                                    | 'behanceUrl'
                                > & {
                                        phones?: Maybe<
                                            Array<
                                                { __typename?: 'ContactPhone' } & Pick<
                                                    ContactPhone,
                                                    'number' | 'comment'
                                                >
                                            >
                                        >;
                                    }
                            >;
                            region?: Maybe<{ __typename?: 'Region' } & Pick<Region, 'id' | 'name'>>;
                            experiences: { __typename?: 'JobseekerExperienceConnection' } & {
                                edges: Array<
                                    { __typename?: 'JobseekerExperienceEdge' } & {
                                        node: { __typename?: 'JobseekerExperience' } & Pick<
                                            JobseekerExperience,
                                            | 'id'
                                            | 'company'
                                            | 'position'
                                            | 'startDate'
                                            | 'endDate'
                                            | 'ongoing'
                                            | 'description'
                                        >;
                                    }
                                >;
                            };
                            educations: { __typename?: 'JobseekerEducationConnection' } & {
                                edges: Array<
                                    { __typename?: 'JobseekerEducationEdge' } & {
                                        node: { __typename?: 'JobseekerEducation' } & Pick<
                                            JobseekerEducation,
                                            | 'id'
                                            | 'school'
                                            | 'location'
                                            | 'level'
                                            | 'field'
                                            | 'startDate'
                                            | 'endDate'
                                            | 'ongoing'
                                            | 'description'
                                        >;
                                    }
                                >;
                            };
                            languages: { __typename?: 'JobseekerLanguageConnection' } & {
                                edges: Array<
                                    { __typename?: 'JobseekerLanguageEdge' } & {
                                        node: { __typename?: 'JobseekerLanguage' } & Pick<
                                            JobseekerLanguage,
                                            'id' | 'languageId' | 'level'
                                        >;
                                    }
                                >;
                            };
                            resumes: { __typename?: 'ResumeConnection' } & {
                                edges: Array<
                                    { __typename?: 'ResumeEdge' } & {
                                        node: { __typename?: 'Resume' } & Pick<Resume, 'id' | 'name' | 'createdAt'> & {
                                                file: { __typename?: 'FileInfo' } & Pick<
                                                    FileInfo,
                                                    'extension' | 'size' | 'url'
                                                >;
                                            };
                                    }
                                >;
                            };
                        }
                >;
            }
    >;
};

export type JobseekerQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type JobseekerQuery = { __typename?: 'Query' } & {
    jobSeeker: { __typename?: 'Jobseeker' } & Pick<
        Jobseeker,
        | 'id'
        | 'firstName'
        | 'middleName'
        | 'lastName'
        | 'status'
        | 'gender'
        | 'placeOfBirth'
        | 'dateOfBirth'
        | 'summary'
        | 'salaryExpectation'
        | 'regionId'
        | 'keySkills'
        | 'userId'
    > & {
            avatar?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'url'>>;
            address?: Maybe<{ __typename?: 'Address' } & Pick<Address, 'city' | 'street'>>;
            contacts?: Maybe<
                { __typename?: 'ContactInfo' } & Pick<
                    ContactInfo,
                    | 'emails'
                    | 'linkedInUrl'
                    | 'skypeAddress'
                    | 'facebookUrl'
                    | 'twitterUrl'
                    | 'githubUrl'
                    | 'instagramUrl'
                > & {
                        phones?: Maybe<
                            Array<{ __typename?: 'ContactPhone' } & Pick<ContactPhone, 'number' | 'comment'>>
                        >;
                    }
            >;
            region?: Maybe<{ __typename?: 'Region' } & Pick<Region, 'id' | 'name'>>;
            experiences: { __typename?: 'JobseekerExperienceConnection' } & {
                edges: Array<
                    { __typename?: 'JobseekerExperienceEdge' } & {
                        node: { __typename?: 'JobseekerExperience' } & Pick<
                            JobseekerExperience,
                            'id' | 'company' | 'position' | 'startDate' | 'endDate' | 'ongoing' | 'description'
                        >;
                    }
                >;
            };
            educations: { __typename?: 'JobseekerEducationConnection' } & {
                edges: Array<
                    { __typename?: 'JobseekerEducationEdge' } & {
                        node: { __typename?: 'JobseekerEducation' } & Pick<
                            JobseekerEducation,
                            'id' | 'school' | 'level' | 'field' | 'startDate' | 'endDate' | 'ongoing' | 'description'
                        >;
                    }
                >;
            };
            languages: { __typename?: 'JobseekerLanguageConnection' } & {
                edges: Array<
                    { __typename?: 'JobseekerLanguageEdge' } & {
                        node: { __typename?: 'JobseekerLanguage' } & Pick<
                            JobseekerLanguage,
                            'id' | 'languageId' | 'level'
                        >;
                    }
                >;
            };
            resumes: { __typename?: 'ResumeConnection' } & {
                edges: Array<
                    { __typename?: 'ResumeEdge' } & {
                        node: { __typename?: 'Resume' } & Pick<Resume, 'id' | 'name' | 'createdAt'> & {
                                file: { __typename?: 'FileInfo' } & Pick<FileInfo, 'id' | 'extension' | 'size' | 'url'>;
                            };
                    }
                >;
            };
        };
};

export type PdfResumeQueryVariables = Exact<{ [key: string]: never }>;

export type PdfResumeQuery = { __typename?: 'Query' } & Pick<Query, 'pdfResume'>;

export type SavedSearchesQueryVariables = Exact<{ [key: string]: never }>;

export type SavedSearchesQuery = { __typename?: 'Query' } & {
    savedSearches: { __typename?: 'SavedSearchConnection' } & {
        edges: Array<
            { __typename?: 'SavedSearchEdge' } & {
                node: { __typename?: 'SavedSearch' } & Pick<
                    SavedSearch,
                    'id' | 'name' | 'alertPeriod' | 'lastExecutedAt'
                >;
            }
        >;
    };
};

export type LanguagesQueryVariables = Exact<{ [key: string]: never }>;

export type LanguagesQuery = { __typename?: 'Query' } & {
    languages: { __typename?: 'LanguageConnection' } & {
        edges: Array<
            { __typename?: 'LanguageEdge' } & {
                node: { __typename?: 'Language' } & Pick<Language, 'id' | 'isoCode' | 'name'>;
            }
        >;
    };
};

export type PricingPlansQueryVariables = Exact<{ [key: string]: never }>;

export type PricingPlansQuery = { __typename?: 'Query' } & {
    pricingPlans: { __typename?: 'PricingPlanConnection' } & {
        edges: Array<
            { __typename?: 'PricingPlanEdge' } & {
                node: { __typename?: 'PricingPlan' } & Pick<
                    PricingPlan,
                    'id' | 'name' | 'pricingType' | 'price' | 'order'
                > & {
                        features: { __typename?: 'PricingPlanFeatureConnection' } & {
                            edges: Array<
                                { __typename?: 'PricingPlanFeatureEdge' } & {
                                    node: { __typename?: 'PricingPlanFeature' } & Pick<
                                        PricingPlanFeature,
                                        'id' | 'code' | 'enabled' | 'limit'
                                    >;
                                }
                            >;
                        };
                    };
            }
        >;
    };
};

export type RegionListQueryVariables = Exact<{ [key: string]: never }>;

export type RegionListQuery = { __typename?: 'Query' } & {
    regions: { __typename?: 'RegionConnection' } & {
        edges: Array<
            { __typename?: 'RegionEdge' } & {
                node: { __typename?: 'Region' } & Pick<Region, 'id' | 'name'> & {
                        children: { __typename?: 'RegionConnection' } & {
                            edges: Array<
                                { __typename?: 'RegionEdge' } & {
                                    node: { __typename?: 'Region' } & Pick<Region, 'name' | 'id'>;
                                }
                            >;
                        };
                    };
            }
        >;
    };
};

export type RegionQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type RegionQuery = { __typename?: 'Query' } & {
    region: { __typename?: 'Region' } & Pick<Region, 'id' | 'name'>;
};

export type ReportsQueryVariables = Exact<{ [key: string]: never }>;

export type ReportsQuery = { __typename?: 'Query' } & {
    reports: { __typename?: 'ReportConnection' } & Pick<ReportConnection, 'totalCount'> & {
            edges: Array<
                { __typename?: 'ReportEdge' } & {
                    node: { __typename?: 'Report' } & Pick<Report, 'id' | 'code' | 'embedUrl'>;
                }
            >;
        };
};

export type ScheduledEventTemplateQueryVariables = Exact<{
    templateId: Scalars['String'];
}>;

export type ScheduledEventTemplateQuery = { __typename?: 'Query' } & {
    scheduledEventTemplate: { __typename?: 'ScheduledEventTemplate' } & Pick<
        ScheduledEventTemplate,
        'id' | 'name' | 'expiresAt' | 'type' | 'duration' | 'location' | 'notes' | 'creatorId'
    > & {
            availabilities: Array<
                { __typename?: 'Availability' } & Pick<Availability, 'date'> & {
                        slots: Array<{ __typename?: 'AvailabilitySlot' } & Pick<AvailabilitySlot, 'from'>>;
                    }
            >;
        };
};

export type ScheduledEventQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type ScheduledEventQuery = { __typename?: 'Query' } & {
    scheduledEvent: { __typename?: 'ScheduledEvent' } & Pick<
        ScheduledEvent,
        | 'id'
        | 'name'
        | 'expiresAt'
        | 'type'
        | 'duration'
        | 'applicationId'
        | 'timezone'
        | 'location'
        | 'notes'
        | 'confirmed'
        | 'creatorId'
        | 'confirmerId'
        | 'createdAt'
        | 'updatedAt'
    > & {
            availabilities: Array<
                { __typename?: 'Availability' } & Pick<Availability, 'date'> & {
                        slots: Array<{ __typename?: 'AvailabilitySlot' } & Pick<AvailabilitySlot, 'from'>>;
                    }
            >;
            confirmedSlot?: Maybe<{ __typename?: 'ConfirmedSlot' } & Pick<ConfirmedSlot, 'date' | 'from'>>;
        };
};

export type SelectedSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type SelectedSettingsQuery = { __typename?: 'Query' } & {
    selectedSettings: { __typename?: 'UserSettingConnection' } & {
        edges: Array<
            { __typename?: 'UserSettingEdge' } & {
                node: { __typename?: 'UserSetting' } & Pick<UserSetting, 'id' | 'settingId' | 'value' | 'type'>;
            }
        >;
    };
};

export type SettingGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type SettingGroupsQuery = { __typename?: 'Query' } & {
    settingGroups: { __typename?: 'SettingGroupConnection' } & {
        edges: Array<
            { __typename?: 'SettingGroupEdge' } & {
                node: { __typename?: 'SettingGroup' } & Pick<SettingGroup, 'id' | 'name'> & {
                        settings: { __typename?: 'SettingConnection' } & {
                            edges: Array<
                                { __typename?: 'SettingEdge' } & {
                                    node: { __typename?: 'Setting' } & Pick<
                                        Setting,
                                        'id' | 'name' | 'type' | 'scopes' | 'required' | 'dataType' | 'groupId'
                                    > & {
                                            allowedValues?: Maybe<
                                                Array<
                                                    { __typename?: 'AllowedSettingValue' } & Pick<
                                                        AllowedSettingValue,
                                                        'label' | 'value'
                                                    >
                                                >
                                            >;
                                        };
                                }
                            >;
                        };
                    };
            }
        >;
    };
};

export const BlogPostPartsFragmentDoc = gql`
    fragment BlogPostParts on BlogPost {
        id
        title
        slug
        body
        viewCount
        tags
        createdAt
        updatedAt
        coverImage {
            id
            url
        }
    }
`;
export const HiringStagePartsFragmentDoc = gql`
    fragment HiringStageParts on HiringStage {
        title
        id
        status
        color
        title
        processId
        version
        triggers {
            edges {
                node {
                    id
                    type
                    stageId
                    attachments {
                        edges {
                            node {
                                id
                                name
                                url
                                extension
                            }
                        }
                    }
                    payload {
                        ... on EmailActionPayload {
                            from
                            to
                            toEmails
                            subject
                            body
                        }
                    }
                }
            }
        }
    }
`;
export const ApproveJobDocument = gql`
    mutation ApproveJob($job: ApproveJobInput!) {
        approveJob(job: $job) {
            job {
                id
                status
            }
        }
    }
`;
export type ApproveJobMutationFn = Apollo.MutationFunction<ApproveJobMutation, ApproveJobMutationVariables>;

/**
 * __useApproveJobMutation__
 *
 * To run a mutation, you first call `useApproveJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveJobMutation, { data, loading, error }] = useApproveJobMutation({
 *   variables: {
 *      job: // value for 'job'
 *   },
 * });
 */
export function useApproveJobMutation(
    baseOptions?: Apollo.MutationHookOptions<ApproveJobMutation, ApproveJobMutationVariables>,
) {
    return Apollo.useMutation<ApproveJobMutation, ApproveJobMutationVariables>(ApproveJobDocument, baseOptions);
}
export type ApproveJobMutationHookResult = ReturnType<typeof useApproveJobMutation>;
export type ApproveJobMutationResult = Apollo.MutationResult<ApproveJobMutation>;
export type ApproveJobMutationOptions = Apollo.BaseMutationOptions<ApproveJobMutation, ApproveJobMutationVariables>;
export const CreatePostDocument = gql`
    mutation CreatePost($values: CreateBlogPostInput!) {
        createPost(blogPost: $values) {
            post {
                id
                slug
                title
            }
        }
    }
`;
export type CreatePostMutationFn = Apollo.MutationFunction<CreatePostMutation, CreatePostMutationVariables>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      values: // value for 'values'
 *   },
 * });
 */
export function useCreatePostMutation(
    baseOptions?: Apollo.MutationHookOptions<CreatePostMutation, CreatePostMutationVariables>,
) {
    return Apollo.useMutation<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument, baseOptions);
}
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = Apollo.MutationResult<CreatePostMutation>;
export type CreatePostMutationOptions = Apollo.BaseMutationOptions<CreatePostMutation, CreatePostMutationVariables>;
export const DeleteBlogPostDocument = gql`
    mutation DeleteBlogPost($id: String!) {
        deleteBlogPost(id: $id) {
            id
        }
    }
`;
export type DeleteBlogPostMutationFn = Apollo.MutationFunction<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>;

/**
 * __useDeleteBlogPostMutation__
 *
 * To run a mutation, you first call `useDeleteBlogPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlogPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlogPostMutation, { data, loading, error }] = useDeleteBlogPostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBlogPostMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>,
) {
    return Apollo.useMutation<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>(
        DeleteBlogPostDocument,
        baseOptions,
    );
}
export type DeleteBlogPostMutationHookResult = ReturnType<typeof useDeleteBlogPostMutation>;
export type DeleteBlogPostMutationResult = Apollo.MutationResult<DeleteBlogPostMutation>;
export type DeleteBlogPostMutationOptions = Apollo.BaseMutationOptions<
    DeleteBlogPostMutation,
    DeleteBlogPostMutationVariables
>;
export const DeleteCompanyDocument = gql`
    mutation DeleteCompany($companyId: String!) {
        deleteCompany(companyId: $companyId) {
            company {
                id
            }
        }
    }
`;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDeleteCompanyMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>,
) {
    return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(
        DeleteCompanyDocument,
        baseOptions,
    );
}
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<
    DeleteCompanyMutation,
    DeleteCompanyMutationVariables
>;
export const RejectJobDocument = gql`
    mutation RejectJob($job: RejectJobInput!) {
        rejectJob(job: $job) {
            job {
                id
                status
            }
        }
    }
`;
export type RejectJobMutationFn = Apollo.MutationFunction<RejectJobMutation, RejectJobMutationVariables>;

/**
 * __useRejectJobMutation__
 *
 * To run a mutation, you first call `useRejectJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectJobMutation, { data, loading, error }] = useRejectJobMutation({
 *   variables: {
 *      job: // value for 'job'
 *   },
 * });
 */
export function useRejectJobMutation(
    baseOptions?: Apollo.MutationHookOptions<RejectJobMutation, RejectJobMutationVariables>,
) {
    return Apollo.useMutation<RejectJobMutation, RejectJobMutationVariables>(RejectJobDocument, baseOptions);
}
export type RejectJobMutationHookResult = ReturnType<typeof useRejectJobMutation>;
export type RejectJobMutationResult = Apollo.MutationResult<RejectJobMutation>;
export type RejectJobMutationOptions = Apollo.BaseMutationOptions<RejectJobMutation, RejectJobMutationVariables>;
export const UploadBlogPostCoverImageDocument = gql`
    mutation uploadBlogPostCoverImage($blogPost: UploadBlogPostCoverImageInput!) {
        uploadBlogPostCoverImage(blogPost: $blogPost) {
            blogPost {
                id
                coverImage {
                    id
                    url
                }
            }
        }
    }
`;
export type UploadBlogPostCoverImageMutationFn = Apollo.MutationFunction<
    UploadBlogPostCoverImageMutation,
    UploadBlogPostCoverImageMutationVariables
>;

/**
 * __useUploadBlogPostCoverImageMutation__
 *
 * To run a mutation, you first call `useUploadBlogPostCoverImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBlogPostCoverImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBlogPostCoverImageMutation, { data, loading, error }] = useUploadBlogPostCoverImageMutation({
 *   variables: {
 *      blogPost: // value for 'blogPost'
 *   },
 * });
 */
export function useUploadBlogPostCoverImageMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UploadBlogPostCoverImageMutation,
        UploadBlogPostCoverImageMutationVariables
    >,
) {
    return Apollo.useMutation<UploadBlogPostCoverImageMutation, UploadBlogPostCoverImageMutationVariables>(
        UploadBlogPostCoverImageDocument,
        baseOptions,
    );
}
export type UploadBlogPostCoverImageMutationHookResult = ReturnType<typeof useUploadBlogPostCoverImageMutation>;
export type UploadBlogPostCoverImageMutationResult = Apollo.MutationResult<UploadBlogPostCoverImageMutation>;
export type UploadBlogPostCoverImageMutationOptions = Apollo.BaseMutationOptions<
    UploadBlogPostCoverImageMutation,
    UploadBlogPostCoverImageMutationVariables
>;
export const UpdateBlogPostDocument = gql`
    mutation UpdateBlogPost($blog: UpdateBlogPostInput!) {
        updateBlogPost(blogPost: $blog) {
            blogPost {
                id
                title
                body
                tags
            }
        }
    }
`;
export type UpdateBlogPostMutationFn = Apollo.MutationFunction<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>;

/**
 * __useUpdateBlogPostMutation__
 *
 * To run a mutation, you first call `useUpdateBlogPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlogPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlogPostMutation, { data, loading, error }] = useUpdateBlogPostMutation({
 *   variables: {
 *      blog: // value for 'blog'
 *   },
 * });
 */
export function useUpdateBlogPostMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>,
) {
    return Apollo.useMutation<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>(
        UpdateBlogPostDocument,
        baseOptions,
    );
}
export type UpdateBlogPostMutationHookResult = ReturnType<typeof useUpdateBlogPostMutation>;
export type UpdateBlogPostMutationResult = Apollo.MutationResult<UpdateBlogPostMutation>;
export type UpdateBlogPostMutationOptions = Apollo.BaseMutationOptions<
    UpdateBlogPostMutation,
    UpdateBlogPostMutationVariables
>;
export const UpdateCompanySlugDocument = gql`
    mutation UpdateCompanySlug($company: UpdateCompanySlugInput!) {
        updateCompanySlug(company: $company) {
            company {
                id
                slug
            }
        }
    }
`;
export type UpdateCompanySlugMutationFn = Apollo.MutationFunction<
    UpdateCompanySlugMutation,
    UpdateCompanySlugMutationVariables
>;

/**
 * __useUpdateCompanySlugMutation__
 *
 * To run a mutation, you first call `useUpdateCompanySlugMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanySlugMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanySlugMutation, { data, loading, error }] = useUpdateCompanySlugMutation({
 *   variables: {
 *      company: // value for 'company'
 *   },
 * });
 */
export function useUpdateCompanySlugMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateCompanySlugMutation, UpdateCompanySlugMutationVariables>,
) {
    return Apollo.useMutation<UpdateCompanySlugMutation, UpdateCompanySlugMutationVariables>(
        UpdateCompanySlugDocument,
        baseOptions,
    );
}
export type UpdateCompanySlugMutationHookResult = ReturnType<typeof useUpdateCompanySlugMutation>;
export type UpdateCompanySlugMutationResult = Apollo.MutationResult<UpdateCompanySlugMutation>;
export type UpdateCompanySlugMutationOptions = Apollo.BaseMutationOptions<
    UpdateCompanySlugMutation,
    UpdateCompanySlugMutationVariables
>;
export const VerifyCompanyDocument = gql`
    mutation VerifyCompany($company: VerifyCompanyInput!) {
        verifyCompany(company: $company) {
            company {
                id
                verified
            }
        }
    }
`;
export type VerifyCompanyMutationFn = Apollo.MutationFunction<VerifyCompanyMutation, VerifyCompanyMutationVariables>;

/**
 * __useVerifyCompanyMutation__
 *
 * To run a mutation, you first call `useVerifyCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyCompanyMutation, { data, loading, error }] = useVerifyCompanyMutation({
 *   variables: {
 *      company: // value for 'company'
 *   },
 * });
 */
export function useVerifyCompanyMutation(
    baseOptions?: Apollo.MutationHookOptions<VerifyCompanyMutation, VerifyCompanyMutationVariables>,
) {
    return Apollo.useMutation<VerifyCompanyMutation, VerifyCompanyMutationVariables>(
        VerifyCompanyDocument,
        baseOptions,
    );
}
export type VerifyCompanyMutationHookResult = ReturnType<typeof useVerifyCompanyMutation>;
export type VerifyCompanyMutationResult = Apollo.MutationResult<VerifyCompanyMutation>;
export type VerifyCompanyMutationOptions = Apollo.BaseMutationOptions<
    VerifyCompanyMutation,
    VerifyCompanyMutationVariables
>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($input: VerifyEmailInput!) {
        verifyEmail(input: $input) {
            success
        }
    }
`;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyEmailMutation(
    baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>,
) {
    return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, baseOptions);
}
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
        changePassword(input: $input) {
            success
        }
    }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>,
) {
    return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
        ChangePasswordDocument,
        baseOptions,
    );
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
>;
export const FacebookLoginDocument = gql`
    mutation FacebookLogin {
        facebookLogin {
            accessToken
            matrixAuth {
                access_token
                device_id
                home_server
                user_id
            }
        }
    }
`;
export type FacebookLoginMutationFn = Apollo.MutationFunction<FacebookLoginMutation, FacebookLoginMutationVariables>;

/**
 * __useFacebookLoginMutation__
 *
 * To run a mutation, you first call `useFacebookLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFacebookLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [facebookLoginMutation, { data, loading, error }] = useFacebookLoginMutation({
 *   variables: {
 *   },
 * });
 */
export function useFacebookLoginMutation(
    baseOptions?: Apollo.MutationHookOptions<FacebookLoginMutation, FacebookLoginMutationVariables>,
) {
    return Apollo.useMutation<FacebookLoginMutation, FacebookLoginMutationVariables>(
        FacebookLoginDocument,
        baseOptions,
    );
}
export type FacebookLoginMutationHookResult = ReturnType<typeof useFacebookLoginMutation>;
export type FacebookLoginMutationResult = Apollo.MutationResult<FacebookLoginMutation>;
export type FacebookLoginMutationOptions = Apollo.BaseMutationOptions<
    FacebookLoginMutation,
    FacebookLoginMutationVariables
>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($input: ForgotPasswordInput!) {
        forgotPassword(input: $input) {
            sent
        }
    }
`;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>,
) {
    return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(
        ForgotPasswordDocument,
        baseOptions,
    );
}
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
>;
export const GoogleLoginDocument = gql`
    mutation GoogleLogin {
        googleLogin {
            accessToken
            matrixAuth {
                access_token
                device_id
                home_server
                user_id
            }
        }
    }
`;
export type GoogleLoginMutationFn = Apollo.MutationFunction<GoogleLoginMutation, GoogleLoginMutationVariables>;

/**
 * __useGoogleLoginMutation__
 *
 * To run a mutation, you first call `useGoogleLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleLoginMutation, { data, loading, error }] = useGoogleLoginMutation({
 *   variables: {
 *   },
 * });
 */
export function useGoogleLoginMutation(
    baseOptions?: Apollo.MutationHookOptions<GoogleLoginMutation, GoogleLoginMutationVariables>,
) {
    return Apollo.useMutation<GoogleLoginMutation, GoogleLoginMutationVariables>(GoogleLoginDocument, baseOptions);
}
export type GoogleLoginMutationHookResult = ReturnType<typeof useGoogleLoginMutation>;
export type GoogleLoginMutationResult = Apollo.MutationResult<GoogleLoginMutation>;
export type GoogleLoginMutationOptions = Apollo.BaseMutationOptions<GoogleLoginMutation, GoogleLoginMutationVariables>;
export const SignInDocument = gql`
    mutation SignIn($credentials: CredentialsInput!) {
        login(credentials: $credentials) {
            accessToken
            matrixAuth {
                access_token
                device_id
                home_server
                user_id
            }
        }
    }
`;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      credentials: // value for 'credentials'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
    return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, baseOptions);
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const RegisterEmployerDocument = gql`
    mutation RegisterEmployer($employer: RegisterEmployerInput!) {
        registerEmployer(employer: $employer) {
            id
        }
    }
`;
export type RegisterEmployerMutationFn = Apollo.MutationFunction<
    RegisterEmployerMutation,
    RegisterEmployerMutationVariables
>;

/**
 * __useRegisterEmployerMutation__
 *
 * To run a mutation, you first call `useRegisterEmployerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterEmployerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerEmployerMutation, { data, loading, error }] = useRegisterEmployerMutation({
 *   variables: {
 *      employer: // value for 'employer'
 *   },
 * });
 */
export function useRegisterEmployerMutation(
    baseOptions?: Apollo.MutationHookOptions<RegisterEmployerMutation, RegisterEmployerMutationVariables>,
) {
    return Apollo.useMutation<RegisterEmployerMutation, RegisterEmployerMutationVariables>(
        RegisterEmployerDocument,
        baseOptions,
    );
}
export type RegisterEmployerMutationHookResult = ReturnType<typeof useRegisterEmployerMutation>;
export type RegisterEmployerMutationResult = Apollo.MutationResult<RegisterEmployerMutation>;
export type RegisterEmployerMutationOptions = Apollo.BaseMutationOptions<
    RegisterEmployerMutation,
    RegisterEmployerMutationVariables
>;
export const RegisterJobSeekerDocument = gql`
    mutation RegisterJobSeeker($jobseeker: RegisterJobseekerInput!) {
        registerJobseeker(jobseeker: $jobseeker) {
            id
        }
    }
`;
export type RegisterJobSeekerMutationFn = Apollo.MutationFunction<
    RegisterJobSeekerMutation,
    RegisterJobSeekerMutationVariables
>;

/**
 * __useRegisterJobSeekerMutation__
 *
 * To run a mutation, you first call `useRegisterJobSeekerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterJobSeekerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerJobSeekerMutation, { data, loading, error }] = useRegisterJobSeekerMutation({
 *   variables: {
 *      jobseeker: // value for 'jobseeker'
 *   },
 * });
 */
export function useRegisterJobSeekerMutation(
    baseOptions?: Apollo.MutationHookOptions<RegisterJobSeekerMutation, RegisterJobSeekerMutationVariables>,
) {
    return Apollo.useMutation<RegisterJobSeekerMutation, RegisterJobSeekerMutationVariables>(
        RegisterJobSeekerDocument,
        baseOptions,
    );
}
export type RegisterJobSeekerMutationHookResult = ReturnType<typeof useRegisterJobSeekerMutation>;
export type RegisterJobSeekerMutationResult = Apollo.MutationResult<RegisterJobSeekerMutation>;
export type RegisterJobSeekerMutationOptions = Apollo.BaseMutationOptions<
    RegisterJobSeekerMutation,
    RegisterJobSeekerMutationVariables
>;
export const RegisterMatrixUserDocument = gql`
    mutation RegisterMatrixUser($input: RegisterMatrixUserInput!) {
        registerMatrixUser(input: $input) {
            access_token
            device_id
            home_server
            user_id
        }
    }
`;
export type RegisterMatrixUserMutationFn = Apollo.MutationFunction<
    RegisterMatrixUserMutation,
    RegisterMatrixUserMutationVariables
>;

/**
 * __useRegisterMatrixUserMutation__
 *
 * To run a mutation, you first call `useRegisterMatrixUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMatrixUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMatrixUserMutation, { data, loading, error }] = useRegisterMatrixUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMatrixUserMutation(
    baseOptions?: Apollo.MutationHookOptions<RegisterMatrixUserMutation, RegisterMatrixUserMutationVariables>,
) {
    return Apollo.useMutation<RegisterMatrixUserMutation, RegisterMatrixUserMutationVariables>(
        RegisterMatrixUserDocument,
        baseOptions,
    );
}
export type RegisterMatrixUserMutationHookResult = ReturnType<typeof useRegisterMatrixUserMutation>;
export type RegisterMatrixUserMutationResult = Apollo.MutationResult<RegisterMatrixUserMutation>;
export type RegisterMatrixUserMutationOptions = Apollo.BaseMutationOptions<
    RegisterMatrixUserMutation,
    RegisterMatrixUserMutationVariables
>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
            success
        }
    }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>,
) {
    return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
        ResetPasswordDocument,
        baseOptions,
    );
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
>;
export const SendVerifyEmailDocument = gql`
    mutation SendVerifyEmail($input: SendVerifyEmailInput!) {
        sendVerificationEmail(input: $input) {
            sent
        }
    }
`;
export type SendVerifyEmailMutationFn = Apollo.MutationFunction<
    SendVerifyEmailMutation,
    SendVerifyEmailMutationVariables
>;

/**
 * __useSendVerifyEmailMutation__
 *
 * To run a mutation, you first call `useSendVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerifyEmailMutation, { data, loading, error }] = useSendVerifyEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendVerifyEmailMutation(
    baseOptions?: Apollo.MutationHookOptions<SendVerifyEmailMutation, SendVerifyEmailMutationVariables>,
) {
    return Apollo.useMutation<SendVerifyEmailMutation, SendVerifyEmailMutationVariables>(
        SendVerifyEmailDocument,
        baseOptions,
    );
}
export type SendVerifyEmailMutationHookResult = ReturnType<typeof useSendVerifyEmailMutation>;
export type SendVerifyEmailMutationResult = Apollo.MutationResult<SendVerifyEmailMutation>;
export type SendVerifyEmailMutationOptions = Apollo.BaseMutationOptions<
    SendVerifyEmailMutation,
    SendVerifyEmailMutationVariables
>;
export const ViewBlogPostDocument = gql`
    mutation ViewBlogPost($blogPost: ViewBlogPostInput!) {
        viewBlogPost(blogPost: $blogPost) {
            viewCount
        }
    }
`;
export type ViewBlogPostMutationFn = Apollo.MutationFunction<ViewBlogPostMutation, ViewBlogPostMutationVariables>;

/**
 * __useViewBlogPostMutation__
 *
 * To run a mutation, you first call `useViewBlogPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewBlogPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewBlogPostMutation, { data, loading, error }] = useViewBlogPostMutation({
 *   variables: {
 *      blogPost: // value for 'blogPost'
 *   },
 * });
 */
export function useViewBlogPostMutation(
    baseOptions?: Apollo.MutationHookOptions<ViewBlogPostMutation, ViewBlogPostMutationVariables>,
) {
    return Apollo.useMutation<ViewBlogPostMutation, ViewBlogPostMutationVariables>(ViewBlogPostDocument, baseOptions);
}
export type ViewBlogPostMutationHookResult = ReturnType<typeof useViewBlogPostMutation>;
export type ViewBlogPostMutationResult = Apollo.MutationResult<ViewBlogPostMutation>;
export type ViewBlogPostMutationOptions = Apollo.BaseMutationOptions<
    ViewBlogPostMutation,
    ViewBlogPostMutationVariables
>;
export const ConfirmScheduledEventDocument = gql`
    mutation ConfirmScheduledEvent($scheduledEvent: ConfirmScheduledEventInput!) {
        confirmScheduledEvent(scheduledEvent: $scheduledEvent) {
            scheduledEvent {
                name
                id
            }
        }
    }
`;
export type ConfirmScheduledEventMutationFn = Apollo.MutationFunction<
    ConfirmScheduledEventMutation,
    ConfirmScheduledEventMutationVariables
>;

/**
 * __useConfirmScheduledEventMutation__
 *
 * To run a mutation, you first call `useConfirmScheduledEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmScheduledEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmScheduledEventMutation, { data, loading, error }] = useConfirmScheduledEventMutation({
 *   variables: {
 *      scheduledEvent: // value for 'scheduledEvent'
 *   },
 * });
 */
export function useConfirmScheduledEventMutation(
    baseOptions?: Apollo.MutationHookOptions<ConfirmScheduledEventMutation, ConfirmScheduledEventMutationVariables>,
) {
    return Apollo.useMutation<ConfirmScheduledEventMutation, ConfirmScheduledEventMutationVariables>(
        ConfirmScheduledEventDocument,
        baseOptions,
    );
}
export type ConfirmScheduledEventMutationHookResult = ReturnType<typeof useConfirmScheduledEventMutation>;
export type ConfirmScheduledEventMutationResult = Apollo.MutationResult<ConfirmScheduledEventMutation>;
export type ConfirmScheduledEventMutationOptions = Apollo.BaseMutationOptions<
    ConfirmScheduledEventMutation,
    ConfirmScheduledEventMutationVariables
>;
export const CreateCalendarEventDocument = gql`
    mutation CreateCalendarEvent($calendarEvent: CreateCalendarEventInput!) {
        createCalendarEvent(calendarEvent: $calendarEvent) {
            calendarEvent {
                id
                start
                end
                date
                title
                duration
                userId
                createdAt
            }
        }
    }
`;
export type CreateCalendarEventMutationFn = Apollo.MutationFunction<
    CreateCalendarEventMutation,
    CreateCalendarEventMutationVariables
>;

/**
 * __useCreateCalendarEventMutation__
 *
 * To run a mutation, you first call `useCreateCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarEventMutation, { data, loading, error }] = useCreateCalendarEventMutation({
 *   variables: {
 *      calendarEvent: // value for 'calendarEvent'
 *   },
 * });
 */
export function useCreateCalendarEventMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateCalendarEventMutation, CreateCalendarEventMutationVariables>,
) {
    return Apollo.useMutation<CreateCalendarEventMutation, CreateCalendarEventMutationVariables>(
        CreateCalendarEventDocument,
        baseOptions,
    );
}
export type CreateCalendarEventMutationHookResult = ReturnType<typeof useCreateCalendarEventMutation>;
export type CreateCalendarEventMutationResult = Apollo.MutationResult<CreateCalendarEventMutation>;
export type CreateCalendarEventMutationOptions = Apollo.BaseMutationOptions<
    CreateCalendarEventMutation,
    CreateCalendarEventMutationVariables
>;
export const CreateScheduledEventDocument = gql`
    mutation CreateScheduledEvent($scheduledEvent: CreateScheduledEventInput!) {
        createScheduledEvent(scheduledEvent: $scheduledEvent) {
            scheduledEvent {
                id
            }
        }
    }
`;
export type CreateScheduledEventMutationFn = Apollo.MutationFunction<
    CreateScheduledEventMutation,
    CreateScheduledEventMutationVariables
>;

/**
 * __useCreateScheduledEventMutation__
 *
 * To run a mutation, you first call `useCreateScheduledEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduledEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduledEventMutation, { data, loading, error }] = useCreateScheduledEventMutation({
 *   variables: {
 *      scheduledEvent: // value for 'scheduledEvent'
 *   },
 * });
 */
export function useCreateScheduledEventMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateScheduledEventMutation, CreateScheduledEventMutationVariables>,
) {
    return Apollo.useMutation<CreateScheduledEventMutation, CreateScheduledEventMutationVariables>(
        CreateScheduledEventDocument,
        baseOptions,
    );
}
export type CreateScheduledEventMutationHookResult = ReturnType<typeof useCreateScheduledEventMutation>;
export type CreateScheduledEventMutationResult = Apollo.MutationResult<CreateScheduledEventMutation>;
export type CreateScheduledEventMutationOptions = Apollo.BaseMutationOptions<
    CreateScheduledEventMutation,
    CreateScheduledEventMutationVariables
>;
export const DeleteCalendarEventDocument = gql`
    mutation DeleteCalendarEvent($eventId: String!) {
        deleteCalendarEvent(eventId: $eventId)
    }
`;
export type DeleteCalendarEventMutationFn = Apollo.MutationFunction<
    DeleteCalendarEventMutation,
    DeleteCalendarEventMutationVariables
>;

/**
 * __useDeleteCalendarEventMutation__
 *
 * To run a mutation, you first call `useDeleteCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCalendarEventMutation, { data, loading, error }] = useDeleteCalendarEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useDeleteCalendarEventMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteCalendarEventMutation, DeleteCalendarEventMutationVariables>,
) {
    return Apollo.useMutation<DeleteCalendarEventMutation, DeleteCalendarEventMutationVariables>(
        DeleteCalendarEventDocument,
        baseOptions,
    );
}
export type DeleteCalendarEventMutationHookResult = ReturnType<typeof useDeleteCalendarEventMutation>;
export type DeleteCalendarEventMutationResult = Apollo.MutationResult<DeleteCalendarEventMutation>;
export type DeleteCalendarEventMutationOptions = Apollo.BaseMutationOptions<
    DeleteCalendarEventMutation,
    DeleteCalendarEventMutationVariables
>;
export const SendInvitationToJobseekerDocument = gql`
    mutation sendInvitationToJobseeker($input: CreateApplicationInviteInput!) {
        sendInvitationToJobseeker(input: $input) {
            applicationInvite {
                id
                jobseekerId
                jobId
            }
        }
    }
`;
export type SendInvitationToJobseekerMutationFn = Apollo.MutationFunction<
    SendInvitationToJobseekerMutation,
    SendInvitationToJobseekerMutationVariables
>;

/**
 * __useSendInvitationToJobseekerMutation__
 *
 * To run a mutation, you first call `useSendInvitationToJobseekerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationToJobseekerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationToJobseekerMutation, { data, loading, error }] = useSendInvitationToJobseekerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendInvitationToJobseekerMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SendInvitationToJobseekerMutation,
        SendInvitationToJobseekerMutationVariables
    >,
) {
    return Apollo.useMutation<SendInvitationToJobseekerMutation, SendInvitationToJobseekerMutationVariables>(
        SendInvitationToJobseekerDocument,
        baseOptions,
    );
}
export type SendInvitationToJobseekerMutationHookResult = ReturnType<typeof useSendInvitationToJobseekerMutation>;
export type SendInvitationToJobseekerMutationResult = Apollo.MutationResult<SendInvitationToJobseekerMutation>;
export type SendInvitationToJobseekerMutationOptions = Apollo.BaseMutationOptions<
    SendInvitationToJobseekerMutation,
    SendInvitationToJobseekerMutationVariables
>;
export const CreateJobApplicationCommentDocument = gql`
    mutation CreateJobApplicationComment($comment: CreateJobApplicationCommentInput!) {
        createJobApplicationComment(comment: $comment) {
            jobApplicationComment {
                id
                content
            }
        }
    }
`;
export type CreateJobApplicationCommentMutationFn = Apollo.MutationFunction<
    CreateJobApplicationCommentMutation,
    CreateJobApplicationCommentMutationVariables
>;

/**
 * __useCreateJobApplicationCommentMutation__
 *
 * To run a mutation, you first call `useCreateJobApplicationCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobApplicationCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobApplicationCommentMutation, { data, loading, error }] = useCreateJobApplicationCommentMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateJobApplicationCommentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateJobApplicationCommentMutation,
        CreateJobApplicationCommentMutationVariables
    >,
) {
    return Apollo.useMutation<CreateJobApplicationCommentMutation, CreateJobApplicationCommentMutationVariables>(
        CreateJobApplicationCommentDocument,
        baseOptions,
    );
}
export type CreateJobApplicationCommentMutationHookResult = ReturnType<typeof useCreateJobApplicationCommentMutation>;
export type CreateJobApplicationCommentMutationResult = Apollo.MutationResult<CreateJobApplicationCommentMutation>;
export type CreateJobApplicationCommentMutationOptions = Apollo.BaseMutationOptions<
    CreateJobApplicationCommentMutation,
    CreateJobApplicationCommentMutationVariables
>;
export const DeleteJobApplicationCommentDocument = gql`
    mutation DeleteJobApplicationComment($commentId: String!) {
        deleteJobApplicationComment(commentId: $commentId)
    }
`;
export type DeleteJobApplicationCommentMutationFn = Apollo.MutationFunction<
    DeleteJobApplicationCommentMutation,
    DeleteJobApplicationCommentMutationVariables
>;

/**
 * __useDeleteJobApplicationCommentMutation__
 *
 * To run a mutation, you first call `useDeleteJobApplicationCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobApplicationCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobApplicationCommentMutation, { data, loading, error }] = useDeleteJobApplicationCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteJobApplicationCommentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteJobApplicationCommentMutation,
        DeleteJobApplicationCommentMutationVariables
    >,
) {
    return Apollo.useMutation<DeleteJobApplicationCommentMutation, DeleteJobApplicationCommentMutationVariables>(
        DeleteJobApplicationCommentDocument,
        baseOptions,
    );
}
export type DeleteJobApplicationCommentMutationHookResult = ReturnType<typeof useDeleteJobApplicationCommentMutation>;
export type DeleteJobApplicationCommentMutationResult = Apollo.MutationResult<DeleteJobApplicationCommentMutation>;
export type DeleteJobApplicationCommentMutationOptions = Apollo.BaseMutationOptions<
    DeleteJobApplicationCommentMutation,
    DeleteJobApplicationCommentMutationVariables
>;
export const MoveApplicationToStageDocument = gql`
    mutation MoveApplicationToStage(
        $applicationId: String!
        $stageId: String!
        $triggers: [EmailStageActionOverrideInput!]
    ) {
        moveApplicationToStage(input: { applicationId: $applicationId, stageId: $stageId, triggers: $triggers }) {
            application {
                id
                stageId
                stage {
                    id
                    title
                }
            }
        }
    }
`;
export type MoveApplicationToStageMutationFn = Apollo.MutationFunction<
    MoveApplicationToStageMutation,
    MoveApplicationToStageMutationVariables
>;

/**
 * __useMoveApplicationToStageMutation__
 *
 * To run a mutation, you first call `useMoveApplicationToStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveApplicationToStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveApplicationToStageMutation, { data, loading, error }] = useMoveApplicationToStageMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      stageId: // value for 'stageId'
 *      triggers: // value for 'triggers'
 *   },
 * });
 */
export function useMoveApplicationToStageMutation(
    baseOptions?: Apollo.MutationHookOptions<MoveApplicationToStageMutation, MoveApplicationToStageMutationVariables>,
) {
    return Apollo.useMutation<MoveApplicationToStageMutation, MoveApplicationToStageMutationVariables>(
        MoveApplicationToStageDocument,
        baseOptions,
    );
}
export type MoveApplicationToStageMutationHookResult = ReturnType<typeof useMoveApplicationToStageMutation>;
export type MoveApplicationToStageMutationResult = Apollo.MutationResult<MoveApplicationToStageMutation>;
export type MoveApplicationToStageMutationOptions = Apollo.BaseMutationOptions<
    MoveApplicationToStageMutation,
    MoveApplicationToStageMutationVariables
>;
export const UpdateJobApplicationCommentDocument = gql`
    mutation UpdateJobApplicationComment($comment: UpdateJobApplicationCommentInput!) {
        updateJobApplicationComment(comment: $comment) {
            jobApplicationComment {
                id
                content
            }
        }
    }
`;
export type UpdateJobApplicationCommentMutationFn = Apollo.MutationFunction<
    UpdateJobApplicationCommentMutation,
    UpdateJobApplicationCommentMutationVariables
>;

/**
 * __useUpdateJobApplicationCommentMutation__
 *
 * To run a mutation, you first call `useUpdateJobApplicationCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobApplicationCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobApplicationCommentMutation, { data, loading, error }] = useUpdateJobApplicationCommentMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateJobApplicationCommentMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateJobApplicationCommentMutation,
        UpdateJobApplicationCommentMutationVariables
    >,
) {
    return Apollo.useMutation<UpdateJobApplicationCommentMutation, UpdateJobApplicationCommentMutationVariables>(
        UpdateJobApplicationCommentDocument,
        baseOptions,
    );
}
export type UpdateJobApplicationCommentMutationHookResult = ReturnType<typeof useUpdateJobApplicationCommentMutation>;
export type UpdateJobApplicationCommentMutationResult = Apollo.MutationResult<UpdateJobApplicationCommentMutation>;
export type UpdateJobApplicationCommentMutationOptions = Apollo.BaseMutationOptions<
    UpdateJobApplicationCommentMutation,
    UpdateJobApplicationCommentMutationVariables
>;
export const BuyJobSlotDocument = gql`
    mutation BuyJobSlot($jobId: String!) {
        buyJobSlot(jobId: $jobId)
    }
`;
export type BuyJobSlotMutationFn = Apollo.MutationFunction<BuyJobSlotMutation, BuyJobSlotMutationVariables>;

/**
 * __useBuyJobSlotMutation__
 *
 * To run a mutation, you first call `useBuyJobSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyJobSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyJobSlotMutation, { data, loading, error }] = useBuyJobSlotMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useBuyJobSlotMutation(
    baseOptions?: Apollo.MutationHookOptions<BuyJobSlotMutation, BuyJobSlotMutationVariables>,
) {
    return Apollo.useMutation<BuyJobSlotMutation, BuyJobSlotMutationVariables>(BuyJobSlotDocument, baseOptions);
}
export type BuyJobSlotMutationHookResult = ReturnType<typeof useBuyJobSlotMutation>;
export type BuyJobSlotMutationResult = Apollo.MutationResult<BuyJobSlotMutation>;
export type BuyJobSlotMutationOptions = Apollo.BaseMutationOptions<BuyJobSlotMutation, BuyJobSlotMutationVariables>;
export const AddExternalCandidateDocument = gql`
    mutation AddExternalCandidate($input: AddExternalCandidateInput!) {
        addExternalCandidate(input: $input) {
            candidate {
                id
            }
        }
    }
`;
export type AddExternalCandidateMutationFn = Apollo.MutationFunction<
    AddExternalCandidateMutation,
    AddExternalCandidateMutationVariables
>;

/**
 * __useAddExternalCandidateMutation__
 *
 * To run a mutation, you first call `useAddExternalCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddExternalCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addExternalCandidateMutation, { data, loading, error }] = useAddExternalCandidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddExternalCandidateMutation(
    baseOptions?: Apollo.MutationHookOptions<AddExternalCandidateMutation, AddExternalCandidateMutationVariables>,
) {
    return Apollo.useMutation<AddExternalCandidateMutation, AddExternalCandidateMutationVariables>(
        AddExternalCandidateDocument,
        baseOptions,
    );
}
export type AddExternalCandidateMutationHookResult = ReturnType<typeof useAddExternalCandidateMutation>;
export type AddExternalCandidateMutationResult = Apollo.MutationResult<AddExternalCandidateMutation>;
export type AddExternalCandidateMutationOptions = Apollo.BaseMutationOptions<
    AddExternalCandidateMutation,
    AddExternalCandidateMutationVariables
>;
export const RejectMultipleCandidatesDocument = gql`
    mutation RejectMultipleCandidates($input: RejectMultipleCandidatesInput!) {
        rejectMultipleCandidates(input: $input) {
            totalCount
            pageInfo {
                totalPages
            }
            edges {
                node {
                    id
                    applicant {
                        id
                        firstName
                        lastName
                        createdAt
                        avatar {
                            id
                            url
                        }
                        contacts {
                            emails
                            phones {
                                number
                                comment
                            }
                        }
                    }
                    stage {
                        status
                        color
                    }
                }
            }
        }
    }
`;
export type RejectMultipleCandidatesMutationFn = Apollo.MutationFunction<
    RejectMultipleCandidatesMutation,
    RejectMultipleCandidatesMutationVariables
>;

/**
 * __useRejectMultipleCandidatesMutation__
 *
 * To run a mutation, you first call `useRejectMultipleCandidatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectMultipleCandidatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectMultipleCandidatesMutation, { data, loading, error }] = useRejectMultipleCandidatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectMultipleCandidatesMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RejectMultipleCandidatesMutation,
        RejectMultipleCandidatesMutationVariables
    >,
) {
    return Apollo.useMutation<RejectMultipleCandidatesMutation, RejectMultipleCandidatesMutationVariables>(
        RejectMultipleCandidatesDocument,
        baseOptions,
    );
}
export type RejectMultipleCandidatesMutationHookResult = ReturnType<typeof useRejectMultipleCandidatesMutation>;
export type RejectMultipleCandidatesMutationResult = Apollo.MutationResult<RejectMultipleCandidatesMutation>;
export type RejectMultipleCandidatesMutationOptions = Apollo.BaseMutationOptions<
    RejectMultipleCandidatesMutation,
    RejectMultipleCandidatesMutationVariables
>;
export const CreateHiringProcessDocument = gql`
    mutation CreateHiringProcess($title: String!) {
        createHiringProcess(process: { title: $title }) {
            hiringProcess {
                id
                title
                employerId
                createdAt
                updatedAt
                version
            }
        }
    }
`;
export type CreateHiringProcessMutationFn = Apollo.MutationFunction<
    CreateHiringProcessMutation,
    CreateHiringProcessMutationVariables
>;

/**
 * __useCreateHiringProcessMutation__
 *
 * To run a mutation, you first call `useCreateHiringProcessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHiringProcessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHiringProcessMutation, { data, loading, error }] = useCreateHiringProcessMutation({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateHiringProcessMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateHiringProcessMutation, CreateHiringProcessMutationVariables>,
) {
    return Apollo.useMutation<CreateHiringProcessMutation, CreateHiringProcessMutationVariables>(
        CreateHiringProcessDocument,
        baseOptions,
    );
}
export type CreateHiringProcessMutationHookResult = ReturnType<typeof useCreateHiringProcessMutation>;
export type CreateHiringProcessMutationResult = Apollo.MutationResult<CreateHiringProcessMutation>;
export type CreateHiringProcessMutationOptions = Apollo.BaseMutationOptions<
    CreateHiringProcessMutation,
    CreateHiringProcessMutationVariables
>;
export const DeleteHiringProcessDocument = gql`
    mutation DeleteHiringProcess($id: String!) {
        deleteHiringProcess(id: $id) {
            id
        }
    }
`;
export type DeleteHiringProcessMutationFn = Apollo.MutationFunction<
    DeleteHiringProcessMutation,
    DeleteHiringProcessMutationVariables
>;

/**
 * __useDeleteHiringProcessMutation__
 *
 * To run a mutation, you first call `useDeleteHiringProcessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHiringProcessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHiringProcessMutation, { data, loading, error }] = useDeleteHiringProcessMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteHiringProcessMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteHiringProcessMutation, DeleteHiringProcessMutationVariables>,
) {
    return Apollo.useMutation<DeleteHiringProcessMutation, DeleteHiringProcessMutationVariables>(
        DeleteHiringProcessDocument,
        baseOptions,
    );
}
export type DeleteHiringProcessMutationHookResult = ReturnType<typeof useDeleteHiringProcessMutation>;
export type DeleteHiringProcessMutationResult = Apollo.MutationResult<DeleteHiringProcessMutation>;
export type DeleteHiringProcessMutationOptions = Apollo.BaseMutationOptions<
    DeleteHiringProcessMutation,
    DeleteHiringProcessMutationVariables
>;
export const EditHiringProcessDocument = gql`
    mutation EditHiringProcess($id: String!, $title: String!) {
        editHiringProcess(process: { id: $id, title: $title }) {
            hiringProcess {
                id
                title
                employerId
                createdAt
                updatedAt
                version
            }
        }
    }
`;
export type EditHiringProcessMutationFn = Apollo.MutationFunction<
    EditHiringProcessMutation,
    EditHiringProcessMutationVariables
>;

/**
 * __useEditHiringProcessMutation__
 *
 * To run a mutation, you first call `useEditHiringProcessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditHiringProcessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editHiringProcessMutation, { data, loading, error }] = useEditHiringProcessMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useEditHiringProcessMutation(
    baseOptions?: Apollo.MutationHookOptions<EditHiringProcessMutation, EditHiringProcessMutationVariables>,
) {
    return Apollo.useMutation<EditHiringProcessMutation, EditHiringProcessMutationVariables>(
        EditHiringProcessDocument,
        baseOptions,
    );
}
export type EditHiringProcessMutationHookResult = ReturnType<typeof useEditHiringProcessMutation>;
export type EditHiringProcessMutationResult = Apollo.MutationResult<EditHiringProcessMutation>;
export type EditHiringProcessMutationOptions = Apollo.BaseMutationOptions<
    EditHiringProcessMutation,
    EditHiringProcessMutationVariables
>;
export const CreateHiringStageDocument = gql`
    mutation CreateHiringStage($title: String!, $color: String!, $status: HiringStageStatus!, $processId: String!) {
        createHiringStage(stage: { title: $title, color: $color, status: $status, processId: $processId }) {
            hiringStage {
                title
            }
        }
    }
`;
export type CreateHiringStageMutationFn = Apollo.MutationFunction<
    CreateHiringStageMutation,
    CreateHiringStageMutationVariables
>;

/**
 * __useCreateHiringStageMutation__
 *
 * To run a mutation, you first call `useCreateHiringStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHiringStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHiringStageMutation, { data, loading, error }] = useCreateHiringStageMutation({
 *   variables: {
 *      title: // value for 'title'
 *      color: // value for 'color'
 *      status: // value for 'status'
 *      processId: // value for 'processId'
 *   },
 * });
 */
export function useCreateHiringStageMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateHiringStageMutation, CreateHiringStageMutationVariables>,
) {
    return Apollo.useMutation<CreateHiringStageMutation, CreateHiringStageMutationVariables>(
        CreateHiringStageDocument,
        baseOptions,
    );
}
export type CreateHiringStageMutationHookResult = ReturnType<typeof useCreateHiringStageMutation>;
export type CreateHiringStageMutationResult = Apollo.MutationResult<CreateHiringStageMutation>;
export type CreateHiringStageMutationOptions = Apollo.BaseMutationOptions<
    CreateHiringStageMutation,
    CreateHiringStageMutationVariables
>;
export const DeleteHiringStageDocument = gql`
    mutation DeleteHiringStage($id: String!) {
        deleteHiringStage(id: $id) {
            id
        }
    }
`;
export type DeleteHiringStageMutationFn = Apollo.MutationFunction<
    DeleteHiringStageMutation,
    DeleteHiringStageMutationVariables
>;

/**
 * __useDeleteHiringStageMutation__
 *
 * To run a mutation, you first call `useDeleteHiringStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHiringStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHiringStageMutation, { data, loading, error }] = useDeleteHiringStageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteHiringStageMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteHiringStageMutation, DeleteHiringStageMutationVariables>,
) {
    return Apollo.useMutation<DeleteHiringStageMutation, DeleteHiringStageMutationVariables>(
        DeleteHiringStageDocument,
        baseOptions,
    );
}
export type DeleteHiringStageMutationHookResult = ReturnType<typeof useDeleteHiringStageMutation>;
export type DeleteHiringStageMutationResult = Apollo.MutationResult<DeleteHiringStageMutation>;
export type DeleteHiringStageMutationOptions = Apollo.BaseMutationOptions<
    DeleteHiringStageMutation,
    DeleteHiringStageMutationVariables
>;
export const EditHiringStageDocument = gql`
    mutation EditHiringStage($id: String!, $title: String!, $color: String!, $status: HiringStageStatus!) {
        editHiringStage(stage: { id: $id, title: $title, color: $color, status: $status }) {
            hiringStage {
                id
                title
                color
                status
                order
                createdAt
                updatedAt
            }
        }
    }
`;
export type EditHiringStageMutationFn = Apollo.MutationFunction<
    EditHiringStageMutation,
    EditHiringStageMutationVariables
>;

/**
 * __useEditHiringStageMutation__
 *
 * To run a mutation, you first call `useEditHiringStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditHiringStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editHiringStageMutation, { data, loading, error }] = useEditHiringStageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      color: // value for 'color'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useEditHiringStageMutation(
    baseOptions?: Apollo.MutationHookOptions<EditHiringStageMutation, EditHiringStageMutationVariables>,
) {
    return Apollo.useMutation<EditHiringStageMutation, EditHiringStageMutationVariables>(
        EditHiringStageDocument,
        baseOptions,
    );
}
export type EditHiringStageMutationHookResult = ReturnType<typeof useEditHiringStageMutation>;
export type EditHiringStageMutationResult = Apollo.MutationResult<EditHiringStageMutation>;
export type EditHiringStageMutationOptions = Apollo.BaseMutationOptions<
    EditHiringStageMutation,
    EditHiringStageMutationVariables
>;
export const ReorderHiringStagesDocument = gql`
    mutation ReorderHiringStages($stage: ReorderHiringStageInput!) {
        reorderHiringStage(stage: $stage) {
            stages {
                edges {
                    node {
                        id
                        status
                        order
                        color
                        title
                        processId
                        version
                        triggers {
                            totalCount
                        }
                    }
                }
            }
        }
    }
`;
export type ReorderHiringStagesMutationFn = Apollo.MutationFunction<
    ReorderHiringStagesMutation,
    ReorderHiringStagesMutationVariables
>;

/**
 * __useReorderHiringStagesMutation__
 *
 * To run a mutation, you first call `useReorderHiringStagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderHiringStagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderHiringStagesMutation, { data, loading, error }] = useReorderHiringStagesMutation({
 *   variables: {
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useReorderHiringStagesMutation(
    baseOptions?: Apollo.MutationHookOptions<ReorderHiringStagesMutation, ReorderHiringStagesMutationVariables>,
) {
    return Apollo.useMutation<ReorderHiringStagesMutation, ReorderHiringStagesMutationVariables>(
        ReorderHiringStagesDocument,
        baseOptions,
    );
}
export type ReorderHiringStagesMutationHookResult = ReturnType<typeof useReorderHiringStagesMutation>;
export type ReorderHiringStagesMutationResult = Apollo.MutationResult<ReorderHiringStagesMutation>;
export type ReorderHiringStagesMutationOptions = Apollo.BaseMutationOptions<
    ReorderHiringStagesMutation,
    ReorderHiringStagesMutationVariables
>;
export const CreateJobDocument = gql`
    mutation CreateJob($values: CreateJobInput!) {
        createJob(job: $values) {
            job {
                id
                slug
                title
            }
        }
    }
`;
export type CreateJobMutationFn = Apollo.MutationFunction<CreateJobMutation, CreateJobMutationVariables>;

/**
 * __useCreateJobMutation__
 *
 * To run a mutation, you first call `useCreateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobMutation, { data, loading, error }] = useCreateJobMutation({
 *   variables: {
 *      values: // value for 'values'
 *   },
 * });
 */
export function useCreateJobMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateJobMutation, CreateJobMutationVariables>,
) {
    return Apollo.useMutation<CreateJobMutation, CreateJobMutationVariables>(CreateJobDocument, baseOptions);
}
export type CreateJobMutationHookResult = ReturnType<typeof useCreateJobMutation>;
export type CreateJobMutationResult = Apollo.MutationResult<CreateJobMutation>;
export type CreateJobMutationOptions = Apollo.BaseMutationOptions<CreateJobMutation, CreateJobMutationVariables>;
export const DeleteJobDocument = gql`
    mutation DeleteJob($id: String!) {
        deleteJob(id: $id) {
            id
        }
    }
`;
export type DeleteJobMutationFn = Apollo.MutationFunction<DeleteJobMutation, DeleteJobMutationVariables>;

/**
 * __useDeleteJobMutation__
 *
 * To run a mutation, you first call `useDeleteJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobMutation, { data, loading, error }] = useDeleteJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteJobMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteJobMutation, DeleteJobMutationVariables>,
) {
    return Apollo.useMutation<DeleteJobMutation, DeleteJobMutationVariables>(DeleteJobDocument, baseOptions);
}
export type DeleteJobMutationHookResult = ReturnType<typeof useDeleteJobMutation>;
export type DeleteJobMutationResult = Apollo.MutationResult<DeleteJobMutation>;
export type DeleteJobMutationOptions = Apollo.BaseMutationOptions<DeleteJobMutation, DeleteJobMutationVariables>;
export const EditJobDocument = gql`
    mutation EditJob($values: EditJobInput!) {
        editJob(job: $values) {
            job {
                id
                slug
                title
            }
        }
    }
`;
export type EditJobMutationFn = Apollo.MutationFunction<EditJobMutation, EditJobMutationVariables>;

/**
 * __useEditJobMutation__
 *
 * To run a mutation, you first call `useEditJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editJobMutation, { data, loading, error }] = useEditJobMutation({
 *   variables: {
 *      values: // value for 'values'
 *   },
 * });
 */
export function useEditJobMutation(
    baseOptions?: Apollo.MutationHookOptions<EditJobMutation, EditJobMutationVariables>,
) {
    return Apollo.useMutation<EditJobMutation, EditJobMutationVariables>(EditJobDocument, baseOptions);
}
export type EditJobMutationHookResult = ReturnType<typeof useEditJobMutation>;
export type EditJobMutationResult = Apollo.MutationResult<EditJobMutation>;
export type EditJobMutationOptions = Apollo.BaseMutationOptions<EditJobMutation, EditJobMutationVariables>;
export const PublishJobDocument = gql`
    mutation PublishJob($job: PublishJobInput!) {
        publishJob(job: $job) {
            job {
                id
                slug
                title
                status
                viewCount
                expiresAt
                createdAt
                publishedAt
                applications {
                    totalCount
                }
            }
        }
    }
`;
export type PublishJobMutationFn = Apollo.MutationFunction<PublishJobMutation, PublishJobMutationVariables>;

/**
 * __usePublishJobMutation__
 *
 * To run a mutation, you first call `usePublishJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishJobMutation, { data, loading, error }] = usePublishJobMutation({
 *   variables: {
 *      job: // value for 'job'
 *   },
 * });
 */
export function usePublishJobMutation(
    baseOptions?: Apollo.MutationHookOptions<PublishJobMutation, PublishJobMutationVariables>,
) {
    return Apollo.useMutation<PublishJobMutation, PublishJobMutationVariables>(PublishJobDocument, baseOptions);
}
export type PublishJobMutationHookResult = ReturnType<typeof usePublishJobMutation>;
export type PublishJobMutationResult = Apollo.MutationResult<PublishJobMutation>;
export type PublishJobMutationOptions = Apollo.BaseMutationOptions<PublishJobMutation, PublishJobMutationVariables>;
export const CreateScheduledEventTemplateDocument = gql`
    mutation CreateScheduledEventTemplate($scheduledEventTemplate: CreateScheduledEventTemplateInput!) {
        createScheduledEventTemplate(scheduledEventTemplate: $scheduledEventTemplate) {
            scheduledEventTemplate {
                id
            }
        }
    }
`;
export type CreateScheduledEventTemplateMutationFn = Apollo.MutationFunction<
    CreateScheduledEventTemplateMutation,
    CreateScheduledEventTemplateMutationVariables
>;

/**
 * __useCreateScheduledEventTemplateMutation__
 *
 * To run a mutation, you first call `useCreateScheduledEventTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduledEventTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduledEventTemplateMutation, { data, loading, error }] = useCreateScheduledEventTemplateMutation({
 *   variables: {
 *      scheduledEventTemplate: // value for 'scheduledEventTemplate'
 *   },
 * });
 */
export function useCreateScheduledEventTemplateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateScheduledEventTemplateMutation,
        CreateScheduledEventTemplateMutationVariables
    >,
) {
    return Apollo.useMutation<CreateScheduledEventTemplateMutation, CreateScheduledEventTemplateMutationVariables>(
        CreateScheduledEventTemplateDocument,
        baseOptions,
    );
}
export type CreateScheduledEventTemplateMutationHookResult = ReturnType<typeof useCreateScheduledEventTemplateMutation>;
export type CreateScheduledEventTemplateMutationResult = Apollo.MutationResult<CreateScheduledEventTemplateMutation>;
export type CreateScheduledEventTemplateMutationOptions = Apollo.BaseMutationOptions<
    CreateScheduledEventTemplateMutation,
    CreateScheduledEventTemplateMutationVariables
>;
export const DeleteScheduledEventTemplateDocument = gql`
    mutation DeleteScheduledEventTemplate($templateId: String!) {
        deleteScheduledEventTemplate(templateId: $templateId)
    }
`;
export type DeleteScheduledEventTemplateMutationFn = Apollo.MutationFunction<
    DeleteScheduledEventTemplateMutation,
    DeleteScheduledEventTemplateMutationVariables
>;

/**
 * __useDeleteScheduledEventTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteScheduledEventTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduledEventTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduledEventTemplateMutation, { data, loading, error }] = useDeleteScheduledEventTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useDeleteScheduledEventTemplateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteScheduledEventTemplateMutation,
        DeleteScheduledEventTemplateMutationVariables
    >,
) {
    return Apollo.useMutation<DeleteScheduledEventTemplateMutation, DeleteScheduledEventTemplateMutationVariables>(
        DeleteScheduledEventTemplateDocument,
        baseOptions,
    );
}
export type DeleteScheduledEventTemplateMutationHookResult = ReturnType<typeof useDeleteScheduledEventTemplateMutation>;
export type DeleteScheduledEventTemplateMutationResult = Apollo.MutationResult<DeleteScheduledEventTemplateMutation>;
export type DeleteScheduledEventTemplateMutationOptions = Apollo.BaseMutationOptions<
    DeleteScheduledEventTemplateMutation,
    DeleteScheduledEventTemplateMutationVariables
>;
export const UpdateScheduledEventTemplateDocument = gql`
    mutation UpdateScheduledEventTemplate($scheduledEventTemplate: UpdateScheduledEventTemplateInput!) {
        updateScheduledEventTemplate(scheduledEventTemplate: $scheduledEventTemplate) {
            scheduledEventTemplate {
                id
            }
        }
    }
`;
export type UpdateScheduledEventTemplateMutationFn = Apollo.MutationFunction<
    UpdateScheduledEventTemplateMutation,
    UpdateScheduledEventTemplateMutationVariables
>;

/**
 * __useUpdateScheduledEventTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateScheduledEventTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduledEventTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduledEventTemplateMutation, { data, loading, error }] = useUpdateScheduledEventTemplateMutation({
 *   variables: {
 *      scheduledEventTemplate: // value for 'scheduledEventTemplate'
 *   },
 * });
 */
export function useUpdateScheduledEventTemplateMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateScheduledEventTemplateMutation,
        UpdateScheduledEventTemplateMutationVariables
    >,
) {
    return Apollo.useMutation<UpdateScheduledEventTemplateMutation, UpdateScheduledEventTemplateMutationVariables>(
        UpdateScheduledEventTemplateDocument,
        baseOptions,
    );
}
export type UpdateScheduledEventTemplateMutationHookResult = ReturnType<typeof useUpdateScheduledEventTemplateMutation>;
export type UpdateScheduledEventTemplateMutationResult = Apollo.MutationResult<UpdateScheduledEventTemplateMutation>;
export type UpdateScheduledEventTemplateMutationOptions = Apollo.BaseMutationOptions<
    UpdateScheduledEventTemplateMutation,
    UpdateScheduledEventTemplateMutationVariables
>;
export const CreateEmailStageActionDocument = gql`
    mutation CreateEmailStageAction($trigger: CreateEmailStageActionInput!) {
        createStageTrigger(trigger: $trigger) {
            hiringStage {
                ...HiringStageParts
            }
        }
    }
    ${HiringStagePartsFragmentDoc}
`;
export type CreateEmailStageActionMutationFn = Apollo.MutationFunction<
    CreateEmailStageActionMutation,
    CreateEmailStageActionMutationVariables
>;

/**
 * __useCreateEmailStageActionMutation__
 *
 * To run a mutation, you first call `useCreateEmailStageActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailStageActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailStageActionMutation, { data, loading, error }] = useCreateEmailStageActionMutation({
 *   variables: {
 *      trigger: // value for 'trigger'
 *   },
 * });
 */
export function useCreateEmailStageActionMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateEmailStageActionMutation, CreateEmailStageActionMutationVariables>,
) {
    return Apollo.useMutation<CreateEmailStageActionMutation, CreateEmailStageActionMutationVariables>(
        CreateEmailStageActionDocument,
        baseOptions,
    );
}
export type CreateEmailStageActionMutationHookResult = ReturnType<typeof useCreateEmailStageActionMutation>;
export type CreateEmailStageActionMutationResult = Apollo.MutationResult<CreateEmailStageActionMutation>;
export type CreateEmailStageActionMutationOptions = Apollo.BaseMutationOptions<
    CreateEmailStageActionMutation,
    CreateEmailStageActionMutationVariables
>;
export const DeleteStageActionDocument = gql`
    mutation DeleteStageAction($id: String!) {
        deleteStageAction(id: $id) {
            id
        }
    }
`;
export type DeleteStageActionMutationFn = Apollo.MutationFunction<
    DeleteStageActionMutation,
    DeleteStageActionMutationVariables
>;

/**
 * __useDeleteStageActionMutation__
 *
 * To run a mutation, you first call `useDeleteStageActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStageActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStageActionMutation, { data, loading, error }] = useDeleteStageActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStageActionMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteStageActionMutation, DeleteStageActionMutationVariables>,
) {
    return Apollo.useMutation<DeleteStageActionMutation, DeleteStageActionMutationVariables>(
        DeleteStageActionDocument,
        baseOptions,
    );
}
export type DeleteStageActionMutationHookResult = ReturnType<typeof useDeleteStageActionMutation>;
export type DeleteStageActionMutationResult = Apollo.MutationResult<DeleteStageActionMutation>;
export type DeleteStageActionMutationOptions = Apollo.BaseMutationOptions<
    DeleteStageActionMutation,
    DeleteStageActionMutationVariables
>;
export const EditEmailStageActionDocument = gql`
    mutation EditEmailStageAction($trigger: EditEmailStageActionInput!) {
        editStageTrigger(trigger: $trigger) {
            trigger {
                id
                type
                stageId
                payload {
                    ... on EmailActionPayload {
                        to
                        subject
                        body
                        from
                        toEmails
                    }
                }
            }
        }
    }
`;
export type EditEmailStageActionMutationFn = Apollo.MutationFunction<
    EditEmailStageActionMutation,
    EditEmailStageActionMutationVariables
>;

/**
 * __useEditEmailStageActionMutation__
 *
 * To run a mutation, you first call `useEditEmailStageActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEmailStageActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEmailStageActionMutation, { data, loading, error }] = useEditEmailStageActionMutation({
 *   variables: {
 *      trigger: // value for 'trigger'
 *   },
 * });
 */
export function useEditEmailStageActionMutation(
    baseOptions?: Apollo.MutationHookOptions<EditEmailStageActionMutation, EditEmailStageActionMutationVariables>,
) {
    return Apollo.useMutation<EditEmailStageActionMutation, EditEmailStageActionMutationVariables>(
        EditEmailStageActionDocument,
        baseOptions,
    );
}
export type EditEmailStageActionMutationHookResult = ReturnType<typeof useEditEmailStageActionMutation>;
export type EditEmailStageActionMutationResult = Apollo.MutationResult<EditEmailStageActionMutation>;
export type EditEmailStageActionMutationOptions = Apollo.BaseMutationOptions<
    EditEmailStageActionMutation,
    EditEmailStageActionMutationVariables
>;
export const UpdateEmployerAvatarDocument = gql`
    mutation UpdateEmployerAvatar($employer: UpdateEmployerAvatarInput!) {
        updateEmployerAvatar(employer: $employer) {
            employer {
                id
                avatar {
                    id
                    url
                }
            }
        }
    }
`;
export type UpdateEmployerAvatarMutationFn = Apollo.MutationFunction<
    UpdateEmployerAvatarMutation,
    UpdateEmployerAvatarMutationVariables
>;

/**
 * __useUpdateEmployerAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateEmployerAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployerAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployerAvatarMutation, { data, loading, error }] = useUpdateEmployerAvatarMutation({
 *   variables: {
 *      employer: // value for 'employer'
 *   },
 * });
 */
export function useUpdateEmployerAvatarMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateEmployerAvatarMutation, UpdateEmployerAvatarMutationVariables>,
) {
    return Apollo.useMutation<UpdateEmployerAvatarMutation, UpdateEmployerAvatarMutationVariables>(
        UpdateEmployerAvatarDocument,
        baseOptions,
    );
}
export type UpdateEmployerAvatarMutationHookResult = ReturnType<typeof useUpdateEmployerAvatarMutation>;
export type UpdateEmployerAvatarMutationResult = Apollo.MutationResult<UpdateEmployerAvatarMutation>;
export type UpdateEmployerAvatarMutationOptions = Apollo.BaseMutationOptions<
    UpdateEmployerAvatarMutation,
    UpdateEmployerAvatarMutationVariables
>;
export const UpdateCompanyLogoDocument = gql`
    mutation updateCompanyLogo($input: UploadCompanyLogoInput!) {
        updateCompanyLogo(input: $input) {
            logo {
                name
            }
        }
    }
`;
export type UpdateCompanyLogoMutationFn = Apollo.MutationFunction<
    UpdateCompanyLogoMutation,
    UpdateCompanyLogoMutationVariables
>;

/**
 * __useUpdateCompanyLogoMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyLogoMutation, { data, loading, error }] = useUpdateCompanyLogoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyLogoMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateCompanyLogoMutation, UpdateCompanyLogoMutationVariables>,
) {
    return Apollo.useMutation<UpdateCompanyLogoMutation, UpdateCompanyLogoMutationVariables>(
        UpdateCompanyLogoDocument,
        baseOptions,
    );
}
export type UpdateCompanyLogoMutationHookResult = ReturnType<typeof useUpdateCompanyLogoMutation>;
export type UpdateCompanyLogoMutationResult = Apollo.MutationResult<UpdateCompanyLogoMutation>;
export type UpdateCompanyLogoMutationOptions = Apollo.BaseMutationOptions<
    UpdateCompanyLogoMutation,
    UpdateCompanyLogoMutationVariables
>;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($company: UpdateCompanyInput!) {
        updateCompany(company: $company) {
            company {
                name
                type
                regionId
                industryId
                website
                description
            }
        }
    }
`;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      company: // value for 'company'
 *   },
 * });
 */
export function useUpdateCompanyMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>,
) {
    return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(
        UpdateCompanyDocument,
        baseOptions,
    );
}
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
>;
export const UpdateEmployerDocument = gql`
    mutation UpdateEmployer($input: UpdateEmployerInput!) {
        updateEmployer(input: $input) {
            employer {
                id
                firstName
                lastName
                phoneNumber
            }
        }
    }
`;
export type UpdateEmployerMutationFn = Apollo.MutationFunction<UpdateEmployerMutation, UpdateEmployerMutationVariables>;

/**
 * __useUpdateEmployerMutation__
 *
 * To run a mutation, you first call `useUpdateEmployerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployerMutation, { data, loading, error }] = useUpdateEmployerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployerMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateEmployerMutation, UpdateEmployerMutationVariables>,
) {
    return Apollo.useMutation<UpdateEmployerMutation, UpdateEmployerMutationVariables>(
        UpdateEmployerDocument,
        baseOptions,
    );
}
export type UpdateEmployerMutationHookResult = ReturnType<typeof useUpdateEmployerMutation>;
export type UpdateEmployerMutationResult = Apollo.MutationResult<UpdateEmployerMutation>;
export type UpdateEmployerMutationOptions = Apollo.BaseMutationOptions<
    UpdateEmployerMutation,
    UpdateEmployerMutationVariables
>;
export const UpdatePricingPlanDocument = gql`
    mutation UpdatePricingPlan($pricingPlanId: String!) {
        updatePricingPlan(pricingPlanId: $pricingPlanId)
    }
`;
export type UpdatePricingPlanMutationFn = Apollo.MutationFunction<
    UpdatePricingPlanMutation,
    UpdatePricingPlanMutationVariables
>;

/**
 * __useUpdatePricingPlanMutation__
 *
 * To run a mutation, you first call `useUpdatePricingPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePricingPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePricingPlanMutation, { data, loading, error }] = useUpdatePricingPlanMutation({
 *   variables: {
 *      pricingPlanId: // value for 'pricingPlanId'
 *   },
 * });
 */
export function useUpdatePricingPlanMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdatePricingPlanMutation, UpdatePricingPlanMutationVariables>,
) {
    return Apollo.useMutation<UpdatePricingPlanMutation, UpdatePricingPlanMutationVariables>(
        UpdatePricingPlanDocument,
        baseOptions,
    );
}
export type UpdatePricingPlanMutationHookResult = ReturnType<typeof useUpdatePricingPlanMutation>;
export type UpdatePricingPlanMutationResult = Apollo.MutationResult<UpdatePricingPlanMutation>;
export type UpdatePricingPlanMutationOptions = Apollo.BaseMutationOptions<
    UpdatePricingPlanMutation,
    UpdatePricingPlanMutationVariables
>;
export const ApplyToJobDocument = gql`
    mutation ApplyToJob($jobId: String!, $coverLetter: String) {
        applyToJob(application: { jobId: $jobId, coverLetter: $coverLetter }) {
            application {
                id
                applicantId
            }
        }
    }
`;
export type ApplyToJobMutationFn = Apollo.MutationFunction<ApplyToJobMutation, ApplyToJobMutationVariables>;

/**
 * __useApplyToJobMutation__
 *
 * To run a mutation, you first call `useApplyToJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyToJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyToJobMutation, { data, loading, error }] = useApplyToJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      coverLetter: // value for 'coverLetter'
 *   },
 * });
 */
export function useApplyToJobMutation(
    baseOptions?: Apollo.MutationHookOptions<ApplyToJobMutation, ApplyToJobMutationVariables>,
) {
    return Apollo.useMutation<ApplyToJobMutation, ApplyToJobMutationVariables>(ApplyToJobDocument, baseOptions);
}
export type ApplyToJobMutationHookResult = ReturnType<typeof useApplyToJobMutation>;
export type ApplyToJobMutationResult = Apollo.MutationResult<ApplyToJobMutation>;
export type ApplyToJobMutationOptions = Apollo.BaseMutationOptions<ApplyToJobMutation, ApplyToJobMutationVariables>;
export const BookmarkJobDocument = gql`
    mutation BookmarkJob($jobId: String!) {
        bookmarkJob(bookmarkJob: { jobId: $jobId }) {
            job {
                id
                bookmarked
            }
        }
    }
`;
export type BookmarkJobMutationFn = Apollo.MutationFunction<BookmarkJobMutation, BookmarkJobMutationVariables>;

/**
 * __useBookmarkJobMutation__
 *
 * To run a mutation, you first call `useBookmarkJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookmarkJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookmarkJobMutation, { data, loading, error }] = useBookmarkJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useBookmarkJobMutation(
    baseOptions?: Apollo.MutationHookOptions<BookmarkJobMutation, BookmarkJobMutationVariables>,
) {
    return Apollo.useMutation<BookmarkJobMutation, BookmarkJobMutationVariables>(BookmarkJobDocument, baseOptions);
}
export type BookmarkJobMutationHookResult = ReturnType<typeof useBookmarkJobMutation>;
export type BookmarkJobMutationResult = Apollo.MutationResult<BookmarkJobMutation>;
export type BookmarkJobMutationOptions = Apollo.BaseMutationOptions<BookmarkJobMutation, BookmarkJobMutationVariables>;
export const ReactivateJobDocument = gql`
    mutation reactivateJob($job: ReactivateJobInput!) {
        reactivateJob(job: $job) {
            job {
                id
            }
        }
    }
`;
export type ReactivateJobMutationFn = Apollo.MutationFunction<ReactivateJobMutation, ReactivateJobMutationVariables>;

/**
 * __useReactivateJobMutation__
 *
 * To run a mutation, you first call `useReactivateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateJobMutation, { data, loading, error }] = useReactivateJobMutation({
 *   variables: {
 *      job: // value for 'job'
 *   },
 * });
 */
export function useReactivateJobMutation(
    baseOptions?: Apollo.MutationHookOptions<ReactivateJobMutation, ReactivateJobMutationVariables>,
) {
    return Apollo.useMutation<ReactivateJobMutation, ReactivateJobMutationVariables>(
        ReactivateJobDocument,
        baseOptions,
    );
}
export type ReactivateJobMutationHookResult = ReturnType<typeof useReactivateJobMutation>;
export type ReactivateJobMutationResult = Apollo.MutationResult<ReactivateJobMutation>;
export type ReactivateJobMutationOptions = Apollo.BaseMutationOptions<
    ReactivateJobMutation,
    ReactivateJobMutationVariables
>;
export const ViewJobDocument = gql`
    mutation ViewJob($jobId: String!) {
        viewJob(viewJob: { jobId: $jobId }) {
            viewCount
        }
    }
`;
export type ViewJobMutationFn = Apollo.MutationFunction<ViewJobMutation, ViewJobMutationVariables>;

/**
 * __useViewJobMutation__
 *
 * To run a mutation, you first call `useViewJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewJobMutation, { data, loading, error }] = useViewJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useViewJobMutation(
    baseOptions?: Apollo.MutationHookOptions<ViewJobMutation, ViewJobMutationVariables>,
) {
    return Apollo.useMutation<ViewJobMutation, ViewJobMutationVariables>(ViewJobDocument, baseOptions);
}
export type ViewJobMutationHookResult = ReturnType<typeof useViewJobMutation>;
export type ViewJobMutationResult = Apollo.MutationResult<ViewJobMutation>;
export type ViewJobMutationOptions = Apollo.BaseMutationOptions<ViewJobMutation, ViewJobMutationVariables>;
export const AcceptApplicationInviteDocument = gql`
    mutation AcceptApplicationInvite($input: AcceptApplicationInviteInput!) {
        acceptApplicationInvite(input: $input) {
            applicationInvite {
                id
            }
        }
    }
`;
export type AcceptApplicationInviteMutationFn = Apollo.MutationFunction<
    AcceptApplicationInviteMutation,
    AcceptApplicationInviteMutationVariables
>;

/**
 * __useAcceptApplicationInviteMutation__
 *
 * To run a mutation, you first call `useAcceptApplicationInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptApplicationInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptApplicationInviteMutation, { data, loading, error }] = useAcceptApplicationInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptApplicationInviteMutation(
    baseOptions?: Apollo.MutationHookOptions<AcceptApplicationInviteMutation, AcceptApplicationInviteMutationVariables>,
) {
    return Apollo.useMutation<AcceptApplicationInviteMutation, AcceptApplicationInviteMutationVariables>(
        AcceptApplicationInviteDocument,
        baseOptions,
    );
}
export type AcceptApplicationInviteMutationHookResult = ReturnType<typeof useAcceptApplicationInviteMutation>;
export type AcceptApplicationInviteMutationResult = Apollo.MutationResult<AcceptApplicationInviteMutation>;
export type AcceptApplicationInviteMutationOptions = Apollo.BaseMutationOptions<
    AcceptApplicationInviteMutation,
    AcceptApplicationInviteMutationVariables
>;
export const AcceptOfferDocument = gql`
    mutation AcceptOffer($applicationId: String!) {
        acceptOffer(applicationId: $applicationId) {
            application {
                id
                stage {
                    id
                    status
                    color
                }
                statusHistories {
                    edges {
                        node {
                            id
                            reason
                        }
                    }
                }
            }
        }
    }
`;
export type AcceptOfferMutationFn = Apollo.MutationFunction<AcceptOfferMutation, AcceptOfferMutationVariables>;

/**
 * __useAcceptOfferMutation__
 *
 * To run a mutation, you first call `useAcceptOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptOfferMutation, { data, loading, error }] = useAcceptOfferMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useAcceptOfferMutation(
    baseOptions?: Apollo.MutationHookOptions<AcceptOfferMutation, AcceptOfferMutationVariables>,
) {
    return Apollo.useMutation<AcceptOfferMutation, AcceptOfferMutationVariables>(AcceptOfferDocument, baseOptions);
}
export type AcceptOfferMutationHookResult = ReturnType<typeof useAcceptOfferMutation>;
export type AcceptOfferMutationResult = Apollo.MutationResult<AcceptOfferMutation>;
export type AcceptOfferMutationOptions = Apollo.BaseMutationOptions<AcceptOfferMutation, AcceptOfferMutationVariables>;
export const CreateSavedSearchDocument = gql`
    mutation CreateSavedSearch($search: CreateSavedSearchInput!) {
        saveSearch(search: $search) {
            savedSearch {
                id
            }
        }
    }
`;
export type CreateSavedSearchMutationFn = Apollo.MutationFunction<
    CreateSavedSearchMutation,
    CreateSavedSearchMutationVariables
>;

/**
 * __useCreateSavedSearchMutation__
 *
 * To run a mutation, you first call `useCreateSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSavedSearchMutation, { data, loading, error }] = useCreateSavedSearchMutation({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCreateSavedSearchMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateSavedSearchMutation, CreateSavedSearchMutationVariables>,
) {
    return Apollo.useMutation<CreateSavedSearchMutation, CreateSavedSearchMutationVariables>(
        CreateSavedSearchDocument,
        baseOptions,
    );
}
export type CreateSavedSearchMutationHookResult = ReturnType<typeof useCreateSavedSearchMutation>;
export type CreateSavedSearchMutationResult = Apollo.MutationResult<CreateSavedSearchMutation>;
export type CreateSavedSearchMutationOptions = Apollo.BaseMutationOptions<
    CreateSavedSearchMutation,
    CreateSavedSearchMutationVariables
>;
export const DeleteResumeDocument = gql`
    mutation DeleteResume($id: String!) {
        deleteResume(id: $id) {
            id
        }
    }
`;
export type DeleteResumeMutationFn = Apollo.MutationFunction<DeleteResumeMutation, DeleteResumeMutationVariables>;

/**
 * __useDeleteResumeMutation__
 *
 * To run a mutation, you first call `useDeleteResumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResumeMutation, { data, loading, error }] = useDeleteResumeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteResumeMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteResumeMutation, DeleteResumeMutationVariables>,
) {
    return Apollo.useMutation<DeleteResumeMutation, DeleteResumeMutationVariables>(DeleteResumeDocument, baseOptions);
}
export type DeleteResumeMutationHookResult = ReturnType<typeof useDeleteResumeMutation>;
export type DeleteResumeMutationResult = Apollo.MutationResult<DeleteResumeMutation>;
export type DeleteResumeMutationOptions = Apollo.BaseMutationOptions<
    DeleteResumeMutation,
    DeleteResumeMutationVariables
>;
export const DeleteSavedSearchDocument = gql`
    mutation DeleteSavedSearch($id: String!) {
        deleteSavedSearch(id: $id) {
            id
        }
    }
`;
export type DeleteSavedSearchMutationFn = Apollo.MutationFunction<
    DeleteSavedSearchMutation,
    DeleteSavedSearchMutationVariables
>;

/**
 * __useDeleteSavedSearchMutation__
 *
 * To run a mutation, you first call `useDeleteSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSavedSearchMutation, { data, loading, error }] = useDeleteSavedSearchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSavedSearchMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteSavedSearchMutation, DeleteSavedSearchMutationVariables>,
) {
    return Apollo.useMutation<DeleteSavedSearchMutation, DeleteSavedSearchMutationVariables>(
        DeleteSavedSearchDocument,
        baseOptions,
    );
}
export type DeleteSavedSearchMutationHookResult = ReturnType<typeof useDeleteSavedSearchMutation>;
export type DeleteSavedSearchMutationResult = Apollo.MutationResult<DeleteSavedSearchMutation>;
export type DeleteSavedSearchMutationOptions = Apollo.BaseMutationOptions<
    DeleteSavedSearchMutation,
    DeleteSavedSearchMutationVariables
>;
export const RejectApplicationInviteDocument = gql`
    mutation RejectApplicationInvite($input: RejectApplicationInviteInput!) {
        rejectApplicationInvite(input: $input) {
            applicationInvite {
                id
            }
        }
    }
`;
export type RejectApplicationInviteMutationFn = Apollo.MutationFunction<
    RejectApplicationInviteMutation,
    RejectApplicationInviteMutationVariables
>;

/**
 * __useRejectApplicationInviteMutation__
 *
 * To run a mutation, you first call `useRejectApplicationInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectApplicationInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectApplicationInviteMutation, { data, loading, error }] = useRejectApplicationInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectApplicationInviteMutation(
    baseOptions?: Apollo.MutationHookOptions<RejectApplicationInviteMutation, RejectApplicationInviteMutationVariables>,
) {
    return Apollo.useMutation<RejectApplicationInviteMutation, RejectApplicationInviteMutationVariables>(
        RejectApplicationInviteDocument,
        baseOptions,
    );
}
export type RejectApplicationInviteMutationHookResult = ReturnType<typeof useRejectApplicationInviteMutation>;
export type RejectApplicationInviteMutationResult = Apollo.MutationResult<RejectApplicationInviteMutation>;
export type RejectApplicationInviteMutationOptions = Apollo.BaseMutationOptions<
    RejectApplicationInviteMutation,
    RejectApplicationInviteMutationVariables
>;
export const RejectOfferDocument = gql`
    mutation RejectOffer($input: RejectOfferInput!) {
        rejectOffer(input: $input) {
            application {
                id
                stage {
                    id
                    status
                    color
                }
                statusHistories {
                    edges {
                        node {
                            id
                            reason
                        }
                    }
                }
            }
        }
    }
`;
export type RejectOfferMutationFn = Apollo.MutationFunction<RejectOfferMutation, RejectOfferMutationVariables>;

/**
 * __useRejectOfferMutation__
 *
 * To run a mutation, you first call `useRejectOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectOfferMutation, { data, loading, error }] = useRejectOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectOfferMutation(
    baseOptions?: Apollo.MutationHookOptions<RejectOfferMutation, RejectOfferMutationVariables>,
) {
    return Apollo.useMutation<RejectOfferMutation, RejectOfferMutationVariables>(RejectOfferDocument, baseOptions);
}
export type RejectOfferMutationHookResult = ReturnType<typeof useRejectOfferMutation>;
export type RejectOfferMutationResult = Apollo.MutationResult<RejectOfferMutation>;
export type RejectOfferMutationOptions = Apollo.BaseMutationOptions<RejectOfferMutation, RejectOfferMutationVariables>;
export const UpdateJobseekerAvatarDocument = gql`
    mutation UpdateJobseekerAvatar($input: UploadJobseekerAvatarInput!) {
        updateJobseekerAvatar(input: $input) {
            id
            avatar {
                id
                url
            }
        }
    }
`;
export type UpdateJobseekerAvatarMutationFn = Apollo.MutationFunction<
    UpdateJobseekerAvatarMutation,
    UpdateJobseekerAvatarMutationVariables
>;

/**
 * __useUpdateJobseekerAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateJobseekerAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobseekerAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobseekerAvatarMutation, { data, loading, error }] = useUpdateJobseekerAvatarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJobseekerAvatarMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateJobseekerAvatarMutation, UpdateJobseekerAvatarMutationVariables>,
) {
    return Apollo.useMutation<UpdateJobseekerAvatarMutation, UpdateJobseekerAvatarMutationVariables>(
        UpdateJobseekerAvatarDocument,
        baseOptions,
    );
}
export type UpdateJobseekerAvatarMutationHookResult = ReturnType<typeof useUpdateJobseekerAvatarMutation>;
export type UpdateJobseekerAvatarMutationResult = Apollo.MutationResult<UpdateJobseekerAvatarMutation>;
export type UpdateJobseekerAvatarMutationOptions = Apollo.BaseMutationOptions<
    UpdateJobseekerAvatarMutation,
    UpdateJobseekerAvatarMutationVariables
>;
export const UpdateJobseekerProfileDocument = gql`
    mutation UpdateJobseekerProfile($profile: UpdateJobseekerProfileInput!) {
        updateJobseekerProfile(profile: $profile) {
            profile {
                firstName
                middleName
                lastName
                gender
                dateOfBirth
                placeOfBirth
                regionId
                categoryId
                address {
                    location {
                        longitude
                        latitude
                    }
                    building
                    street
                    description
                    metroStations {
                        name
                        location {
                            longitude
                            latitude
                        }
                    }
                }
                contacts {
                    emails
                    phones {
                        number
                        comment
                    }
                    linkedInUrl
                    skypeAddress
                    facebookUrl
                    twitterUrl
                    githubUrl
                }
                keySkills
                languages {
                    edges {
                        node {
                            id
                            languageId
                            level
                        }
                    }
                }
                educations {
                    edges {
                        node {
                            id
                            school
                            location
                            level
                            field
                            startDate
                            endDate
                            ongoing
                            description
                        }
                    }
                }
                experiences {
                    edges {
                        node {
                            id
                            company
                            position
                            startDate
                            endDate
                            ongoing
                            description
                        }
                    }
                }
            }
        }
    }
`;
export type UpdateJobseekerProfileMutationFn = Apollo.MutationFunction<
    UpdateJobseekerProfileMutation,
    UpdateJobseekerProfileMutationVariables
>;

/**
 * __useUpdateJobseekerProfileMutation__
 *
 * To run a mutation, you first call `useUpdateJobseekerProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobseekerProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobseekerProfileMutation, { data, loading, error }] = useUpdateJobseekerProfileMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useUpdateJobseekerProfileMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateJobseekerProfileMutation, UpdateJobseekerProfileMutationVariables>,
) {
    return Apollo.useMutation<UpdateJobseekerProfileMutation, UpdateJobseekerProfileMutationVariables>(
        UpdateJobseekerProfileDocument,
        baseOptions,
    );
}
export type UpdateJobseekerProfileMutationHookResult = ReturnType<typeof useUpdateJobseekerProfileMutation>;
export type UpdateJobseekerProfileMutationResult = Apollo.MutationResult<UpdateJobseekerProfileMutation>;
export type UpdateJobseekerProfileMutationOptions = Apollo.BaseMutationOptions<
    UpdateJobseekerProfileMutation,
    UpdateJobseekerProfileMutationVariables
>;
export const UpdateSavedSearchDocument = gql`
    mutation UpdateSavedSearch($search: UpdateSavedSearchInput!) {
        updateSavedSearch(search: $search) {
            savedSearch {
                id
                name
                alertPeriod
            }
        }
    }
`;
export type UpdateSavedSearchMutationFn = Apollo.MutationFunction<
    UpdateSavedSearchMutation,
    UpdateSavedSearchMutationVariables
>;

/**
 * __useUpdateSavedSearchMutation__
 *
 * To run a mutation, you first call `useUpdateSavedSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSavedSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSavedSearchMutation, { data, loading, error }] = useUpdateSavedSearchMutation({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useUpdateSavedSearchMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateSavedSearchMutation, UpdateSavedSearchMutationVariables>,
) {
    return Apollo.useMutation<UpdateSavedSearchMutation, UpdateSavedSearchMutationVariables>(
        UpdateSavedSearchDocument,
        baseOptions,
    );
}
export type UpdateSavedSearchMutationHookResult = ReturnType<typeof useUpdateSavedSearchMutation>;
export type UpdateSavedSearchMutationResult = Apollo.MutationResult<UpdateSavedSearchMutation>;
export type UpdateSavedSearchMutationOptions = Apollo.BaseMutationOptions<
    UpdateSavedSearchMutation,
    UpdateSavedSearchMutationVariables
>;
export const UploadResumeDocument = gql`
    mutation UploadResume($input: UploadResumeInput!) {
        uploadResume(input: $input) {
            name
        }
    }
`;
export type UploadResumeMutationFn = Apollo.MutationFunction<UploadResumeMutation, UploadResumeMutationVariables>;

/**
 * __useUploadResumeMutation__
 *
 * To run a mutation, you first call `useUploadResumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadResumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadResumeMutation, { data, loading, error }] = useUploadResumeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadResumeMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadResumeMutation, UploadResumeMutationVariables>,
) {
    return Apollo.useMutation<UploadResumeMutation, UploadResumeMutationVariables>(UploadResumeDocument, baseOptions);
}
export type UploadResumeMutationHookResult = ReturnType<typeof useUploadResumeMutation>;
export type UploadResumeMutationResult = Apollo.MutationResult<UploadResumeMutation>;
export type UploadResumeMutationOptions = Apollo.BaseMutationOptions<
    UploadResumeMutation,
    UploadResumeMutationVariables
>;
export const RegisterDeviceDocument = gql`
    mutation registerDevice($input: RegisterDeviceInput!) {
        registerDevice(input: $input)
    }
`;
export type RegisterDeviceMutationFn = Apollo.MutationFunction<RegisterDeviceMutation, RegisterDeviceMutationVariables>;

/**
 * __useRegisterDeviceMutation__
 *
 * To run a mutation, you first call `useRegisterDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerDeviceMutation, { data, loading, error }] = useRegisterDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterDeviceMutation(
    baseOptions?: Apollo.MutationHookOptions<RegisterDeviceMutation, RegisterDeviceMutationVariables>,
) {
    return Apollo.useMutation<RegisterDeviceMutation, RegisterDeviceMutationVariables>(
        RegisterDeviceDocument,
        baseOptions,
    );
}
export type RegisterDeviceMutationHookResult = ReturnType<typeof useRegisterDeviceMutation>;
export type RegisterDeviceMutationResult = Apollo.MutationResult<RegisterDeviceMutation>;
export type RegisterDeviceMutationOptions = Apollo.BaseMutationOptions<
    RegisterDeviceMutation,
    RegisterDeviceMutationVariables
>;
export const UpdateCalendarEventDocument = gql`
    mutation UpdateCalendarEvent($calendarEvent: UpdateCalendarEventInput!) {
        updateCalendarEvent(calendarEvent: $calendarEvent) {
            calendarEvent {
                id
                title
                start
                end
                duration
                type
            }
        }
    }
`;
export type UpdateCalendarEventMutationFn = Apollo.MutationFunction<
    UpdateCalendarEventMutation,
    UpdateCalendarEventMutationVariables
>;

/**
 * __useUpdateCalendarEventMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarEventMutation, { data, loading, error }] = useUpdateCalendarEventMutation({
 *   variables: {
 *      calendarEvent: // value for 'calendarEvent'
 *   },
 * });
 */
export function useUpdateCalendarEventMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateCalendarEventMutation, UpdateCalendarEventMutationVariables>,
) {
    return Apollo.useMutation<UpdateCalendarEventMutation, UpdateCalendarEventMutationVariables>(
        UpdateCalendarEventDocument,
        baseOptions,
    );
}
export type UpdateCalendarEventMutationHookResult = ReturnType<typeof useUpdateCalendarEventMutation>;
export type UpdateCalendarEventMutationResult = Apollo.MutationResult<UpdateCalendarEventMutation>;
export type UpdateCalendarEventMutationOptions = Apollo.BaseMutationOptions<
    UpdateCalendarEventMutation,
    UpdateCalendarEventMutationVariables
>;
export const UpdateUserSettingDocument = gql`
    mutation UpdateUserSetting($input: UpdateUserSettingInput!) {
        updateUserSetting(input: $input) {
            result
        }
    }
`;
export type UpdateUserSettingMutationFn = Apollo.MutationFunction<
    UpdateUserSettingMutation,
    UpdateUserSettingMutationVariables
>;

/**
 * __useUpdateUserSettingMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingMutation, { data, loading, error }] = useUpdateUserSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserSettingMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateUserSettingMutation, UpdateUserSettingMutationVariables>,
) {
    return Apollo.useMutation<UpdateUserSettingMutation, UpdateUserSettingMutationVariables>(
        UpdateUserSettingDocument,
        baseOptions,
    );
}
export type UpdateUserSettingMutationHookResult = ReturnType<typeof useUpdateUserSettingMutation>;
export type UpdateUserSettingMutationResult = Apollo.MutationResult<UpdateUserSettingMutation>;
export type UpdateUserSettingMutationOptions = Apollo.BaseMutationOptions<
    UpdateUserSettingMutation,
    UpdateUserSettingMutationVariables
>;
export const UpdateUserSettingsDocument = gql`
    mutation UpdateUserSettings($input: UpdateUserSettingsInput!) {
        updateUserSettings(input: $input) {
            result
        }
    }
`;
export type UpdateUserSettingsMutationFn = Apollo.MutationFunction<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>,
) {
    return Apollo.useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(
        UpdateUserSettingsDocument,
        baseOptions,
    );
}
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = Apollo.MutationResult<UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
>;
export const UploadImageDocument = gql`
    mutation UploadImage($input: UploadImageInput!) {
        uploadImage(input: $input)
    }
`;
export type UploadImageMutationFn = Apollo.MutationFunction<UploadImageMutation, UploadImageMutationVariables>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadImageMutation(
    baseOptions?: Apollo.MutationHookOptions<UploadImageMutation, UploadImageMutationVariables>,
) {
    return Apollo.useMutation<UploadImageMutation, UploadImageMutationVariables>(UploadImageDocument, baseOptions);
}
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = Apollo.MutationResult<UploadImageMutation>;
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<UploadImageMutation, UploadImageMutationVariables>;
export const AllBlogPostingsDocument = gql`
    query AllBlogPostings {
        blogPosts {
            edges {
                node {
                    id
                    title
                    body
                    slug
                    createdAt
                    coverImage {
                        id
                        url
                    }
                }
            }
        }
    }
`;

/**
 * __useAllBlogPostingsQuery__
 *
 * To run a query within a React component, call `useAllBlogPostingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBlogPostingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBlogPostingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllBlogPostingsQuery(
    baseOptions?: Apollo.QueryHookOptions<AllBlogPostingsQuery, AllBlogPostingsQueryVariables>,
) {
    return Apollo.useQuery<AllBlogPostingsQuery, AllBlogPostingsQueryVariables>(AllBlogPostingsDocument, baseOptions);
}
export function useAllBlogPostingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AllBlogPostingsQuery, AllBlogPostingsQueryVariables>,
) {
    return Apollo.useLazyQuery<AllBlogPostingsQuery, AllBlogPostingsQueryVariables>(
        AllBlogPostingsDocument,
        baseOptions,
    );
}
export type AllBlogPostingsQueryHookResult = ReturnType<typeof useAllBlogPostingsQuery>;
export type AllBlogPostingsLazyQueryHookResult = ReturnType<typeof useAllBlogPostingsLazyQuery>;
export type AllBlogPostingsQueryResult = Apollo.QueryResult<AllBlogPostingsQuery, AllBlogPostingsQueryVariables>;
export const BlogPostDocument = gql`
    query BlogPost($id: String!) {
        blogPost(id: $id) {
            id
            title
            slug
            body
            tags
            coverImage {
                id
                url
            }
        }
    }
`;

/**
 * __useBlogPostQuery__
 *
 * To run a query within a React component, call `useBlogPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogPostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBlogPostQuery(baseOptions: Apollo.QueryHookOptions<BlogPostQuery, BlogPostQueryVariables>) {
    return Apollo.useQuery<BlogPostQuery, BlogPostQueryVariables>(BlogPostDocument, baseOptions);
}
export function useBlogPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlogPostQuery, BlogPostQueryVariables>) {
    return Apollo.useLazyQuery<BlogPostQuery, BlogPostQueryVariables>(BlogPostDocument, baseOptions);
}
export type BlogPostQueryHookResult = ReturnType<typeof useBlogPostQuery>;
export type BlogPostLazyQueryHookResult = ReturnType<typeof useBlogPostLazyQuery>;
export type BlogPostQueryResult = Apollo.QueryResult<BlogPostQuery, BlogPostQueryVariables>;
export const RegisteredCompaniesDocument = gql`
    query RegisteredCompanies($page: Int, $where: CompanyWhereInput, $orderBy: CompanyOrderBy) {
        registeredCompanies(page: $page, where: $where, orderBy: $orderBy) {
            totalCount
            pageInfo {
                totalPages
            }
            edges {
                node {
                    id
                    name
                    type
                    region {
                        id
                        name
                    }
                    jobs {
                        totalCount
                    }
                    logo {
                        id
                        url
                    }
                    slug
                    verified
                    createdAt
                    currentPricingPlan {
                        plan {
                            id
                            name
                        }
                        startsAt
                        expiresAt
                    }
                }
            }
        }
    }
`;

/**
 * __useRegisteredCompaniesQuery__
 *
 * To run a query within a React component, call `useRegisteredCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisteredCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisteredCompaniesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useRegisteredCompaniesQuery(
    baseOptions?: Apollo.QueryHookOptions<RegisteredCompaniesQuery, RegisteredCompaniesQueryVariables>,
) {
    return Apollo.useQuery<RegisteredCompaniesQuery, RegisteredCompaniesQueryVariables>(
        RegisteredCompaniesDocument,
        baseOptions,
    );
}
export function useRegisteredCompaniesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<RegisteredCompaniesQuery, RegisteredCompaniesQueryVariables>,
) {
    return Apollo.useLazyQuery<RegisteredCompaniesQuery, RegisteredCompaniesQueryVariables>(
        RegisteredCompaniesDocument,
        baseOptions,
    );
}
export type RegisteredCompaniesQueryHookResult = ReturnType<typeof useRegisteredCompaniesQuery>;
export type RegisteredCompaniesLazyQueryHookResult = ReturnType<typeof useRegisteredCompaniesLazyQuery>;
export type RegisteredCompaniesQueryResult = Apollo.QueryResult<
    RegisteredCompaniesQuery,
    RegisteredCompaniesQueryVariables
>;
export const AllJobPostingsDocument = gql`
    query AllJobPostings($page: Int, $where: JobForAdminWhereInput, $orderBy: JobOrderBy) {
        allJobPostings(page: $page, where: $where, orderBy: $orderBy) {
            totalCount
            pageInfo {
                totalPages
            }
            edges {
                node {
                    id
                    slug
                    title
                    viewCount
                    status
                    expiresAt
                    createdAt
                    publishedAt
                    company {
                        id
                        name
                        slug
                    }
                    applications {
                        totalCount
                    }
                }
            }
        }
    }
`;

/**
 * __useAllJobPostingsQuery__
 *
 * To run a query within a React component, call `useAllJobPostingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllJobPostingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllJobPostingsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAllJobPostingsQuery(
    baseOptions?: Apollo.QueryHookOptions<AllJobPostingsQuery, AllJobPostingsQueryVariables>,
) {
    return Apollo.useQuery<AllJobPostingsQuery, AllJobPostingsQueryVariables>(AllJobPostingsDocument, baseOptions);
}
export function useAllJobPostingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AllJobPostingsQuery, AllJobPostingsQueryVariables>,
) {
    return Apollo.useLazyQuery<AllJobPostingsQuery, AllJobPostingsQueryVariables>(AllJobPostingsDocument, baseOptions);
}
export type AllJobPostingsQueryHookResult = ReturnType<typeof useAllJobPostingsQuery>;
export type AllJobPostingsLazyQueryHookResult = ReturnType<typeof useAllJobPostingsLazyQuery>;
export type AllJobPostingsQueryResult = Apollo.QueryResult<AllJobPostingsQuery, AllJobPostingsQueryVariables>;
export const PaymentsDocument = gql`
    query payments($page: Int, $where: PaymentWhereInput, $orderBy: PaymentOrderBy) {
        payments(page: $page, where: $where, orderBy: $orderBy) {
            totalCount
            pageInfo {
                totalPages
            }
            edges {
                node {
                    id
                    firstName
                    lastName
                    companyName
                    price
                    status
                    date
                    companySlug
                    companyId
                }
            }
        }
    }
`;

/**
 * __usePaymentsQuery__
 *
 * To run a query within a React component, call `usePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function usePaymentsQuery(baseOptions?: Apollo.QueryHookOptions<PaymentsQuery, PaymentsQueryVariables>) {
    return Apollo.useQuery<PaymentsQuery, PaymentsQueryVariables>(PaymentsDocument, baseOptions);
}
export function usePaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentsQuery, PaymentsQueryVariables>) {
    return Apollo.useLazyQuery<PaymentsQuery, PaymentsQueryVariables>(PaymentsDocument, baseOptions);
}
export type PaymentsQueryHookResult = ReturnType<typeof usePaymentsQuery>;
export type PaymentsLazyQueryHookResult = ReturnType<typeof usePaymentsLazyQuery>;
export type PaymentsQueryResult = Apollo.QueryResult<PaymentsQuery, PaymentsQueryVariables>;
export const BlogPostDetailDocument = gql`
    query BlogPostDetail($slug: String!) {
        blogPost(slug: $slug) {
            ...BlogPostParts
            relatedBlogPosts {
                edges {
                    node {
                        ...BlogPostParts
                    }
                }
            }
        }
    }
    ${BlogPostPartsFragmentDoc}
`;

/**
 * __useBlogPostDetailQuery__
 *
 * To run a query within a React component, call `useBlogPostDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogPostDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogPostDetailQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useBlogPostDetailQuery(
    baseOptions: Apollo.QueryHookOptions<BlogPostDetailQuery, BlogPostDetailQueryVariables>,
) {
    return Apollo.useQuery<BlogPostDetailQuery, BlogPostDetailQueryVariables>(BlogPostDetailDocument, baseOptions);
}
export function useBlogPostDetailLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<BlogPostDetailQuery, BlogPostDetailQueryVariables>,
) {
    return Apollo.useLazyQuery<BlogPostDetailQuery, BlogPostDetailQueryVariables>(BlogPostDetailDocument, baseOptions);
}
export type BlogPostDetailQueryHookResult = ReturnType<typeof useBlogPostDetailQuery>;
export type BlogPostDetailLazyQueryHookResult = ReturnType<typeof useBlogPostDetailLazyQuery>;
export type BlogPostDetailQueryResult = Apollo.QueryResult<BlogPostDetailQuery, BlogPostDetailQueryVariables>;
export const BlogPostsDocument = gql`
    query BlogPosts($page: Int, $where: BlogPostWhereInput, $orderBy: BlogPostOrderBy) {
        blogPosts(page: $page, where: $where, orderBy: $orderBy) {
            totalCount
            pageInfo {
                totalPages
            }
            edges {
                node {
                    ...BlogPostParts
                }
            }
        }
    }
    ${BlogPostPartsFragmentDoc}
`;

/**
 * __useBlogPostsQuery__
 *
 * To run a query within a React component, call `useBlogPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogPostsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useBlogPostsQuery(baseOptions?: Apollo.QueryHookOptions<BlogPostsQuery, BlogPostsQueryVariables>) {
    return Apollo.useQuery<BlogPostsQuery, BlogPostsQueryVariables>(BlogPostsDocument, baseOptions);
}
export function useBlogPostsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<BlogPostsQuery, BlogPostsQueryVariables>,
) {
    return Apollo.useLazyQuery<BlogPostsQuery, BlogPostsQueryVariables>(BlogPostsDocument, baseOptions);
}
export type BlogPostsQueryHookResult = ReturnType<typeof useBlogPostsQuery>;
export type BlogPostsLazyQueryHookResult = ReturnType<typeof useBlogPostsLazyQuery>;
export type BlogPostsQueryResult = Apollo.QueryResult<BlogPostsQuery, BlogPostsQueryVariables>;
export const CalendarEventsDocument = gql`
    query calendarEvents($where: CalendarEventWhereInput!) {
        calendarEvents(where: $where) {
            totalCount
            edges {
                node {
                    id
                    start
                    end
                    date
                    title
                    duration
                    type
                }
            }
        }
    }
`;

/**
 * __useCalendarEventsQuery__
 *
 * To run a query within a React component, call `useCalendarEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCalendarEventsQuery(
    baseOptions: Apollo.QueryHookOptions<CalendarEventsQuery, CalendarEventsQueryVariables>,
) {
    return Apollo.useQuery<CalendarEventsQuery, CalendarEventsQueryVariables>(CalendarEventsDocument, baseOptions);
}
export function useCalendarEventsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CalendarEventsQuery, CalendarEventsQueryVariables>,
) {
    return Apollo.useLazyQuery<CalendarEventsQuery, CalendarEventsQueryVariables>(CalendarEventsDocument, baseOptions);
}
export type CalendarEventsQueryHookResult = ReturnType<typeof useCalendarEventsQuery>;
export type CalendarEventsLazyQueryHookResult = ReturnType<typeof useCalendarEventsLazyQuery>;
export type CalendarEventsQueryResult = Apollo.QueryResult<CalendarEventsQuery, CalendarEventsQueryVariables>;
export const CategoryListDocument = gql`
    query CategoryList($whereInput: CategoryWhereInput) {
        categories(where: $whereInput) {
            totalCount
            edges {
                node {
                    id
                    order
                    name
                }
            }
        }
    }
`;

/**
 * __useCategoryListQuery__
 *
 * To run a query within a React component, call `useCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryListQuery({
 *   variables: {
 *      whereInput: // value for 'whereInput'
 *   },
 * });
 */
export function useCategoryListQuery(
    baseOptions?: Apollo.QueryHookOptions<CategoryListQuery, CategoryListQueryVariables>,
) {
    return Apollo.useQuery<CategoryListQuery, CategoryListQueryVariables>(CategoryListDocument, baseOptions);
}
export function useCategoryListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CategoryListQuery, CategoryListQueryVariables>,
) {
    return Apollo.useLazyQuery<CategoryListQuery, CategoryListQueryVariables>(CategoryListDocument, baseOptions);
}
export type CategoryListQueryHookResult = ReturnType<typeof useCategoryListQuery>;
export type CategoryListLazyQueryHookResult = ReturnType<typeof useCategoryListLazyQuery>;
export type CategoryListQueryResult = Apollo.QueryResult<CategoryListQuery, CategoryListQueryVariables>;
export const ChatableUsersDocument = gql`
    query ChatableUsers($page: Int, $where: ChatableUsersWhereInput) {
        chatableUsers(page: $page, where: $where) {
            edges {
                node {
                    id
                    matrixAuth {
                        access_token
                        device_id
                        home_server
                        user_id
                    }
                }
            }
            pageInfo {
                hasNextPage
            }
        }
    }
`;

/**
 * __useChatableUsersQuery__
 *
 * To run a query within a React component, call `useChatableUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatableUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatableUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useChatableUsersQuery(
    baseOptions?: Apollo.QueryHookOptions<ChatableUsersQuery, ChatableUsersQueryVariables>,
) {
    return Apollo.useQuery<ChatableUsersQuery, ChatableUsersQueryVariables>(ChatableUsersDocument, baseOptions);
}
export function useChatableUsersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ChatableUsersQuery, ChatableUsersQueryVariables>,
) {
    return Apollo.useLazyQuery<ChatableUsersQuery, ChatableUsersQueryVariables>(ChatableUsersDocument, baseOptions);
}
export type ChatableUsersQueryHookResult = ReturnType<typeof useChatableUsersQuery>;
export type ChatableUsersLazyQueryHookResult = ReturnType<typeof useChatableUsersLazyQuery>;
export type ChatableUsersQueryResult = Apollo.QueryResult<ChatableUsersQuery, ChatableUsersQueryVariables>;
export const CompanyListDocument = gql`
    query CompanyList($where: CompanyWhereInput, $page: Int, $orderBy: CompanyOrderBy) {
        companies(where: $where, page: $page, orderBy: $orderBy) {
            totalCount
            pageInfo {
                totalPages
            }
            edges {
                node {
                    id
                    name
                    type
                    slug
                    logo {
                        id
                        url
                    }
                    verified
                    region {
                        id
                        name
                    }
                    jobs {
                        totalCount
                    }
                }
            }
        }
    }
`;

/**
 * __useCompanyListQuery__
 *
 * To run a query within a React component, call `useCompanyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCompanyListQuery(
    baseOptions?: Apollo.QueryHookOptions<CompanyListQuery, CompanyListQueryVariables>,
) {
    return Apollo.useQuery<CompanyListQuery, CompanyListQueryVariables>(CompanyListDocument, baseOptions);
}
export function useCompanyListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CompanyListQuery, CompanyListQueryVariables>,
) {
    return Apollo.useLazyQuery<CompanyListQuery, CompanyListQueryVariables>(CompanyListDocument, baseOptions);
}
export type CompanyListQueryHookResult = ReturnType<typeof useCompanyListQuery>;
export type CompanyListLazyQueryHookResult = ReturnType<typeof useCompanyListLazyQuery>;
export type CompanyListQueryResult = Apollo.QueryResult<CompanyListQuery, CompanyListQueryVariables>;
export const CompanyDocument = gql`
    query Company($id: String, $slug: String) {
        company(id: $id, slug: $slug) {
            id
            slug
            name
            logoId
            verified
            type
            website
            description
            employers {
                edges {
                    node {
                        id
                        firstName
                        lastName
                        phoneNumber
                        user {
                            id
                            email
                        }
                    }
                }
            }
            region {
                id
                name
            }
            logo {
                id
                url
            }
            jobs {
                edges {
                    node {
                        id
                        slug
                        title
                        employment
                        createdAt
                        publishedAt
                        applied
                        bookmarked
                        viewCount
                        source
                        salary {
                            from
                            to
                        }
                        company {
                            id
                            slug
                            logo {
                                id
                                url
                            }
                            name
                            verified
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions?: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
    return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, baseOptions);
}
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
    return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, baseOptions);
}
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const CurrentPlanDocument = gql`
    query CurrentPlan {
        currentPlan {
            id
            active
            startsAt
            expiresAt
            plan {
                id
                name
                order
                pricingType
                price
                discountPercentage
            }
            features {
                edges {
                    node {
                        id
                        enabled
                        code
                        limit
                    }
                }
            }
        }
    }
`;

/**
 * __useCurrentPlanQuery__
 *
 * To run a query within a React component, call `useCurrentPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentPlanQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentPlanQuery(
    baseOptions?: Apollo.QueryHookOptions<CurrentPlanQuery, CurrentPlanQueryVariables>,
) {
    return Apollo.useQuery<CurrentPlanQuery, CurrentPlanQueryVariables>(CurrentPlanDocument, baseOptions);
}
export function useCurrentPlanLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CurrentPlanQuery, CurrentPlanQueryVariables>,
) {
    return Apollo.useLazyQuery<CurrentPlanQuery, CurrentPlanQueryVariables>(CurrentPlanDocument, baseOptions);
}
export type CurrentPlanQueryHookResult = ReturnType<typeof useCurrentPlanQuery>;
export type CurrentPlanLazyQueryHookResult = ReturnType<typeof useCurrentPlanLazyQuery>;
export type CurrentPlanQueryResult = Apollo.QueryResult<CurrentPlanQuery, CurrentPlanQueryVariables>;
export const DashboardsDocument = gql`
    query Dashboards {
        dasboards {
            totalCount
            edges {
                node {
                    id
                    code
                    embedUrl
                }
            }
        }
    }
`;

/**
 * __useDashboardsQuery__
 *
 * To run a query within a React component, call `useDashboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardsQuery(baseOptions?: Apollo.QueryHookOptions<DashboardsQuery, DashboardsQueryVariables>) {
    return Apollo.useQuery<DashboardsQuery, DashboardsQueryVariables>(DashboardsDocument, baseOptions);
}
export function useDashboardsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<DashboardsQuery, DashboardsQueryVariables>,
) {
    return Apollo.useLazyQuery<DashboardsQuery, DashboardsQueryVariables>(DashboardsDocument, baseOptions);
}
export type DashboardsQueryHookResult = ReturnType<typeof useDashboardsQuery>;
export type DashboardsLazyQueryHookResult = ReturnType<typeof useDashboardsLazyQuery>;
export type DashboardsQueryResult = Apollo.QueryResult<DashboardsQuery, DashboardsQueryVariables>;
export const CandidateDocument = gql`
    query Candidate($id: String!) {
        candidate(id: $id) {
            profile {
                id
                firstName
                lastName
                middleName
                gender
                dateOfBirth
                placeOfBirth
                regionId
                status
                userId
                email
                address {
                    city
                    street
                    building
                    description
                }
                contacts {
                    name
                    emails
                    phones {
                        number
                        comment
                    }
                    instagramUrl
                    linkedInUrl
                    skypeAddress
                    facebookUrl
                    twitterUrl
                    githubUrl
                    behanceUrl
                }
                keySkills
                languages {
                    edges {
                        node {
                            id
                            languageId
                            level
                        }
                    }
                }
                educations {
                    edges {
                        node {
                            id
                            school
                            field
                            level
                            startDate
                            location
                            endDate
                            ongoing
                            description
                            verified
                        }
                    }
                }
                experiences {
                    edges {
                        node {
                            id
                            company
                            position
                            startDate
                            endDate
                            ongoing
                            verified
                        }
                    }
                }
                region {
                    id
                    name
                }
            }
            applications {
                edges {
                    node {
                        resume {
                            id
                            name
                            localUri
                            file {
                                url
                                name
                                id
                            }
                        }
                        id
                        coverLetter
                        scheduledEvents {
                            edges {
                                node {
                                    id
                                    confirmed
                                    confirmedSlot {
                                        date
                                        from
                                    }
                                    type
                                    location
                                    name
                                    notes
                                    duration
                                    expiresAt
                                }
                            }
                        }
                        stage {
                            id
                            title
                            color
                            status
                        }
                        statusHistories {
                            edges {
                                node {
                                    id
                                    createdAt
                                    reason
                                }
                            }
                        }
                        job {
                            id
                            title
                            process {
                                id
                                stages {
                                    edges {
                                        node {
                                            id
                                            title
                                            color
                                            order
                                            status
                                            triggers {
                                                edges {
                                                    node {
                                                        id
                                                        type
                                                        stageId
                                                        attachments {
                                                            edges {
                                                                node {
                                                                    id
                                                                    name
                                                                    url
                                                                    extension
                                                                }
                                                            }
                                                        }
                                                        payload {
                                                            ... on EmailActionPayload {
                                                                from
                                                                to
                                                                toEmails
                                                                subject
                                                                body
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        comments {
                            edges {
                                node {
                                    id
                                    content
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useCandidateQuery__
 *
 * To run a query within a React component, call `useCandidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCandidateQuery(baseOptions: Apollo.QueryHookOptions<CandidateQuery, CandidateQueryVariables>) {
    return Apollo.useQuery<CandidateQuery, CandidateQueryVariables>(CandidateDocument, baseOptions);
}
export function useCandidateLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CandidateQuery, CandidateQueryVariables>,
) {
    return Apollo.useLazyQuery<CandidateQuery, CandidateQueryVariables>(CandidateDocument, baseOptions);
}
export type CandidateQueryHookResult = ReturnType<typeof useCandidateQuery>;
export type CandidateLazyQueryHookResult = ReturnType<typeof useCandidateLazyQuery>;
export type CandidateQueryResult = Apollo.QueryResult<CandidateQuery, CandidateQueryVariables>;
export const CandidatesDocument = gql`
    query Candidates($where: CandidateWhereInput, $page: Int, $orderBy: CandidateOrderBy) {
        candidates(where: $where, page: $page, orderBy: $orderBy) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                page
                totalPages
            }
            edges {
                node {
                    id
                    profile {
                        id
                        firstName
                        lastName
                        createdAt
                        email
                        avatar {
                            id
                            url
                        }
                        contacts {
                            emails
                            phones {
                                number
                                comment
                            }
                        }
                        region {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useCandidatesQuery__
 *
 * To run a query within a React component, call `useCandidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidatesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCandidatesQuery(baseOptions?: Apollo.QueryHookOptions<CandidatesQuery, CandidatesQueryVariables>) {
    return Apollo.useQuery<CandidatesQuery, CandidatesQueryVariables>(CandidatesDocument, baseOptions);
}
export function useCandidatesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CandidatesQuery, CandidatesQueryVariables>,
) {
    return Apollo.useLazyQuery<CandidatesQuery, CandidatesQueryVariables>(CandidatesDocument, baseOptions);
}
export type CandidatesQueryHookResult = ReturnType<typeof useCandidatesQuery>;
export type CandidatesLazyQueryHookResult = ReturnType<typeof useCandidatesLazyQuery>;
export type CandidatesQueryResult = Apollo.QueryResult<CandidatesQuery, CandidatesQueryVariables>;
export const JobApplicationsDocument = gql`
    query JobApplications($slug: String!, $page: Int, $where: ApplicationWhereInput, $orderBy: ApplicationOrderBy) {
        job(slug: $slug) {
            id
            slug
            title
            applications(page: $page, where: $where, orderBy: $orderBy) {
                totalCount
                pageInfo {
                    totalPages
                }
                edges {
                    node {
                        id
                        applicant {
                            id
                            firstName
                            lastName
                            createdAt
                            email
                            avatar {
                                id
                                url
                            }
                            contacts {
                                emails
                                phones {
                                    number
                                    comment
                                }
                            }
                        }
                        stage {
                            status
                            color
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useJobApplicationsQuery__
 *
 * To run a query within a React component, call `useJobApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobApplicationsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      page: // value for 'page'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useJobApplicationsQuery(
    baseOptions: Apollo.QueryHookOptions<JobApplicationsQuery, JobApplicationsQueryVariables>,
) {
    return Apollo.useQuery<JobApplicationsQuery, JobApplicationsQueryVariables>(JobApplicationsDocument, baseOptions);
}
export function useJobApplicationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<JobApplicationsQuery, JobApplicationsQueryVariables>,
) {
    return Apollo.useLazyQuery<JobApplicationsQuery, JobApplicationsQueryVariables>(
        JobApplicationsDocument,
        baseOptions,
    );
}
export type JobApplicationsQueryHookResult = ReturnType<typeof useJobApplicationsQuery>;
export type JobApplicationsLazyQueryHookResult = ReturnType<typeof useJobApplicationsLazyQuery>;
export type JobApplicationsQueryResult = Apollo.QueryResult<JobApplicationsQuery, JobApplicationsQueryVariables>;
export const DashboardDocument = gql`
    query Dashboard {
        totalJobViewCount
        jobPostings {
            totalCount
        }
        candidates {
            totalCount
        }
        applications {
            totalCount
        }
        expiringJobs {
            edges {
                node {
                    id
                    slug
                    title
                    status
                    expiresAt
                    createdAt
                    publishedAt
                    applications {
                        totalCount
                    }
                }
            }
        }
        mostAppliedJobs {
            edges {
                node {
                    id
                    slug
                    title
                    status
                    expiresAt
                    createdAt
                    publishedAt
                    applications {
                        totalCount
                    }
                }
            }
        }
    }
`;

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardQuery(baseOptions?: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
    return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, baseOptions);
}
export function useDashboardLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>,
) {
    return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, baseOptions);
}
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardQueryResult = Apollo.QueryResult<DashboardQuery, DashboardQueryVariables>;
export const EmployerDocument = gql`
    query Employer {
        me {
            id
            employer {
                id
                firstName
                lastName
                phoneNumber
                avatar {
                    id
                    url
                }
                company {
                    id
                    slug
                    name
                    type
                    website
                    description
                    regionId
                    industryId
                    logo {
                        id
                        url
                    }
                }
            }
        }
    }
`;

/**
 * __useEmployerQuery__
 *
 * To run a query within a React component, call `useEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployerQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployerQuery(baseOptions?: Apollo.QueryHookOptions<EmployerQuery, EmployerQueryVariables>) {
    return Apollo.useQuery<EmployerQuery, EmployerQueryVariables>(EmployerDocument, baseOptions);
}
export function useEmployerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployerQuery, EmployerQueryVariables>) {
    return Apollo.useLazyQuery<EmployerQuery, EmployerQueryVariables>(EmployerDocument, baseOptions);
}
export type EmployerQueryHookResult = ReturnType<typeof useEmployerQuery>;
export type EmployerLazyQueryHookResult = ReturnType<typeof useEmployerLazyQuery>;
export type EmployerQueryResult = Apollo.QueryResult<EmployerQuery, EmployerQueryVariables>;
export const HiringProcessDocument = gql`
    query HiringProcess($id: String!) {
        hiringProcess(id: $id) {
            id
            title
            stages {
                edges {
                    node {
                        id
                        status
                        color
                        title
                        processId
                        version
                        triggers {
                            totalCount
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useHiringProcessQuery__
 *
 * To run a query within a React component, call `useHiringProcessQuery` and pass it any options that fit your needs.
 * When your component renders, `useHiringProcessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHiringProcessQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHiringProcessQuery(
    baseOptions: Apollo.QueryHookOptions<HiringProcessQuery, HiringProcessQueryVariables>,
) {
    return Apollo.useQuery<HiringProcessQuery, HiringProcessQueryVariables>(HiringProcessDocument, baseOptions);
}
export function useHiringProcessLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<HiringProcessQuery, HiringProcessQueryVariables>,
) {
    return Apollo.useLazyQuery<HiringProcessQuery, HiringProcessQueryVariables>(HiringProcessDocument, baseOptions);
}
export type HiringProcessQueryHookResult = ReturnType<typeof useHiringProcessQuery>;
export type HiringProcessLazyQueryHookResult = ReturnType<typeof useHiringProcessLazyQuery>;
export type HiringProcessQueryResult = Apollo.QueryResult<HiringProcessQuery, HiringProcessQueryVariables>;
export const HiringProcessListDocument = gql`
    query HiringProcessList {
        hiringProcesses {
            edges {
                node {
                    id
                    title
                    stages {
                        edges {
                            node {
                                id
                                title
                                color
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useHiringProcessListQuery__
 *
 * To run a query within a React component, call `useHiringProcessListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHiringProcessListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHiringProcessListQuery({
 *   variables: {
 *   },
 * });
 */
export function useHiringProcessListQuery(
    baseOptions?: Apollo.QueryHookOptions<HiringProcessListQuery, HiringProcessListQueryVariables>,
) {
    return Apollo.useQuery<HiringProcessListQuery, HiringProcessListQueryVariables>(
        HiringProcessListDocument,
        baseOptions,
    );
}
export function useHiringProcessListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<HiringProcessListQuery, HiringProcessListQueryVariables>,
) {
    return Apollo.useLazyQuery<HiringProcessListQuery, HiringProcessListQueryVariables>(
        HiringProcessListDocument,
        baseOptions,
    );
}
export type HiringProcessListQueryHookResult = ReturnType<typeof useHiringProcessListQuery>;
export type HiringProcessListLazyQueryHookResult = ReturnType<typeof useHiringProcessListLazyQuery>;
export type HiringProcessListQueryResult = Apollo.QueryResult<HiringProcessListQuery, HiringProcessListQueryVariables>;
export const HiringStageDocument = gql`
    query HiringStage($id: String!) {
        hiringStage(id: $id) {
            ...HiringStageParts
        }
    }
    ${HiringStagePartsFragmentDoc}
`;

/**
 * __useHiringStageQuery__
 *
 * To run a query within a React component, call `useHiringStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHiringStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHiringStageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHiringStageQuery(baseOptions: Apollo.QueryHookOptions<HiringStageQuery, HiringStageQueryVariables>) {
    return Apollo.useQuery<HiringStageQuery, HiringStageQueryVariables>(HiringStageDocument, baseOptions);
}
export function useHiringStageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<HiringStageQuery, HiringStageQueryVariables>,
) {
    return Apollo.useLazyQuery<HiringStageQuery, HiringStageQueryVariables>(HiringStageDocument, baseOptions);
}
export type HiringStageQueryHookResult = ReturnType<typeof useHiringStageQuery>;
export type HiringStageLazyQueryHookResult = ReturnType<typeof useHiringStageLazyQuery>;
export type HiringStageQueryResult = Apollo.QueryResult<HiringStageQuery, HiringStageQueryVariables>;
export const IndustriesDocument = gql`
    query Industries {
        industries {
            edges {
                node {
                    id
                    name
                    order
                }
            }
        }
    }
`;

/**
 * __useIndustriesQuery__
 *
 * To run a query within a React component, call `useIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndustriesQuery(baseOptions?: Apollo.QueryHookOptions<IndustriesQuery, IndustriesQueryVariables>) {
    return Apollo.useQuery<IndustriesQuery, IndustriesQueryVariables>(IndustriesDocument, baseOptions);
}
export function useIndustriesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<IndustriesQuery, IndustriesQueryVariables>,
) {
    return Apollo.useLazyQuery<IndustriesQuery, IndustriesQueryVariables>(IndustriesDocument, baseOptions);
}
export type IndustriesQueryHookResult = ReturnType<typeof useIndustriesQuery>;
export type IndustriesLazyQueryHookResult = ReturnType<typeof useIndustriesLazyQuery>;
export type IndustriesQueryResult = Apollo.QueryResult<IndustriesQuery, IndustriesQueryVariables>;
export const JobPostingsDocument = gql`
    query JobPostings($where: JobPostingWhereInput, $page: Int, $orderBy: JobPostingOrderBy) {
        jobPostings(where: $where, page: $page, orderBy: $orderBy) {
            totalCount
            pageInfo {
                totalPages
            }
            edges {
                node {
                    id
                    slug
                    title
                    status
                    viewCount
                    expiresAt
                    createdAt
                    publishedAt
                    applicationCount
                }
            }
        }
    }
`;

/**
 * __useJobPostingsQuery__
 *
 * To run a query within a React component, call `useJobPostingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPostingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPostingsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useJobPostingsQuery(
    baseOptions?: Apollo.QueryHookOptions<JobPostingsQuery, JobPostingsQueryVariables>,
) {
    return Apollo.useQuery<JobPostingsQuery, JobPostingsQueryVariables>(JobPostingsDocument, baseOptions);
}
export function useJobPostingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<JobPostingsQuery, JobPostingsQueryVariables>,
) {
    return Apollo.useLazyQuery<JobPostingsQuery, JobPostingsQueryVariables>(JobPostingsDocument, baseOptions);
}
export type JobPostingsQueryHookResult = ReturnType<typeof useJobPostingsQuery>;
export type JobPostingsLazyQueryHookResult = ReturnType<typeof useJobPostingsLazyQuery>;
export type JobPostingsQueryResult = Apollo.QueryResult<JobPostingsQuery, JobPostingsQueryVariables>;
export const JobDocument = gql`
    query Job($id: String!) {
        job(id: $id) {
            id
            title
            employment
            applicationUrl
            descriptionHtml
            acceptHandicapped
            acceptKids
            acceptIncompleteResumes
            applicantProfileRequirements
            categoryId
            processId
            regions {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
            salary {
                from
                to
                currency
            }
            age {
                from
                to
            }
            schedule
            gender
            experience
            expiresAt
            education
            code
            address {
                city
                street
                building
                description
            }
            contacts {
                name
                emails
                phones {
                    number
                    comment
                }
            }
            keySkills
        }
    }
`;

/**
 * __useJobQuery__
 *
 * To run a query within a React component, call `useJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobQuery(baseOptions: Apollo.QueryHookOptions<JobQuery, JobQueryVariables>) {
    return Apollo.useQuery<JobQuery, JobQueryVariables>(JobDocument, baseOptions);
}
export function useJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobQuery, JobQueryVariables>) {
    return Apollo.useLazyQuery<JobQuery, JobQueryVariables>(JobDocument, baseOptions);
}
export type JobQueryHookResult = ReturnType<typeof useJobQuery>;
export type JobLazyQueryHookResult = ReturnType<typeof useJobLazyQuery>;
export type JobQueryResult = Apollo.QueryResult<JobQuery, JobQueryVariables>;
export const ScheduledEventTemplatesDocument = gql`
    query ScheduledEventTemplates {
        scheduledEventTemplates {
            totalCount
            edges {
                node {
                    id
                    name
                    type
                    availabilities {
                        date
                        slots {
                            from
                        }
                    }
                    duration
                    location
                    expiresAt
                    notes
                }
            }
        }
    }
`;

/**
 * __useScheduledEventTemplatesQuery__
 *
 * To run a query within a React component, call `useScheduledEventTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledEventTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduledEventTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useScheduledEventTemplatesQuery(
    baseOptions?: Apollo.QueryHookOptions<ScheduledEventTemplatesQuery, ScheduledEventTemplatesQueryVariables>,
) {
    return Apollo.useQuery<ScheduledEventTemplatesQuery, ScheduledEventTemplatesQueryVariables>(
        ScheduledEventTemplatesDocument,
        baseOptions,
    );
}
export function useScheduledEventTemplatesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ScheduledEventTemplatesQuery, ScheduledEventTemplatesQueryVariables>,
) {
    return Apollo.useLazyQuery<ScheduledEventTemplatesQuery, ScheduledEventTemplatesQueryVariables>(
        ScheduledEventTemplatesDocument,
        baseOptions,
    );
}
export type ScheduledEventTemplatesQueryHookResult = ReturnType<typeof useScheduledEventTemplatesQuery>;
export type ScheduledEventTemplatesLazyQueryHookResult = ReturnType<typeof useScheduledEventTemplatesLazyQuery>;
export type ScheduledEventTemplatesQueryResult = Apollo.QueryResult<
    ScheduledEventTemplatesQuery,
    ScheduledEventTemplatesQueryVariables
>;
export const ScheduledSlotsDocument = gql`
    query ScheduledSlots($where: ScheduledSlotWhereInput!) {
        scheduledSlots(where: $where) {
            edges {
                node {
                    id
                    start
                    end
                    date
                }
            }
        }
    }
`;

/**
 * __useScheduledSlotsQuery__
 *
 * To run a query within a React component, call `useScheduledSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduledSlotsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useScheduledSlotsQuery(
    baseOptions: Apollo.QueryHookOptions<ScheduledSlotsQuery, ScheduledSlotsQueryVariables>,
) {
    return Apollo.useQuery<ScheduledSlotsQuery, ScheduledSlotsQueryVariables>(ScheduledSlotsDocument, baseOptions);
}
export function useScheduledSlotsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ScheduledSlotsQuery, ScheduledSlotsQueryVariables>,
) {
    return Apollo.useLazyQuery<ScheduledSlotsQuery, ScheduledSlotsQueryVariables>(ScheduledSlotsDocument, baseOptions);
}
export type ScheduledSlotsQueryHookResult = ReturnType<typeof useScheduledSlotsQuery>;
export type ScheduledSlotsLazyQueryHookResult = ReturnType<typeof useScheduledSlotsLazyQuery>;
export type ScheduledSlotsQueryResult = Apollo.QueryResult<ScheduledSlotsQuery, ScheduledSlotsQueryVariables>;
export const HomeStatisticsDocument = gql`
    query HomeStatistics {
        jobseekers {
            totalCount
        }
        jobCount
        companyCount
    }
`;

/**
 * __useHomeStatisticsQuery__
 *
 * To run a query within a React component, call `useHomeStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeStatisticsQuery(
    baseOptions?: Apollo.QueryHookOptions<HomeStatisticsQuery, HomeStatisticsQueryVariables>,
) {
    return Apollo.useQuery<HomeStatisticsQuery, HomeStatisticsQueryVariables>(HomeStatisticsDocument, baseOptions);
}
export function useHomeStatisticsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<HomeStatisticsQuery, HomeStatisticsQueryVariables>,
) {
    return Apollo.useLazyQuery<HomeStatisticsQuery, HomeStatisticsQueryVariables>(HomeStatisticsDocument, baseOptions);
}
export type HomeStatisticsQueryHookResult = ReturnType<typeof useHomeStatisticsQuery>;
export type HomeStatisticsLazyQueryHookResult = ReturnType<typeof useHomeStatisticsLazyQuery>;
export type HomeStatisticsQueryResult = Apollo.QueryResult<HomeStatisticsQuery, HomeStatisticsQueryVariables>;
export const NewJobsDocument = gql`
    query NewJobs {
        jobs {
            edges {
                node {
                    id
                    slug
                    title
                    employment
                    createdAt
                    publishedAt
                    applied
                    bookmarked
                    easyApply
                    viewCount
                    salary {
                        from
                        to
                    }
                    company {
                        id
                        logo {
                            id
                            url
                        }
                        name
                        verified
                    }
                }
            }
        }
    }
`;

/**
 * __useNewJobsQuery__
 *
 * To run a query within a React component, call `useNewJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewJobsQuery(baseOptions?: Apollo.QueryHookOptions<NewJobsQuery, NewJobsQueryVariables>) {
    return Apollo.useQuery<NewJobsQuery, NewJobsQueryVariables>(NewJobsDocument, baseOptions);
}
export function useNewJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewJobsQuery, NewJobsQueryVariables>) {
    return Apollo.useLazyQuery<NewJobsQuery, NewJobsQueryVariables>(NewJobsDocument, baseOptions);
}
export type NewJobsQueryHookResult = ReturnType<typeof useNewJobsQuery>;
export type NewJobsLazyQueryHookResult = ReturnType<typeof useNewJobsLazyQuery>;
export type NewJobsQueryResult = Apollo.QueryResult<NewJobsQuery, NewJobsQueryVariables>;
export const SelectedJobsDocument = gql`
    query SelectedJobs {
        selectedJobs {
            edges {
                node {
                    id
                    slug
                    title
                    employment
                    createdAt
                    publishedAt
                    applied
                    bookmarked
                    easyApply
                    status
                    viewCount
                    salary {
                        from
                        to
                    }
                    company {
                        id
                        logo {
                            id
                            url
                        }
                        name
                        verified
                    }
                }
            }
        }
    }
`;

/**
 * __useSelectedJobsQuery__
 *
 * To run a query within a React component, call `useSelectedJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectedJobsQuery(
    baseOptions?: Apollo.QueryHookOptions<SelectedJobsQuery, SelectedJobsQueryVariables>,
) {
    return Apollo.useQuery<SelectedJobsQuery, SelectedJobsQueryVariables>(SelectedJobsDocument, baseOptions);
}
export function useSelectedJobsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SelectedJobsQuery, SelectedJobsQueryVariables>,
) {
    return Apollo.useLazyQuery<SelectedJobsQuery, SelectedJobsQueryVariables>(SelectedJobsDocument, baseOptions);
}
export type SelectedJobsQueryHookResult = ReturnType<typeof useSelectedJobsQuery>;
export type SelectedJobsLazyQueryHookResult = ReturnType<typeof useSelectedJobsLazyQuery>;
export type SelectedJobsQueryResult = Apollo.QueryResult<SelectedJobsQuery, SelectedJobsQueryVariables>;
export const TopCategoriesDocument = gql`
    query TopCategories {
        topCategories {
            edges {
                node {
                    id
                    name
                    order
                    jobCount
                }
            }
        }
    }
`;

/**
 * __useTopCategoriesQuery__
 *
 * To run a query within a React component, call `useTopCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopCategoriesQuery(
    baseOptions?: Apollo.QueryHookOptions<TopCategoriesQuery, TopCategoriesQueryVariables>,
) {
    return Apollo.useQuery<TopCategoriesQuery, TopCategoriesQueryVariables>(TopCategoriesDocument, baseOptions);
}
export function useTopCategoriesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<TopCategoriesQuery, TopCategoriesQueryVariables>,
) {
    return Apollo.useLazyQuery<TopCategoriesQuery, TopCategoriesQueryVariables>(TopCategoriesDocument, baseOptions);
}
export type TopCategoriesQueryHookResult = ReturnType<typeof useTopCategoriesQuery>;
export type TopCategoriesLazyQueryHookResult = ReturnType<typeof useTopCategoriesLazyQuery>;
export type TopCategoriesQueryResult = Apollo.QueryResult<TopCategoriesQuery, TopCategoriesQueryVariables>;
export const JobDetailDocument = gql`
    query JobDetail($slug: String!) {
        job(slug: $slug) {
            id
            slug
            title
            easyApply
            status
            employment
            createdAt
            expiresAt
            publishedAt
            experience
            education
            bookmarked
            applied
            descriptionHtml
            descriptionText
            viewCount
            schedule
            keySkills
            driverLicenseTypes
            gender
            applicationUrl
            acceptHandicapped
            acceptIncompleteResumes
            applicantProfileRequirements
            invitation {
                id
                status
            }
            regions {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
            age {
                from
                to
            }
            address {
                city
                location {
                    latitude
                    longitude
                }
            }
            company {
                id
                slug
                logo {
                    id
                    url
                }
                name
                verified
            }
            salary {
                gross
                currency
                from
                to
            }
            contacts {
                emails
                phones {
                    number
                }
                name
                linkedInUrl
                skypeAddress
                facebookUrl
                twitterUrl
                githubUrl
            }
            relatedJobs {
                edges {
                    node {
                        id
                        title
                        slug
                        title
                        employment
                        salary {
                            gross
                            currency
                            from
                            to
                        }
                        applied
                        status
                        bookmarked
                        createdAt
                        publishedAt
                        company {
                            id
                            logo {
                                id
                                url
                            }
                            name
                            verified
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useJobDetailQuery__
 *
 * To run a query within a React component, call `useJobDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobDetailQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useJobDetailQuery(baseOptions: Apollo.QueryHookOptions<JobDetailQuery, JobDetailQueryVariables>) {
    return Apollo.useQuery<JobDetailQuery, JobDetailQueryVariables>(JobDetailDocument, baseOptions);
}
export function useJobDetailLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<JobDetailQuery, JobDetailQueryVariables>,
) {
    return Apollo.useLazyQuery<JobDetailQuery, JobDetailQueryVariables>(JobDetailDocument, baseOptions);
}
export type JobDetailQueryHookResult = ReturnType<typeof useJobDetailQuery>;
export type JobDetailLazyQueryHookResult = ReturnType<typeof useJobDetailLazyQuery>;
export type JobDetailQueryResult = Apollo.QueryResult<JobDetailQuery, JobDetailQueryVariables>;
export const JobListDocument = gql`
    query JobList($where: JobWhereInput, $page: Int, $orderBy: JobOrderBy) {
        jobs(where: $where, page: $page, orderBy: $orderBy) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                page
                totalPages
            }
            edges {
                node {
                    id
                    slug
                    title
                    employment
                    createdAt
                    publishedAt
                    applied
                    bookmarked
                    easyApply
                    status
                    expiresAt
                    applications {
                        totalCount
                    }
                    viewCount
                    salary {
                        from
                        to
                    }
                    company {
                        id
                        slug
                        logo {
                            id
                            url
                        }
                        name
                        verified
                    }
                }
            }
        }
    }
`;

/**
 * __useJobListQuery__
 *
 * To run a query within a React component, call `useJobListQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useJobListQuery(baseOptions?: Apollo.QueryHookOptions<JobListQuery, JobListQueryVariables>) {
    return Apollo.useQuery<JobListQuery, JobListQueryVariables>(JobListDocument, baseOptions);
}
export function useJobListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobListQuery, JobListQueryVariables>) {
    return Apollo.useLazyQuery<JobListQuery, JobListQueryVariables>(JobListDocument, baseOptions);
}
export type JobListQueryHookResult = ReturnType<typeof useJobListQuery>;
export type JobListLazyQueryHookResult = ReturnType<typeof useJobListLazyQuery>;
export type JobListQueryResult = Apollo.QueryResult<JobListQuery, JobListQueryVariables>;
export const JobseekerListDocument = gql`
    query JobseekerList($where: JobseekerWhereInput, $page: Int, $orderBy: JobseekerOrderBy) {
        jobseekers(where: $where, page: $page, orderBy: $orderBy) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                page
                totalPages
            }
            edges {
                node {
                    id
                    firstName
                    lastName
                    gender
                    region {
                        id
                        name
                    }
                    keySkills
                    avatar {
                        id
                        url
                    }
                    salaryExpectation
                    status
                    category {
                        id
                        name
                        order
                    }
                    contacts {
                        emails
                        twitterUrl
                    }
                }
            }
        }
    }
`;

/**
 * __useJobseekerListQuery__
 *
 * To run a query within a React component, call `useJobseekerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobseekerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobseekerListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      page: // value for 'page'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useJobseekerListQuery(
    baseOptions?: Apollo.QueryHookOptions<JobseekerListQuery, JobseekerListQueryVariables>,
) {
    return Apollo.useQuery<JobseekerListQuery, JobseekerListQueryVariables>(JobseekerListDocument, baseOptions);
}
export function useJobseekerListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<JobseekerListQuery, JobseekerListQueryVariables>,
) {
    return Apollo.useLazyQuery<JobseekerListQuery, JobseekerListQueryVariables>(JobseekerListDocument, baseOptions);
}
export type JobseekerListQueryHookResult = ReturnType<typeof useJobseekerListQuery>;
export type JobseekerListLazyQueryHookResult = ReturnType<typeof useJobseekerListLazyQuery>;
export type JobseekerListQueryResult = Apollo.QueryResult<JobseekerListQuery, JobseekerListQueryVariables>;
export const ApplicationInvitesDocument = gql`
    query applicationInvites($page: Int, $where: ApplicationInviteWhereInput, $orderBy: ApplicationInviteOrderBy) {
        applicationInvites(page: $page, where: $where, orderBy: $orderBy) {
            totalCount
            edges {
                node {
                    id
                    jobId
                    createdAt
                    status
                    job {
                        slug
                        title
                        applied
                        createdAt
                        publishedAt
                        employment
                        easyApply
                        status
                        applicantProfileRequirements
                        acceptIncompleteResumes
                        salary {
                            from
                            to
                        }
                        company {
                            name
                            id
                            logo {
                                id
                                url
                            }
                        }
                    }
                    jobseeker {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
    }
`;

/**
 * __useApplicationInvitesQuery__
 *
 * To run a query within a React component, call `useApplicationInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationInvitesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useApplicationInvitesQuery(
    baseOptions?: Apollo.QueryHookOptions<ApplicationInvitesQuery, ApplicationInvitesQueryVariables>,
) {
    return Apollo.useQuery<ApplicationInvitesQuery, ApplicationInvitesQueryVariables>(
        ApplicationInvitesDocument,
        baseOptions,
    );
}
export function useApplicationInvitesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ApplicationInvitesQuery, ApplicationInvitesQueryVariables>,
) {
    return Apollo.useLazyQuery<ApplicationInvitesQuery, ApplicationInvitesQueryVariables>(
        ApplicationInvitesDocument,
        baseOptions,
    );
}
export type ApplicationInvitesQueryHookResult = ReturnType<typeof useApplicationInvitesQuery>;
export type ApplicationInvitesLazyQueryHookResult = ReturnType<typeof useApplicationInvitesLazyQuery>;
export type ApplicationInvitesQueryResult = Apollo.QueryResult<
    ApplicationInvitesQuery,
    ApplicationInvitesQueryVariables
>;
export const JobApplicationDocument = gql`
    query JobApplication($id: String!) {
        jobApplication(id: $id) {
            id
            coverLetter
            createdAt
            stage {
                id
                status
                color
            }
            statusHistories {
                edges {
                    node {
                        id
                        createdAt
                        reason
                        newStage {
                            id
                            title
                            color
                            status
                        }
                        oldStage {
                            id
                            title
                            color
                            status
                        }
                    }
                }
            }
            job {
                id
                slug
                title
                company {
                    id
                    slug
                    logo {
                        id
                        url
                    }
                    name
                }
            }
            scheduledEvents {
                edges {
                    node {
                        id
                        confirmed
                        confirmedSlot {
                            date
                            from
                        }
                        type
                        location
                        name
                        notes
                        duration
                        expiresAt
                    }
                }
            }
        }
    }
`;

/**
 * __useJobApplicationQuery__
 *
 * To run a query within a React component, call `useJobApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobApplicationQuery(
    baseOptions: Apollo.QueryHookOptions<JobApplicationQuery, JobApplicationQueryVariables>,
) {
    return Apollo.useQuery<JobApplicationQuery, JobApplicationQueryVariables>(JobApplicationDocument, baseOptions);
}
export function useJobApplicationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<JobApplicationQuery, JobApplicationQueryVariables>,
) {
    return Apollo.useLazyQuery<JobApplicationQuery, JobApplicationQueryVariables>(JobApplicationDocument, baseOptions);
}
export type JobApplicationQueryHookResult = ReturnType<typeof useJobApplicationQuery>;
export type JobApplicationLazyQueryHookResult = ReturnType<typeof useJobApplicationLazyQuery>;
export type JobApplicationQueryResult = Apollo.QueryResult<JobApplicationQuery, JobApplicationQueryVariables>;
export const BookmarkedJobsDocument = gql`
    query BookmarkedJobs($where: JobWhereInput, $orderBy: JobOrderBy, $page: Int) {
        bookmarkedJobs(where: $where, orderBy: $orderBy, page: $page) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                page
                totalPages
            }
            edges {
                node {
                    id
                    job {
                        id
                        slug
                        title
                        applied
                        employment
                        applied
                        easyApply
                        createdAt
                        publishedAt
                        bookmarked
                        salary {
                            from
                            to
                        }
                        company {
                            id
                            slug
                            logo {
                                id
                                url
                            }
                            name
                            verified
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useBookmarkedJobsQuery__
 *
 * To run a query within a React component, call `useBookmarkedJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookmarkedJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookmarkedJobsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useBookmarkedJobsQuery(
    baseOptions?: Apollo.QueryHookOptions<BookmarkedJobsQuery, BookmarkedJobsQueryVariables>,
) {
    return Apollo.useQuery<BookmarkedJobsQuery, BookmarkedJobsQueryVariables>(BookmarkedJobsDocument, baseOptions);
}
export function useBookmarkedJobsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<BookmarkedJobsQuery, BookmarkedJobsQueryVariables>,
) {
    return Apollo.useLazyQuery<BookmarkedJobsQuery, BookmarkedJobsQueryVariables>(BookmarkedJobsDocument, baseOptions);
}
export type BookmarkedJobsQueryHookResult = ReturnType<typeof useBookmarkedJobsQuery>;
export type BookmarkedJobsLazyQueryHookResult = ReturnType<typeof useBookmarkedJobsLazyQuery>;
export type BookmarkedJobsQueryResult = Apollo.QueryResult<BookmarkedJobsQuery, BookmarkedJobsQueryVariables>;
export const HtmlResumeDocument = gql`
    query htmlResume {
        htmlResume
    }
`;

/**
 * __useHtmlResumeQuery__
 *
 * To run a query within a React component, call `useHtmlResumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useHtmlResumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHtmlResumeQuery({
 *   variables: {
 *   },
 * });
 */
export function useHtmlResumeQuery(baseOptions?: Apollo.QueryHookOptions<HtmlResumeQuery, HtmlResumeQueryVariables>) {
    return Apollo.useQuery<HtmlResumeQuery, HtmlResumeQueryVariables>(HtmlResumeDocument, baseOptions);
}
export function useHtmlResumeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<HtmlResumeQuery, HtmlResumeQueryVariables>,
) {
    return Apollo.useLazyQuery<HtmlResumeQuery, HtmlResumeQueryVariables>(HtmlResumeDocument, baseOptions);
}
export type HtmlResumeQueryHookResult = ReturnType<typeof useHtmlResumeQuery>;
export type HtmlResumeLazyQueryHookResult = ReturnType<typeof useHtmlResumeLazyQuery>;
export type HtmlResumeQueryResult = Apollo.QueryResult<HtmlResumeQuery, HtmlResumeQueryVariables>;
export const JobseekerApplicationsDocument = gql`
    query JobseekerApplications($where: JobApplicationWhereInput, $orderBy: JobOrderBy) {
        jobseekerApplications(where: $where, orderBy: $orderBy) {
            edges {
                node {
                    id
                    createdAt
                    job {
                        id
                        title
                        company {
                            slug
                            id
                            name
                            verified
                            logo {
                                id
                                url
                            }
                        }
                    }
                    stage {
                        id
                        title
                        color
                        status
                    }
                }
            }
        }
    }
`;

/**
 * __useJobseekerApplicationsQuery__
 *
 * To run a query within a React component, call `useJobseekerApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobseekerApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobseekerApplicationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useJobseekerApplicationsQuery(
    baseOptions?: Apollo.QueryHookOptions<JobseekerApplicationsQuery, JobseekerApplicationsQueryVariables>,
) {
    return Apollo.useQuery<JobseekerApplicationsQuery, JobseekerApplicationsQueryVariables>(
        JobseekerApplicationsDocument,
        baseOptions,
    );
}
export function useJobseekerApplicationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<JobseekerApplicationsQuery, JobseekerApplicationsQueryVariables>,
) {
    return Apollo.useLazyQuery<JobseekerApplicationsQuery, JobseekerApplicationsQueryVariables>(
        JobseekerApplicationsDocument,
        baseOptions,
    );
}
export type JobseekerApplicationsQueryHookResult = ReturnType<typeof useJobseekerApplicationsQuery>;
export type JobseekerApplicationsLazyQueryHookResult = ReturnType<typeof useJobseekerApplicationsLazyQuery>;
export type JobseekerApplicationsQueryResult = Apollo.QueryResult<
    JobseekerApplicationsQuery,
    JobseekerApplicationsQueryVariables
>;
export const JobseekerProfileDocument = gql`
    query JobseekerProfile {
        me {
            id
            email
            jobseeker {
                id
                firstName
                middleName
                lastName
                email
                status
                gender
                placeOfBirth
                dateOfBirth
                summary
                salaryExpectation
                categoryId
                category {
                    id
                    name
                }
                avatar {
                    id
                    url
                }
                address {
                    city
                    street
                }
                contacts {
                    emails
                    phones {
                        number
                        comment
                    }
                    linkedInUrl
                    skypeAddress
                    facebookUrl
                    twitterUrl
                    githubUrl
                    instagramUrl
                    behanceUrl
                }
                regionId
                region {
                    id
                    name
                }
                keySkills
                experiences {
                    edges {
                        node {
                            id
                            company
                            position
                            startDate
                            endDate
                            ongoing
                            description
                        }
                    }
                }
                educations {
                    edges {
                        node {
                            id
                            school
                            location
                            level
                            field
                            startDate
                            endDate
                            ongoing
                            description
                        }
                    }
                }
                languages {
                    edges {
                        node {
                            id
                            languageId
                            level
                        }
                    }
                }
                resumes {
                    edges {
                        node {
                            id
                            name
                            createdAt
                            file {
                                extension
                                size
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useJobseekerProfileQuery__
 *
 * To run a query within a React component, call `useJobseekerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobseekerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobseekerProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobseekerProfileQuery(
    baseOptions?: Apollo.QueryHookOptions<JobseekerProfileQuery, JobseekerProfileQueryVariables>,
) {
    return Apollo.useQuery<JobseekerProfileQuery, JobseekerProfileQueryVariables>(
        JobseekerProfileDocument,
        baseOptions,
    );
}
export function useJobseekerProfileLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<JobseekerProfileQuery, JobseekerProfileQueryVariables>,
) {
    return Apollo.useLazyQuery<JobseekerProfileQuery, JobseekerProfileQueryVariables>(
        JobseekerProfileDocument,
        baseOptions,
    );
}
export type JobseekerProfileQueryHookResult = ReturnType<typeof useJobseekerProfileQuery>;
export type JobseekerProfileLazyQueryHookResult = ReturnType<typeof useJobseekerProfileLazyQuery>;
export type JobseekerProfileQueryResult = Apollo.QueryResult<JobseekerProfileQuery, JobseekerProfileQueryVariables>;
export const JobseekerDocument = gql`
    query Jobseeker($id: String!) {
        jobSeeker(id: $id) {
            id
            firstName
            middleName
            lastName
            status
            gender
            placeOfBirth
            dateOfBirth
            summary
            salaryExpectation
            avatar {
                id
                url
            }
            address {
                city
                street
            }
            contacts {
                emails
                phones {
                    number
                    comment
                }
                linkedInUrl
                skypeAddress
                facebookUrl
                twitterUrl
                githubUrl
                instagramUrl
            }
            regionId
            region {
                id
                name
            }
            keySkills
            experiences {
                edges {
                    node {
                        id
                        company
                        position
                        startDate
                        endDate
                        ongoing
                        description
                    }
                }
            }
            educations {
                edges {
                    node {
                        id
                        school
                        level
                        field
                        startDate
                        endDate
                        ongoing
                        description
                    }
                }
            }
            languages {
                edges {
                    node {
                        id
                        languageId
                        level
                    }
                }
            }
            resumes {
                edges {
                    node {
                        id
                        name
                        createdAt
                        file {
                            id
                            extension
                            size
                            url
                        }
                    }
                }
            }
            userId
        }
    }
`;

/**
 * __useJobseekerQuery__
 *
 * To run a query within a React component, call `useJobseekerQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobseekerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobseekerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobseekerQuery(baseOptions: Apollo.QueryHookOptions<JobseekerQuery, JobseekerQueryVariables>) {
    return Apollo.useQuery<JobseekerQuery, JobseekerQueryVariables>(JobseekerDocument, baseOptions);
}
export function useJobseekerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<JobseekerQuery, JobseekerQueryVariables>,
) {
    return Apollo.useLazyQuery<JobseekerQuery, JobseekerQueryVariables>(JobseekerDocument, baseOptions);
}
export type JobseekerQueryHookResult = ReturnType<typeof useJobseekerQuery>;
export type JobseekerLazyQueryHookResult = ReturnType<typeof useJobseekerLazyQuery>;
export type JobseekerQueryResult = Apollo.QueryResult<JobseekerQuery, JobseekerQueryVariables>;
export const PdfResumeDocument = gql`
    query pdfResume {
        pdfResume
    }
`;

/**
 * __usePdfResumeQuery__
 *
 * To run a query within a React component, call `usePdfResumeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePdfResumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePdfResumeQuery({
 *   variables: {
 *   },
 * });
 */
export function usePdfResumeQuery(baseOptions?: Apollo.QueryHookOptions<PdfResumeQuery, PdfResumeQueryVariables>) {
    return Apollo.useQuery<PdfResumeQuery, PdfResumeQueryVariables>(PdfResumeDocument, baseOptions);
}
export function usePdfResumeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PdfResumeQuery, PdfResumeQueryVariables>,
) {
    return Apollo.useLazyQuery<PdfResumeQuery, PdfResumeQueryVariables>(PdfResumeDocument, baseOptions);
}
export type PdfResumeQueryHookResult = ReturnType<typeof usePdfResumeQuery>;
export type PdfResumeLazyQueryHookResult = ReturnType<typeof usePdfResumeLazyQuery>;
export type PdfResumeQueryResult = Apollo.QueryResult<PdfResumeQuery, PdfResumeQueryVariables>;
export const SavedSearchesDocument = gql`
    query SavedSearches {
        savedSearches {
            edges {
                node {
                    id
                    name
                    alertPeriod
                    lastExecutedAt
                }
            }
        }
    }
`;

/**
 * __useSavedSearchesQuery__
 *
 * To run a query within a React component, call `useSavedSearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedSearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedSearchesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSavedSearchesQuery(
    baseOptions?: Apollo.QueryHookOptions<SavedSearchesQuery, SavedSearchesQueryVariables>,
) {
    return Apollo.useQuery<SavedSearchesQuery, SavedSearchesQueryVariables>(SavedSearchesDocument, baseOptions);
}
export function useSavedSearchesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SavedSearchesQuery, SavedSearchesQueryVariables>,
) {
    return Apollo.useLazyQuery<SavedSearchesQuery, SavedSearchesQueryVariables>(SavedSearchesDocument, baseOptions);
}
export type SavedSearchesQueryHookResult = ReturnType<typeof useSavedSearchesQuery>;
export type SavedSearchesLazyQueryHookResult = ReturnType<typeof useSavedSearchesLazyQuery>;
export type SavedSearchesQueryResult = Apollo.QueryResult<SavedSearchesQuery, SavedSearchesQueryVariables>;
export const LanguagesDocument = gql`
    query Languages {
        languages {
            edges {
                node {
                    id
                    isoCode
                    name
                }
            }
        }
    }
`;

/**
 * __useLanguagesQuery__
 *
 * To run a query within a React component, call `useLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<LanguagesQuery, LanguagesQueryVariables>) {
    return Apollo.useQuery<LanguagesQuery, LanguagesQueryVariables>(LanguagesDocument, baseOptions);
}
export function useLanguagesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<LanguagesQuery, LanguagesQueryVariables>,
) {
    return Apollo.useLazyQuery<LanguagesQuery, LanguagesQueryVariables>(LanguagesDocument, baseOptions);
}
export type LanguagesQueryHookResult = ReturnType<typeof useLanguagesQuery>;
export type LanguagesLazyQueryHookResult = ReturnType<typeof useLanguagesLazyQuery>;
export type LanguagesQueryResult = Apollo.QueryResult<LanguagesQuery, LanguagesQueryVariables>;
export const PricingPlansDocument = gql`
    query PricingPlans {
        pricingPlans {
            edges {
                node {
                    id
                    name
                    pricingType
                    price
                    order
                    features {
                        edges {
                            node {
                                id
                                code
                                enabled
                                limit
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __usePricingPlansQuery__
 *
 * To run a query within a React component, call `usePricingPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function usePricingPlansQuery(
    baseOptions?: Apollo.QueryHookOptions<PricingPlansQuery, PricingPlansQueryVariables>,
) {
    return Apollo.useQuery<PricingPlansQuery, PricingPlansQueryVariables>(PricingPlansDocument, baseOptions);
}
export function usePricingPlansLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PricingPlansQuery, PricingPlansQueryVariables>,
) {
    return Apollo.useLazyQuery<PricingPlansQuery, PricingPlansQueryVariables>(PricingPlansDocument, baseOptions);
}
export type PricingPlansQueryHookResult = ReturnType<typeof usePricingPlansQuery>;
export type PricingPlansLazyQueryHookResult = ReturnType<typeof usePricingPlansLazyQuery>;
export type PricingPlansQueryResult = Apollo.QueryResult<PricingPlansQuery, PricingPlansQueryVariables>;
export const RegionListDocument = gql`
    query RegionList {
        regions {
            edges {
                node {
                    id
                    name
                    children {
                        edges {
                            node {
                                name
                                id
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useRegionListQuery__
 *
 * To run a query within a React component, call `useRegionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionListQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegionListQuery(baseOptions?: Apollo.QueryHookOptions<RegionListQuery, RegionListQueryVariables>) {
    return Apollo.useQuery<RegionListQuery, RegionListQueryVariables>(RegionListDocument, baseOptions);
}
export function useRegionListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<RegionListQuery, RegionListQueryVariables>,
) {
    return Apollo.useLazyQuery<RegionListQuery, RegionListQueryVariables>(RegionListDocument, baseOptions);
}
export type RegionListQueryHookResult = ReturnType<typeof useRegionListQuery>;
export type RegionListLazyQueryHookResult = ReturnType<typeof useRegionListLazyQuery>;
export type RegionListQueryResult = Apollo.QueryResult<RegionListQuery, RegionListQueryVariables>;
export const RegionDocument = gql`
    query Region($id: String!) {
        region(id: $id) {
            id
            name
        }
    }
`;

/**
 * __useRegionQuery__
 *
 * To run a query within a React component, call `useRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegionQuery(baseOptions: Apollo.QueryHookOptions<RegionQuery, RegionQueryVariables>) {
    return Apollo.useQuery<RegionQuery, RegionQueryVariables>(RegionDocument, baseOptions);
}
export function useRegionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegionQuery, RegionQueryVariables>) {
    return Apollo.useLazyQuery<RegionQuery, RegionQueryVariables>(RegionDocument, baseOptions);
}
export type RegionQueryHookResult = ReturnType<typeof useRegionQuery>;
export type RegionLazyQueryHookResult = ReturnType<typeof useRegionLazyQuery>;
export type RegionQueryResult = Apollo.QueryResult<RegionQuery, RegionQueryVariables>;
export const ReportsDocument = gql`
    query Reports {
        reports {
            totalCount
            edges {
                node {
                    id
                    code
                    embedUrl
                }
            }
        }
    }
`;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportsQuery(baseOptions?: Apollo.QueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
    return Apollo.useQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, baseOptions);
}
export function useReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
    return Apollo.useLazyQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, baseOptions);
}
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<ReportsQuery, ReportsQueryVariables>;
export const ScheduledEventTemplateDocument = gql`
    query ScheduledEventTemplate($templateId: String!) {
        scheduledEventTemplate(templateId: $templateId) {
            id
            name
            expiresAt
            availabilities {
                date
                slots {
                    from
                }
            }
            type
            duration
            location
            notes
            creatorId
        }
    }
`;

/**
 * __useScheduledEventTemplateQuery__
 *
 * To run a query within a React component, call `useScheduledEventTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledEventTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduledEventTemplateQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useScheduledEventTemplateQuery(
    baseOptions: Apollo.QueryHookOptions<ScheduledEventTemplateQuery, ScheduledEventTemplateQueryVariables>,
) {
    return Apollo.useQuery<ScheduledEventTemplateQuery, ScheduledEventTemplateQueryVariables>(
        ScheduledEventTemplateDocument,
        baseOptions,
    );
}
export function useScheduledEventTemplateLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ScheduledEventTemplateQuery, ScheduledEventTemplateQueryVariables>,
) {
    return Apollo.useLazyQuery<ScheduledEventTemplateQuery, ScheduledEventTemplateQueryVariables>(
        ScheduledEventTemplateDocument,
        baseOptions,
    );
}
export type ScheduledEventTemplateQueryHookResult = ReturnType<typeof useScheduledEventTemplateQuery>;
export type ScheduledEventTemplateLazyQueryHookResult = ReturnType<typeof useScheduledEventTemplateLazyQuery>;
export type ScheduledEventTemplateQueryResult = Apollo.QueryResult<
    ScheduledEventTemplateQuery,
    ScheduledEventTemplateQueryVariables
>;
export const ScheduledEventDocument = gql`
    query ScheduledEvent($id: String!) {
        scheduledEvent(id: $id) {
            id
            name
            expiresAt
            availabilities {
                date
                slots {
                    from
                }
            }
            type
            duration
            applicationId
            timezone
            location
            notes
            confirmed
            confirmedSlot {
                date
                from
            }
            creatorId
            confirmerId
            createdAt
            updatedAt
        }
    }
`;

/**
 * __useScheduledEventQuery__
 *
 * To run a query within a React component, call `useScheduledEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduledEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScheduledEventQuery(
    baseOptions: Apollo.QueryHookOptions<ScheduledEventQuery, ScheduledEventQueryVariables>,
) {
    return Apollo.useQuery<ScheduledEventQuery, ScheduledEventQueryVariables>(ScheduledEventDocument, baseOptions);
}
export function useScheduledEventLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ScheduledEventQuery, ScheduledEventQueryVariables>,
) {
    return Apollo.useLazyQuery<ScheduledEventQuery, ScheduledEventQueryVariables>(ScheduledEventDocument, baseOptions);
}
export type ScheduledEventQueryHookResult = ReturnType<typeof useScheduledEventQuery>;
export type ScheduledEventLazyQueryHookResult = ReturnType<typeof useScheduledEventLazyQuery>;
export type ScheduledEventQueryResult = Apollo.QueryResult<ScheduledEventQuery, ScheduledEventQueryVariables>;
export const SelectedSettingsDocument = gql`
    query SelectedSettings {
        selectedSettings {
            edges {
                node {
                    id
                    settingId
                    value
                    type
                }
            }
        }
    }
`;

/**
 * __useSelectedSettingsQuery__
 *
 * To run a query within a React component, call `useSelectedSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectedSettingsQuery(
    baseOptions?: Apollo.QueryHookOptions<SelectedSettingsQuery, SelectedSettingsQueryVariables>,
) {
    return Apollo.useQuery<SelectedSettingsQuery, SelectedSettingsQueryVariables>(
        SelectedSettingsDocument,
        baseOptions,
    );
}
export function useSelectedSettingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SelectedSettingsQuery, SelectedSettingsQueryVariables>,
) {
    return Apollo.useLazyQuery<SelectedSettingsQuery, SelectedSettingsQueryVariables>(
        SelectedSettingsDocument,
        baseOptions,
    );
}
export type SelectedSettingsQueryHookResult = ReturnType<typeof useSelectedSettingsQuery>;
export type SelectedSettingsLazyQueryHookResult = ReturnType<typeof useSelectedSettingsLazyQuery>;
export type SelectedSettingsQueryResult = Apollo.QueryResult<SelectedSettingsQuery, SelectedSettingsQueryVariables>;
export const SettingGroupsDocument = gql`
    query SettingGroups {
        settingGroups {
            edges {
                node {
                    id
                    name
                    settings {
                        edges {
                            node {
                                id
                                name
                                type
                                scopes
                                required
                                dataType
                                allowedValues {
                                    label
                                    value
                                }
                                groupId
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useSettingGroupsQuery__
 *
 * To run a query within a React component, call `useSettingGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingGroupsQuery(
    baseOptions?: Apollo.QueryHookOptions<SettingGroupsQuery, SettingGroupsQueryVariables>,
) {
    return Apollo.useQuery<SettingGroupsQuery, SettingGroupsQueryVariables>(SettingGroupsDocument, baseOptions);
}
export function useSettingGroupsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SettingGroupsQuery, SettingGroupsQueryVariables>,
) {
    return Apollo.useLazyQuery<SettingGroupsQuery, SettingGroupsQueryVariables>(SettingGroupsDocument, baseOptions);
}
export type SettingGroupsQueryHookResult = ReturnType<typeof useSettingGroupsQuery>;
export type SettingGroupsLazyQueryHookResult = ReturnType<typeof useSettingGroupsLazyQuery>;
export type SettingGroupsQueryResult = Apollo.QueryResult<SettingGroupsQuery, SettingGroupsQueryVariables>;
